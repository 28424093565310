/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('smScrollspy', ['$timeout', '$rootScope', '$location', 'DeviceService',
      function($timeout, $rootScope, $location, DeviceService) {
        return {
          restrict: 'A',
          link: function(scope, elem, attrs) {

            //force self height for affixing
            $(window).on('load resize',function(){
              elem.height(elem.height());
            })

            var controller = new ScrollMagic.Controller();

            var url = $location.path().split('/');
            url = url.filter(function(value) {
              return value != '';
            });

            var updateUrl = function(section) {
              var newUrl = $location.path().split('/');
              if (newUrl[0] != section.substring(1)) {
                $location.update_path(section.substring(1));
              }
            }

            function scrollto(elemTgrt) {
              var offset = 0;
              if (attrs.dskOffset) {
                offset = typeof attrs.dskOffset === 'string' ? parseInt(attrs.dskOffset.replace('"', ''), 10) : attrs.dskOffset;
                if (isNaN(offset)) offset = 0;
              }
              if (DeviceService.check() == 'phone' && attrs.mblOffset) {
                offset = typeof attrs.mblOffset === 'string' ? parseInt(attrs.mblOffset.replace('"', ''), 10) : attrs.mblOffset;
                if (isNaN(offset)) offset = 0;
              }

              $('html, body').animate({
                scrollTop: $('#' + elemTgrt).offset().top - offset
              }, 1000);
            }

            var toggleArrowBouncing = function(section) {
              switch(section) {
                case '#overview':
                case '#move-in-ready':
                case '#model-home':
                  $('.arrow-bouncing').show();
                  break;
                default:
                  $('.arrow-bouncing').hide();
              }
            }

            $timeout(function() {
              elem.find('li a').add('#subnav').each(function() {
                //combinelistener for all elements including parent
                var smOffset = -300;
                var trigger = $(this).attr('href');

                if (DeviceService.check() == 'iPad'  && DeviceService.orientation() == 'portrait' ){
                  smOffset = -80;
                }

                if (DeviceService.check() == 'phone'){
                  smOffset = -60;
                }

                if ($(this).attr('id') == "subnav") {
                  trigger = "#" + attrs.id;
                }

                var scene = new ScrollMagic.Scene({
                    triggerElement: trigger,
                    triggerHook: "onLeave",
                    offset: smOffset
                  })
                  .on("enter leave", function(e) {
                    if (trigger == "#subnav") {
                      if (e.type == 'enter') {
                        $('header').addClass('slideUp isloaded');
                        elem.addClass('affixed');
                      } else {
                        $('header').removeClass('slideUp');
                        elem.removeClass('affixed');
                      }
                    } else {
                      var $target = elem.find('a[href="' + trigger + '"]').closest('li');

                      updateUrl(trigger);
                      toggleArrowBouncing(trigger);
                      if (e.type == "enter") {
                        $target.addClass('active')
                          .siblings().removeClass('active');
                      } else {
                        elem.find('li.active').prev().addClass('active')
                          .siblings().removeClass('active');
                      }
                    }
                  })
                  //.addIndicators({name:trigger})
                  .addTo(controller);
              })
            }, 0).then(function() {
              //scroll to on load
              if (url[0]) {
                $timeout(function() {
                  scrollto(url[0]);
                }, 2000)
              }
            })

          }
        }
      }
    ]);
}());
