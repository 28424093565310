/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('toggleQsu', ['DeviceService',
      function(DeviceService) {
        return {
          restrict: 'A',
          link: function(scope, elem, attrs) {

            $(window).on('load resize', function(){
              if(DeviceService.touch()){
                //all touch devices
                elem.addClass('is-touch')
                .find('> a').on('click', function(){
                  elem.toggleClass('open')
                  .siblings().removeClass('open');
                });
              }else{
                //non touch device
                var triggerIn = 'mouseenter';
                var stayOpen = false;

                $('body').on('click',function(event){
                  var qsuHitbox = $(event.target).closest('.dd-get-fast-answers').length;
                  if(!qsuHitbox){
                    elem.removeClass('open');
                  }
                })

                if('clickOnly' in attrs){
                  triggerIn = 'click';
                }

                elem.removeClass('is-touch')
                .on(triggerIn, function(){
                  if(attrs.dirtyCheck !== 'true' || stayOpen){
                    elem.addClass('open');
                  }else{
                    stayOpen = true;
                  }
                })
                .on('mouseleave', function(){
                  if(!attrs.dirtyCheck && !stayOpen){
                    elem.removeClass('open');
                  }
                })

                scope.$on('formDirtyStateChange', function(event, data) {
                  if (data.isDirty) {
                    stayOpen = true; 
                  } 
                });
              }
            });

            scope.$on('mobile-touch', function(event,prop){
              var num = $(prop.target).closest('.is-touch').length;
                if(!num){
                  elem.removeClass('open');
                } 
            });

          }
        };
      }
    ]);
}());
