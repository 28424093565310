(function(){
	'Use Strict';
	angular.module('app.longinterestlist').controller('longInterestListCtrl', ['$scope', function ($scope) {
		
		$scope.communitiesOfInterest = [
			{
				location: 'GREATER CINCINNATI',
				area: [
					'Amelia',
					'Anderson Township',
					'Batavia',
					'Batavia Township',
					'Colerain Township',
					'Fairfield',
					'Franklin Township',
					'Green Township',
					'Hamilton Township',
					'Harrison',
					'Lebanon',
					'Liberty Township',
					'Loveland',
					'Mason',
					'Miami Township',
					'Middletown',
					'Milford',
					'New Richmond',
					'Northside',
					'So Lebanon',
					'Springboro',
					'Trenton',
					'West Chester Township'
				]
			},
			{
				location: 'KY - Northern Kentucky',
				area: [
					'Alexandria',
					'Burlington',
					'Cold Spring',
					'Covington',
					'Dayton',
					'Erlanger',
					'Florence',
					'Fort Thomas',
					'Hebron',
					'Independence',
					'Union',
					'Walton'
				]
			},
			{
				location: 'OH - Columbus / Central Ohio',
				area: [
					'Blacklick',
					'Canal Winchester',
					'Columbus',
					'Delaware',
					'Dublin',
					'Grove City',
					'Groveport',
					'Jefferson Township',
					'Lewis Center',
					'Lockbourne',
					'Marysville',
					'New Albany',
					'Pickerington',
					'Plain City',
					'Powell',
					'Westerville'
				]
			},
			{
				location: 'IN - Southeastern Indiana',
				area: [
					'Lawrenceburg',
					'St. Leon'
				]
			},
			{
				location: 'GA - Metro Atlanta',
				area: [
					'West Cobb / Powder Springs',
					'South Cherokee / Woodstock',
					'South Forsyth / Cumming',
					'North Gwinnett / Sugar Hill'
				]
			}
		];

		angular.forEach($scope.communitiesOfInterest, function(value, key){
			var column = Math.ceil(value.area.length/3);
			$scope.communitiesOfInterest[key].area = _.chunk(value.area, column);
		})

	}])
}());