(function(){
	'Use Strict';
	angular.module('app.common')
	.service('youtubeConfigService', function(){

		this.default = function($scope, player, nextVid){
			var parseUrl = nextVid.replace('https://youtu.be/','');

			var play = 1;
			if(player == 'pause'){
				play = 0;
			}
	        $scope.playerVars = {
	            autoplay: play,
	            controls: 1,
	            showinfo: 0,
	            loop: 1,
	            disablekb: 1,
	            playlist: parseUrl,
	            modestbranding: 1,
	            mute: 1
	        };
		};

	});
}());