(function () {
  'use strict';
  /* global $, angular, LassoCRM */
  // Tests for this service are not written yet
  // Handle form functions and submissions and return message and instructions
  // Service only displays "pretty" messages, not debug info from the API
  angular.module('app.forms')
    .service('FormService', ['$http', 'TestDataService',
      function ($http, TestDataService) {
        var formService = {};

        /* ========================================
        =            Helper FUnctions            =
        ======================================== */

        formService.getDefaultRegionPhone = function () {
          return '855-342-2281';
        };

        /**
         *
         * Allows you to toggle the inclusion of an item
         *
         */
        formService.toggleSelection = function (id, selectedItems) {
          if (!(selectedItems instanceof Array)) {
            selectedItems = [];
          }

          // id = parseInt(id);
          if (!id) {
            return selectedItems;
          }

          var idx = selectedItems.indexOf(id);
          // is already  selected, remove it
          if (idx > -1) {
            selectedItems.splice(idx, 1);
          }
          // is newly selected, add it
          else {
            selectedItems.push(id);
          }

          return selectedItems;
        };

        /**
         *
         * Allows you to remove an item only
         *
         */
        formService.removeSelection = function (id, selectedItems) {
          if (!(selectedItems instanceof Array)) {
            selectedItems = [];
          }

          if (!id) {
            return selectedItems;
          }

          var idx = selectedItems.indexOf(id);
          // is already  selected, remove it
          if (idx > -1) {
            selectedItems.splice(idx, 1);
          }

          return selectedItems;
        };

        /**
         *
         * Allows you to open / close form sections
         *
         */
        formService.toggleClass = function (event) {
          $(event.currentTarget).closest('.checkbox-wrapper').siblings().find('.in').removeClass('in');
          $(event.currentTarget).toggleClass('in');
        };

        /**
         *
         * Handles the header QSU form's closing behavior
         *
         */
        formService.handleClose = function (target, className, event, formIsDirty) {
          var toggle = false;

          if (typeof formIsDirty === 'undefined') {
            formIsDirty = false;
          }

          if (event === 'hasEntered') {
            toggle = true;
          }

          if (
            event === 'hasLeft' &&
            !$('#top-nav-toggle .dd-get-fast-answers select').is(':focus')
          ) {
            toggle = true;
          }

          if (formIsDirty) {
            toggle = event === 'hasClicked';

            if (event === 'hasEntered' && !$(target).hasClass(className)) {
              toggle = true;
            }
          }

          if (toggle) {
            $(target).toggleClass(className);
          }
        };

        /**
         *
         * Checks if a date element is older than a given age
         *
         */
        formService.requirePrevious = function (formDateElement) {
          var cutoff = new Date();
          cutoff.setYear(cutoff.getFullYear() - 3);

          var current = new Date(formDateElement);
          current.setDate(current.getDate() + 1);

          return current >= cutoff;
        };

        /**
         *
         * Maintains page height after large forms are submitted and hidden
         *
         */

        formService.pageResizeOnSubmit = function () {
          var wh = $(window).height();
          var h = $('.header').height() + $('footer').height();
          /**

            TODO:
            - Make this work for any form that needs it
            - Second todo item

           */

          var $p = $('.full-interest-list');
          $p.height(wh - h);
        };

        /* =========================================
        =            Prefill Functions            =
        ========================================= */

        /**
         *
         * Populates the formData object in a form's Angular controller
         *
         */
        formService.prefillForm = function (prefillMode, formName) {
          var prefillContent = {
            formData: {
              origin: window.location.href,
              type: formName
            },
            formResult: {
              showForm: true,
              showLoader: false,
              showMessage: false
            }
          };

          if (typeof LassoCRM !== 'undefined' && LassoCRM) {
            prefillContent.formData.guid = window.getLassoGuid();
          }

          // Get test data if called for, and mix it with regular formData
          if (prefillMode) {
            prefillContent.formData = this.mix(
              TestDataService.get(formName),
              prefillContent.formData
            );
          }

          switch (formName) {
            case 'subcontractorsAndSuppliers':
              prefillContent.formData.worked_for_fischer = 1;
              break;

            default:
              break;
          }
          return prefillContent;
        };

        /**
         *
         * Combines two objects together
         *
         */
        formService.mix = function (source, target) {
          for (var key in source) {
            if (source.hasOwnProperty(key)) {
              target[key] = source[key];
            }
          }
          return target;
        };

        /* =====================================================
        =            Form submission and results processing   =
        ===================================================== */

        /**
         *
         * Hanldes the submit action and returns prepared results to Angular
         *
         */
        formService.submit = function (url, formData) {
          return $http({
            method: 'POST',
            url: url,
            data: $.param(formData),
            paramSerializer: '$httpParamSerializerJQLike',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
            // }).then(this.formServiceSubmitResultWash(result));
          }).then(formService.formServiceSubmitResultWash);
        };

        /**
         *
         * Returns the defulat fail text
         *
         */
        formService.getSubmitResultFailMessageText = function () {
          return 'Something went wrong, please contact us.';
        };

        /**
         *
         * Prepares the results from backend form processor
         *
         */
        formService.formServiceSubmitResultWash = function (result) {
          var failResult = {
            status: 'failure',
            showForm: true,
            showLoader: false,
            showMessage: true,
            message: formService.getSubmitResultFailMessageText(),
            data: false
          };

          if (result === undefined || result.data === undefined || result.data.status === undefined) {
            return failResult;
          }

          if (result.data.status === 'failure') {
            return failResult;
          }

          var successResult = {
            status: 'success',
            showForm: false,
            showLoader: false,
            showMessage: true,
            message: result.data.message,
            data: result.data.data
          };

          return successResult;
        };

        /**
         *
         * Hanldes the realtor sign up submit action
         * and returns prepared results to Angular
         *
         */
        formService.submitRealtorSignUp = function (url, formData) {
          return $http({
            method: 'POST',
            url: url,
            data: $.param(formData),
            paramSerializer: '$httpParamSerializerJQLike',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
            // }).then(this.formServiceSubmitResultWash(result));
          }).then(formService.formServiceSubmitRealtorSignUpResultWash);
        };

        /**
         *
         * Prepares the realtor sign up results from backend form processor
         *
         */
        formService.formServiceSubmitRealtorSignUpResultWash = function (result) {
          var failResult = {
            status: 'failure',
            showForm: true,
            showLoader: false,
            showMessage: true,
            message: formService.getSubmitResultFailMessageText(),
            redirect: false
          };

          if (result === undefined || result.data === undefined || result.data.status === undefined) {
            return failResult;
          }

          if (result.data.status === 'failure') {
            if (result.data.message) {
              failResult.message = result.data.message;
            }
            return failResult;
          }

          var successResult = {
            status: 'success',
            showForm: false,
            showLoader: false,
            showMessage: true,
            message: result.data.message,
            redirect: '/realtor-extranet/my-profile'
          };

          return successResult;
        };

        formService.getRegionsForSelect = function () {
          return [
            { id: 47, name: 'Atlanta, GA' },
            { id: 22, name: 'Columbus, OH' },
            { id: 35, name: 'Indianapolis, IN' },
            { id: 48, name: 'Louisville, KY' },
            { id: 51, name: 'St. Louis, MO' },
            { id: 16, name: 'Dayton, OH' },
            { id: 1, name: 'Cincinnati Region' },
            { id: 11, name: '-- Cincinnati, OH' },
            { id: 13, name: '-- Northern Kentucky' }
            // { id: 14, name: '-- Southeastern Indiana' }
          ];
        };

        return formService;
      }]);
})();
