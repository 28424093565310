(function(){
	'use strict';
	angular.module('app.vendorbid')
	.controller('vendorBidCtrl',['$scope','dummyDataService', function($scope, dummyDataService){

		$scope.slides = [
			{
				image: "/images/site/vendor-bid/hero-bkg.jpg",
				caption: "Welcome Fischer Homes Bidders"
			}
		];

		$scope.biddingDocuments = dummyDataService.biddingDocuments();

		//client login
		function checkValidity()
		{
			var returnText='The following fields are required:\n';
			returnText=returnText + '__________________________\n\n';
				if(document.signupForm.vendorCode.value=='')
					{returnText=returnText + '   Vendor Code\n'}
				if(document.signupForm.companyName.value=='')
					{returnText=returnText + '   Company Name\n'}	
				if(document.signupForm.userName.value=='')
					{returnText=returnText + '   User Name\n'}
			returnText=returnText + '__________________________\n\n';
			returnText=returnText + 'Please make corrections and \nclick the Sign In button.';

			if(returnText.length > 145)
				{alert(returnText);
				 return false;}
			else
				{return true;}
		}

	}]);
}());