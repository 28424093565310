/*jslint white: true, nomen: true */
/*global $, angular */
(function() {
  'use strict';

  angular.module('app.common')

  .service('TestimonialListDataService', ['$http', function($http) {
    return {
      getTestimonials: function(type, id) {
        var requestUrl = '/api/testimonial',
            variableName = '';

        if (type) {
          switch (type) {
            case 'community':
            variableName = 'community_id';
            break;
            case 'apartment':
            variableName = 'apartment_community_id';
            break;
            case 'storage':
            variableName = 'storage_community_id';
            break;
            case 'commercial':
            variableName = 'commercial_community_id';
            break;
          }
          requestUrl = requestUrl+'/?'+variableName+'='+id;
        }
        
        return $http.get(requestUrl);
      }
    };
  }]);
}());
