/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('homeTourCtrl', ['$scope', 'dummyDataService', 'youtubeConfigService', 'slickConfigService', '$timeout',
    function($scope, dummyDataService, youtubeConfigService, slickConfigService, $timeout) {

        youtubeConfigService.default($scope);

        $scope.mainCarousel = [
            {
                type: 'image',
                url: '/images/site/microsite/home-tour-hero.jpg'
            }
        ];

        $scope.active = 0;

        $scope.$on('youtube.player.ready', function ($event, player) {
            $scope.playerVars.playlist = $scope.mainCarousel[0].id; //sets loop
            player.mute();
        });

        //slick config
        $scope.slickFloorplan = slickConfigService.baseConfig(1);

        //floorplan image
        $scope.floorplanLevels = [
            {
                name: 'FIRST FLOOR',
                image: '/images/site/microsite/home-tour-floorplan.jpg',
                pins: [
                    {
                        top: '48%',
                        left: '50%',
                        rotate: '45',
                        placement: '',
                        popupImage: '/images/site/microsite/home-tour-popup.jpg'
                    },
                    {
                        top: '30%',
                        left: '32%',
                        rotate: '0',
                        placement: '',
                        popupImage: '/images/site/microsite/home-tour-popup.jpg'
                    },
                    {
                        top: '70%',
                        left: '30%',
                        rotate: '0',
                        placement: '',
                        popupImage: '/images/site/microsite/home-tour-popup.jpg'
                    }
                ]
            },
            {
                name: 'SECOND FLOOR',
                image: '/images/site/microsite/home-tour-floorplan.jpg',
                pins: [
                    {
                        top: '20%',
                        left: '60%',
                        rotate: '0',
                        placement: '',
                        popupImage: '/images/site/microsite/home-tour-popup.jpg'
                    },
                    {
                        top: '70%',
                        left: '60%',
                        rotate: '90',
                        placement: '',
                        popupImage: '/images/site/microsite/home-tour-popup.jpg'
                    },
                    {
                        top: '7%',
                        left: '37.5%',
                        rotate: '90',
                        placement: '',
                        popupImage: '/images/site/microsite/home-tour-popup.jpg'
                    }
                ]
            }
        ]

        //adjust popup position
        angular.forEach( $scope.floorplanLevels, function(item){
            angular.forEach(item.pins, function(pin){
                var vert = "";
                var hor = "";
                if(parseInt(pin.top)  < 30){vert = "bottom";}
                if(parseInt(pin.top)  > 70){vert = "top";}
                if(vert !== ''){vert + "-" + hor}
                if(parseInt(pin.left) < 30){hor  = "right";}
                if(parseInt(pin.left) > 70){hor  = "left";}
                pin.placement = vert + hor;
            })
        })

        $scope.activeTab = 0;

        $scope.setActiveTab = function(tab){
            $scope.activeTab = tab;
        }

    }]);
}());
