(function() {
  "use strict";

  angular
    .module('modules.googleMaps')
    .factory('DefaultGoogleMapFactory', ['uiGmapPromises',
      function(uiGmapPromises) {

        var defaultMapConfig = {
          control: {},
          markers: [],
          zoom: 13,
          options: {
            streetViewControl: false,
            draggable: true,
            scrollwheel: true,
            mapTypeControl: false,
            panControl: true,
            zoomControl: true
          }
        };

        var defaultConfig = function defaultConfig() {

          return uiGmapPromises.GoogleMapsApiReady()

            .then(function(GoogleMaps) {
              console.log("AAAAAAAA");

              defaultMapConfig.options.panControlOptions = {
                position: GoogleMaps.ControlPosition.LEFT_CENTER
              };

              defaultMapConfig.options.zoomControlOptions = {
                position: GoogleMaps.ControlPosition.LEFT_CENTER
              };

              defaultMapConfig.window = {
                marker: {},
                show: false,
                closeClick: function() {
                  this.show = false;
                  this.model = {};
                },
                options: {
                  maxWidth: 800,
                  pixelOffset: new GoogleMaps.Size(-5, -60)
                }
              };

            });
        };

        return defaultConfig;

      }
    ])
  ;

})();