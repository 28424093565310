angular
  .module('modules.snapshotRange')

  .filter('snapshotRange', ['$filter', function ($filter) {
    'use strict';

    return function (param, fractionSize, symbol) {

      if (param === undefined || param === null) {
        return (symbol === '$') ? 'Pricing Pending' : 'TBD';
      }

      if (typeof param === 'string') {
        // If param is formatted as number-number, format each number
        if (param.indexOf('-') > -1 && !isNaN(param.split('-')[0])) {
          var split_content = param.split('-');
          if (!isNaN(split_content[0])) {
            // Format with a comma
            split_content[0] = $filter('number')(split_content[0]);
          }
          if (split_content[1] && !isNaN(split_content[1])) {
            // Format with a comma
            split_content[1] = $filter('number')(split_content[1]);
          }

          param = split_content.join('-');
        }

        if (param.startsWith('0-')) {
          param = param.replace('0-', '');
        }
        return param;
      }

      if (param.price_status === 'Temporarily Sold Out' || param.price_status === 'Sold Out') {
        return param.price_status;
      }

      symbol = symbol || '';

      var input = {
        low: 0,
        high: 0
      };

      if (typeof param === 'object') {
        input.low = parseFloat(param.low);
        input.high = parseFloat(param.high);
      } else {
        var split_content = '';
        if (typeof param.split === 'function') {
          split_content = param.split('-');
        }

        if (split_content.length === 2) {
          input = {
            low: split_content[0],
            high: split_content[1]
          };
        } else {
          input = {
            low: parseFloat(param),
            high: 0
          };
        }
      }

      // Don't display whole numbers with fractional syntax
      // "1.0 - 2.5" should be "1 - 2.5"
      var lo_fraction = (input.low === Math.floor(input.low)) ? 0 : fractionSize;
      var hi_fraction = (input.high === Math.floor(input.high)) ? 0 : fractionSize;

      var lo = 1 * input.low || 0;
      var hi = 1 * input.high || 0;

      if (!lo && !hi) {
        return (symbol === '$') ? 'Pricing Pending' : 'TBD';
      }

      if (hi === 0) {
        return symbol + $filter('number')(lo, lo_fraction);
      }

      if (lo === 0) {
        return symbol + $filter('number')(hi, hi_fraction);
      }

      if (hi > lo) {
        return symbol + $filter('number')(lo, lo_fraction) + ' - ' + symbol + $filter('number')(hi, hi_fraction);
      }


      return symbol + $filter('number')(lo, lo_fraction);
    };
  }])
;
