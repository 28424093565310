(function() {
  'use strict';
  /* global $, angular */
  angular.module("app.forms")
    .service("FormComponentService", ['$http', 'TestDataService',
      function($http, TestDataService) {
        var self = {},
          formComponentConfig = {
            showTextInputPlaceholders: true,
            showSelectInputPlaceholders: true
          };

        /*=============================================
        =            Form Component Config            =
        =============================================*/

        self.setConfig = function(item, value) {
          formComponentConfig[item] = value;
        };

        self.getConfig = function() {
          return formComponentConfig;
        };

        /*=====  End of Form Component Config  ======*/

        /*========================================
        =            Helper Functions            =
        ========================================*/

        self.getConfigForType = function(type) {
          return type === 'text' ?
            formComponentConfig.showTextInputPlaceholders :
            formComponentConfig.showSelectInputPlaceholders;
        };

        self.getRequired = function(required) {
          if (typeof required === 'undefined') {
            required = true;
          }

          return required;
        };

        self.definePlaceholder = function(attr, defaultValue, type, required) {
          var result,
            showPlaceholder = self.getConfigForType(type);

          if (showPlaceholder) {
            attr = self.defineAttr(attr, defaultValue);
            result = self.getRequired(required) ? attr + '*' : attr;
          } else {
            result = '';
          }

          return result;
        };

        self.defineAttr = function(attr, defaultValue) {
          if (typeof attr === 'undefined') {
            return defaultValue;
          }

          return attr;
        };

        /*----------  Datepicker Helpers  ----------*/

        self.getDatePickerDates = function(type) {
          var result;

          switch (type) {
            case 'futureOnly':
              result = self.getDatePickerFutureOnlyDates();
              break;

            case 'pastOnly':
              result = self.getDatePickerPastOnlyDates();
              break;

            default:
              break;
          }

          return result;
        };

        self.getDatePickerFutureOnlyDates = function() {
          // Enabled future only selections
          var result,
            minDate,
            maxDate;

          minDate = new Date();
          minDate.setDate(minDate.getDate() + 1);

          maxDate = new Date();
          maxDate.setYear(maxDate.getFullYear() + 3);
          maxDate.setDate(maxDate.getDate() + 1);

          return {
            minDate: minDate,
            maxDate: maxDate
          };
        };

        self.getDatePickerPastOnlyDates = function() {
          // Enabled past only selections
          var maxDate;

          maxDate = new Date();
          maxDate.setDate(maxDate.getDate());

          return maxDate;
        };

        /*----------  State Select Helpers  ----------*/

        self.getStates = function() {
          return [{
            "name": "Alabama",
            "abbreviation": "AL"
          }, {
            "name": "Alaska",
            "abbreviation": "AK"
          }, {
            "name": "Arizona",
            "abbreviation": "AZ"
          }, {
            "name": "Arkansas",
            "abbreviation": "AR"
          }, {
            "name": "California",
            "abbreviation": "CA"
          }, {
            "name": "Colorado",
            "abbreviation": "CO"
          }, {
            "name": "Connecticut",
            "abbreviation": "CT"
          }, {
            "name": "Delaware",
            "abbreviation": "DE"
          }, {
            "name": "Dist. of Columbia",
            "abbreviation": "DC"
          }, {
            "name": "Florida",
            "abbreviation": "FL"
          }, {
            "name": "Georgia",
            "abbreviation": "GA"
          }, {
            "name": "Hawaii",
            "abbreviation": "HI"
          }, {
            "name": "Idaho",
            "abbreviation": "ID"
          }, {
            "name": "Illinois",
            "abbreviation": "IL"
          }, {
            "name": "Indiana",
            "abbreviation": "IN"
          }, {
            "name": "Iowa",
            "abbreviation": "IA"
          }, {
            "name": "Kansas",
            "abbreviation": "KS"
          }, {
            "name": "Kentucky",
            "abbreviation": "KY"
          }, {
            "name": "Louisiana",
            "abbreviation": "LA"
          }, {
            "name": "Maine",
            "abbreviation": "ME"
          }, {
            "name": "Maryland",
            "abbreviation": "MD"
          }, {
            "name": "Massachusetts",
            "abbreviation": "MA"
          }, {
            "name": "Michigan",
            "abbreviation": "MI"
          }, {
            "name": "Minnesota",
            "abbreviation": "MN"
          }, {
            "name": "Mississippi",
            "abbreviation": "MS"
          }, {
            "name": "Missouri",
            "abbreviation": "MO"
          }, {
            "name": "Montana",
            "abbreviation": "MT"
          }, {
            "name": "Nebraska",
            "abbreviation": "NE"
          }, {
            "name": "Nevada",
            "abbreviation": "NV"
          }, {
            "name": "New Hampshire",
            "abbreviation": "NH"
          }, {
            "name": "New Jersey",
            "abbreviation": "NJ"
          }, {
            "name": "New Mexico",
            "abbreviation": "NM"
          }, {
            "name": "New York",
            "abbreviation": "NY"
          }, {
            "name": "North Carolina",
            "abbreviation": "NC"
          }, {
            "name": "North Dakota",
            "abbreviation": "ND"
          }, {
            "name": "Ohio",
            "abbreviation": "OH"
          }, {
            "name": "Oklahoma",
            "abbreviation": "OK"
          }, {
            "name": "Oregon",
            "abbreviation": "OR"
          }, {
            "name": "Pennsylvania",
            "abbreviation": "PA"
          }, {
            "name": "Rhode Island",
            "abbreviation": "RI"
          }, {
            "name": "South Carolina",
            "abbreviation": "SC"
          }, {
            "name": "South Dakota",
            "abbreviation": "SD"
          }, {
            "name": "Tennessee",
            "abbreviation": "TN"
          }, {
            "name": "Texas",
            "abbreviation": "TX"
          }, {
            "name": "Utah",
            "abbreviation": "UT"
          }, {
            "name": "Vermont",
            "abbreviation": "VT"
          }, {
            "name": "Virginia",
            "abbreviation": "VA"
          }, {
            "name": "Washington",
            "abbreviation": "WA"
          }, {
            "name": "West Virginia",
            "abbreviation": "WV"
          }, {
            "name": "Wisconsin",
            "abbreviation": "WI"
          }, {
            "name": "Wyoming",
            "abbreviation": "WY"
          }];
        };


        /*=====  End of Helper Functions  ======*/

        return {
          setConfig: self.setConfig,
          getConfig: self.getConfig,
          definePlaceholder: self.definePlaceholder,
          defineAttr: self.defineAttr,
          getDatePickerDates: self.getDatePickerDates,
          getStates: self.getStates
        };
      }]);
}());