(function() {
  "use strict";

  //added the i elem to style a dropdown, if this is causing issues, please let me know -ko

  angular.module("app.common")
    .directive("categorySelect", [ '$filter',
      function($filter) {
        return {
          restrict: 'E',
          transclude: true,
          scope: {
            list: '=',
            column: '@',
            label: '@',
            value: '=model',
            displayFilter: '@'
          },
          template: '' +
            '<i class="fa fa-arrow-down" aria-hidden="true"></i>' +
            '<select ' +
            '   class="form-control filter-select" ' +
            '   ng-model="value" ' +
            '> ' +
            '   <option value="" ng-bind-html="label | stripWords"></option> ' +
            '   <option ' +
            '       ng-repeat="o in options() | orderBy :\'toString()\'" ' +
            '       value="<% o %>" ' +
            '   > ' +
            '       <% applyDisplayFilter(o) %> ' +
            '   </option> ' +
            '</select> ',

          link: function(scope, element, attributes) {

            scope.options = function() {
              var opts = scope.selectOptions(scope.list, scope.column);
              return opts;
            };

            scope.selectOptions = function(list, column) {
              var results = [];

              var pushUnique = function(value) {
                if (value && results.indexOf(value) === -1) {
                  results.push(value);
                }
              };

              angular.forEach(list, function(listItem){
                var category = scope.$eval("listItem." + column, {'listItem': listItem});

                if (Array.isArray(category)) {
                  category.map(pushUnique);
                } else {
                  pushUnique(category);
                }
              });

              return results;
            };

            scope.applyDisplayFilter = function(val)
            {
              if (!scope.displayFilter) {
                return val;
              }

              return scope.$eval(val + " | " + scope.displayFilter);
            };
          }
        };
      }
    ])
  ;
}());
