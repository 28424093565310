(function() {
  "use strict";

   //added the i elem to style a dropdown, if this is causing issues, please let me know -ko

  angular.module("app.common")
    .directive("rangeSelect", [ '$filter',
      function($filter) {
        return {
          restrict: 'E',
          transclude: true,
          scope: {
            list: '=',
            column: '@',
            columnHigh: '@',
            step: '@',
            label: '@',
            value: '=model',
            displayFilter: '@',
            showArrow: '@',
            min: '@',
            max: '@'
          },
          template: '' +
            '<i class="fa fa-arrow-down" aria-hidden="true"></i>' +
            '<select ' +
            '   class="form-control filter-select" ' +
            '   ng-model="value" ' +
            '> ' +
            '   <option value="" ng-bind-html="label | stripWords"></option> ' +
            '   <option ' +
            '       ng-repeat="o in options()" ' +
            '       value="<% o %>" ' +
            '   > ' +
            '       <% applyDisplayFilter(o) %> ' +
            '   </option> ' +
            '</select> ',

          link: function(scope, element, attributes) {
            element.addClass('range-select');

            if (scope.min != null) {
              scope.min =  1 *  scope.min;
            }

            if (scope.max != null) {
              scope.max =  1 *  scope.max;
            }

            scope.options = function() {
              var opts = scope.selectRange(
                //                                      Use the larger "min"
                (scope.min == null) ? scope.listMin() : Math.max(scope.min, scope.listMin()),
                //                                      Use the smaller "max"
                (scope.max == null) ? scope.listMax() : Math.min(scope.max, scope.listMax()),
                scope.step
              );

              return opts;
            };

            scope.listMin = function() {
              return scope.list.reduce(function(carry, e) {
                e = e[scope.column];

                if (!carry) {
                  return e;
                }

                if (!e) {
                  return carry;
                }

                return (e < carry) ? e : carry;
              }, 0);
            };

            scope.listMax = function() {
              return scope.list.reduce(function(carry, e) {
                var column = scope.column;

                if (scope.columnHigh) {
                  if (e[scope.columnHigh] > e[scope.column]) {
                    column = scope.columnHigh;
                  }
                }

                return (e[column] > carry) ? e[column] : carry;
              }, 0);
            };

            scope.selectRange = function(min, max, step) {
              var res = [];

              step = 1*step;
              min = 1*min;
              max = 1*max;

              var startRange = min + step - (min % step); // start with one notch below the min

              var endRange = (max % step === 0) ? max : (max + step) - (max % step); // end with one notch above the max or with the max

              for(var i = startRange; i <= endRange; i = i + step) {
                res.push(i);
              }

              return res;
            };

            scope.applyDisplayFilter = function(val)
            {
              if (!scope.displayFilter) {
                return val;
              }

              return scope.$eval(val + " | " + scope.displayFilter);
            };
          }
        };
      }
    ])
  ;
}());