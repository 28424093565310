/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentHearAboutSelect', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-hear-about-select',
        replace: true,
        scope: {
          defaultPlaceholder: '@',
          model: '=',
          formObj: '=',
          formScope: '=',
          selectName: '=?',
          isRequired: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          var self = this;

          if (! self.defaultPlaceholder) {
            self.defaultPlaceholder = 'How did you hear about us?';
          }
          if (! self.selectName) {
            self.selectName = 'hear_about';
          }

          if (typeof self.isRequired === 'undefined') {
            self.isRequired = true;
          }

          self.methods = ['Realtor', 'Fischer Homeowner', 'Fischer Associate', 'Billboard', 'Community Event', 'Direct Mail', 'fischerhomes.com', 'Newspaper', 'Signage', 'Social Media', 'TV/Radio', 'Zillow/Trulia', 'Other'];

        }]
      };

    }]);
}());