  /* global ga */
(function() {
  'use strict';
  angular.module("app.forms")
    .service("GoogleAnalyticsService", ['$http',
      function($http) {
        var service = {};

        service.sendPageView = function(url) {
          if (typeof url === 'undefined') {
            console.log('Google Analytics pageViewUrl not defined.');
            return false;
          }

          if (typeof ga === 'undefined') {
            console.log('ga service not defined');
            return false;
          }

          ga('create', 'UA-802100-1');
          ga(function(tracker) {
            ga('send', 'pageview', url);
          });

          return true;
        };

        return service;
      }]);
})();