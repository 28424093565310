/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('modules.googleMaps')
  .service('LocalAmenitiesService', ['$sce', function($sce) {
    var defaultLocalAmenities = [{
      name: 'Schools',
      icon: 'fa fa-graduation-cap',
      pin_icon: '/images/site/icon-map-pin.png',
      pins: [],
      types: ['school', 'library']
    }, {
      name: 'Recreation',
      icon: 'fa fa-bicycle',
      pin_icon: '/images/site/icon-map-pin.png',
      pins: [],
      types: ['aquarium', 'art_gallery', 'campground', 'museum', 'park', 'zoo', 'amusement_park', 'bowling_alley', 'casino', 'stadium']
    }, {
      name: 'Restaurants',
      icon: 'fa fa-spoon',
      pin_icon: '/images/site/icon-map-pin.png',
      pins: [],
      types: ['bakery', 'bar', 'cafe', 'food', 'meal_delivery', 'meal_takeaway', 'restaurant']
    }, {
      name: 'Grocery',
      icon: 'fa fa-shopping-cart',
      pin_icon: '/images/site/icon-map-pin.png',
      types: ['grocery_or_supermarket'],
      pins: []
    }, {
      name: 'Retail',
      icon: 'fa fa-diamond',
      pin_icon: '/images/site/icon-map-pin.png',
      types: ['department_store', 'shopping_mall', 'clothing_store', 'electronics_store', 'book_store', 'bicycle_store', 'furniture_store', 'beauty_salon', 'hair_care', 'hardware_store', 'home_goods_store', 'jewelry_store', 'pet_store', 'shoe_store'],
      pins: []
    }, {
      name: 'Hospitals',
      icon: 'fa fa-hospital-o',
      pin_icon: '/images/site/icon-map-pin.png',
      pins: [],
      types: ['hospital']
    }];

    return {
      data: defaultLocalAmenities
    };
  }]);
}());
