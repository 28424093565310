(function(){
	'use strict';
	angular.module('app.customertestimonials')
	.controller('customerTestimonialsCtrl',['$scope','TestimonialListDataService',
		function($scope, TestimonialListDataService){

		TestimonialListDataService.getTestimonials().then(function(result){
			$scope.testimonials = result.data.testimonials;
		});

		$scope.limit = 10;

		$scope.expand = function(){
			$scope.limit += 10;
		}

		$scope.setActiveTestimony = function(testimony) {
			$scope.activeTestimony = testimony;
		}

		$scope.slide = {
				image: "/images/site/customer-testimonials/hero-bkg.jpg",
				caption: "Customer Reviews"
			}
		;

	}]);
}());