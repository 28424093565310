(function() {
  'use strict';

  angular.module('app.forms')
    .directive('fhFormMeetAndGreet', [function() {
      return {
        restrict: "E",
        scope: {
          analyticsConfig: '='
        },
        bindToController: true,
        controllerAs: 'vm',
        templateUrl: 'forms/fh-form-meet-and-greet',
        link: function() {},
        controller: [
          'FormService',
          'DeviceService',
          'AnalyticsService',
          function(
            FormService,
            DeviceService,
            AnalyticsService
          ) {

            var self = this,
              prefillMode = false,
              prefillData = FormService.prefillForm(prefillMode, 'meetAndGreet');

            self.formData    = prefillData.formData;
            self.formResult  = prefillData.formResult;

            self.checkFormValidation = function(form) {
              self.triedSubmit = true;

              if (! form.$valid) {
                return;
              }

              processGetInTouchForm();
            };

            function processGetInTouchForm() {
              self.formResult.showForm = false;
              self.formResult.showLoader = true;

              prepareFormData();

              FormService.submit('/forms/meet-and-greet', self.formData)
                .then(function(result) {
                  self.formResult = result;

                  AnalyticsService.logTrackingClick(self.analyticsConfig);
                });
            }

            function prepareFormData() {
              return;
            }

          }]
      };
    }]);
})();