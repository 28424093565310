/*jslint white: true, nomen: true */
/*global angular, $ */
(function(){
	'use strict';

	angular.module('app.common')
	.directive('cancelScrollTo', function(){
		return{
			restrict: 'A',
			link: function(scope, elem){
				/*
					Cancels out any jquery scrolling animation if user scrolls manually;
				*/
				$('body,html').bind('touchStart touchMove scroll mousedown wheel DOMMouseScroll mousewheel keyup', function(e){
					if ( e.which > 0 || e.type == "mousedown" || e.type == "mousewheel"
						|| e.type == "touchStart" || e.type == "touchMove"){
						$("html,body").stop();
					}
				});

			}
		}
	})
}());