/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function() {
    'use strict';
  
    angular.module('app.microsite')
      .controller('MicrositeImageGalleryCtrl', ['$scope', 'slickConfigService', '$rootScope',
        function($scope, slickConfigService, $rootScope) {

          function waitForElement(selector) {
            return new Promise((resolve, reject) => {
              const observer = new MutationObserver((mutations) => {
                const element = document.querySelector(selector);
                if(element){
                  resolve(element);
                  observer.disconnect();
                }
              });
        
              observer.observe(document,{childList: true, subtree: true,})
              setTimeout(() => {
                observer.disconnect();
                reject(new Error(`Element with selector "${selector}" did not appear in the DOM.`));
              }, 10000); 
            });
          }
        
  
  waitForElement('#img-gallery-carousel').then((element) => {
    var carousel1 = $(element).slick({
      lazyLoad: 'progressive',
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      arrows: false,
      initialSlide: 0,
      speed: 0,
      asNavFor: '#img-gallery-carousel-nav3',
    });

    carousel1.off('afterChange').on('afterChange', function(event, slick, currentSlide, nextSlide){
      console.log('called');
      updateSlideCount();
    });

    $('.img-gallery-next').on('click', function() {
        carousel1.slick('slickPrev');
        updateSlideCount();
    });
    
    $('.img-gallery-prev').on('click', function() {
        carousel1.slick('slickNext');
        updateSlideCount();
    });
    $('.img-gallery-next-xs').on('click', function() {
      carousel1.slick('slickPrev');
      updateSlideCount();
  });
  
  $('.img-gallery-prev-xs').on('click', function() {
      carousel1.slick('slickNext');
      updateSlideCount();
  });
    function updateSlideCount(){

      let activeSlideNumber = carousel1.slick('slickCurrentSlide');        
      let totalSlideNumber = carousel1.slick('getSlick').slideCount;

/*
      if (activeSlideNumber == 0){
        activeSlideNumber = totalSlideNumber;
      }
*/
      $('.slide-number').text(activeSlideNumber+1);
      
      $('.total-slides').text(totalSlideNumber);
    }


  
    updateSlideCount();

  }).catch(error => {
    console.error(error);
  });

  waitForElement('#img-gallery-carousel-nav1').then((element) => {
    var carousel2 = $(element).slick({
      lazyLoad: 'progressive',
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      arrows: false,
      initialSlide: 1,
      speed: 0,
      rows: 1,
    });
  
    $('.img-gallery-next').on('click', function() {
        carousel2.slick('slickPrev');
    });
    
    $('.img-gallery-prev').on('click', function() {
        carousel2.slick('slickNext');
    });

  
  }).catch(error => {
    console.error(error);
  });

  waitForElement('#img-gallery-carousel-nav2').then((element) => {
    var carousel3 = $(element).slick({
      lazyLoad: 'progressive',
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      arrows: false,
      initialSlide: 3,
      speed: 0,
    });
  
    $('.img-gallery-next').on('click', function() {
        carousel3.slick('slickPrev');
    });
    
    $('.img-gallery-prev').on('click', function() {
        carousel3.slick('slickNext');
    });


  
  }).catch(error => {
    console.error(error);
  });
  waitForElement('#img-gallery-carousel-nav3').then((element) => {
    var carousel3 = $(element).slick({
      lazyLoad: 'progressive',
      slidesToShow: 3,
      infinite: true,
      centerMode: true,
      arrows: false,
      initialSlide: 0,
      asNavFor: '#img-gallery-carousel',
      focusOnSelect: true,
      swipeToSlide: true,
      touchThreshold: 2,
    });
  
    $('.img-gallery-next-xs').on('click', function() {
        carousel3.slick('slickPrev');
    });
    
    $('.img-gallery-prev-xs').on('click', function() {
        carousel3.slick('slickNext');
    });


  
  }).catch(error => {
    console.error(error);
  });

  $scope.galleryModal = function(){
    var modalContent = $('.modal-container');
    modalContent.addClass('gallery-modal');
    var modalCloseHandler = $rootScope.$on('modalClose', function(){
      modalContent.removeClass('gallery-modal');
      modalCloseHandler();
    });
  }

}])
}());
