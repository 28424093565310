(function(){
  'use strict';
  angular
    .module('app.common')
    .directive('floorplanGallery', ['slickConfigService', 'DeviceService', '$sce', '$timeout',
      function(slickConfigService, DeviceService, $sce, $timeout){
        return{
          restrict: 'E',
          transclude: true,
          scope: {
            communityData: '=communityData',
            planData: '=planData',
            templateData: '=templateData'
          },
          link: function(scope, element, attrs) {

            var mutationObserver = new MutationObserver(function(mutations) {
              mutations.forEach(function(mutation) {
                if (mutation.attributeName === 'class') {
                  var isTab = mutation.target.classList.contains('uib-tab');
                  var isInteractiveFloorplanTab = mutation.target.getAttribute('heading') === 'Interactive Floorplan';
                  if (isTab && isInteractiveFloorplanTab) {
                    $timeout(function(){
                      if (mutation.target.classList.contains('active')) {
                        scope.isInteractiveFloorplanTabActive = true;
                      } else {
                        scope.isInteractiveFloorplanTabActive = false;
                      }
                    });
                  }
                }
              });
            });

            scope.isInteractiveFloorplanTabActive = false;

            mutationObserver.observe(element[0], { attributes: true, childList: true, subtree: true });

            scope.$watchGroup(['communityData', 'planData'], function(is, was){
              if (!is || is === was) {
                return;
              }
              if (scope.planData.sapphire_interactive_floorplan_community_collection_id && scope.planData.sapphire_interactive_floorplan_id) {
                scope.sapphireInteractiveFloorplanUrl = $sce.trustAsResourceUrl("https://www.fischerhomes.com/kova-sapphire/demo/demo-modified.html#!c:" + scope.planData.sapphire_interactive_floorplan_community_collection_id + "-m:" + scope.planData.sapphire_interactive_floorplan_id);
              }
            });

            scope.$watch('templateData', function(is, was){
              if (!is || is === was) {
                return;
              }
              scope.slickFloorplan = slickConfigService.baseConfig(1);
            });

            scope.mobileLinkOut = function(img){
              if(DeviceService.check() == 'phone'){
                window.open(img,'_blank');
              }
            }
          },
          templateUrl: '/components/floorplan-gallery'
        }
      }
    ])
  ;
}());
