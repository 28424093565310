/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function() {
    'use strict';

    angular.module('app.whodey')
    .controller("whodeyCtrl", [
        '$scope',
        '$timeout',
        'DeviceService',
        '$window',
        '$sce',
        '$document',

        function($scope,
            $timeout,
            DeviceService,
            $window,
            $sce,
            $document,
            
        ){
            $scope.galleryItems = [
                {
                    id: 18,
                    title: "Dear Diary from the Lower Level",
                    description: "Who Dey back in action, and wow, has this been a great season!  This week, we’re diving into the lower level recreation space...",
                    pubTimestamp: "Jan 08, 2024 9:15:00 AM",
                    readDuration: "1 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-18.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-lower-level-who-dey-at-home',
                },
                {
                    id: 17,
                    title: "Dear Diary from the Living Room",
                    description: "Who Dey back in action, ready to explore a Fischer Homes masterpiece! This week, I had the privilege of diving headfirst...",
                    pubTimestamp: "Jan 02, 2024 9:32:09 AM",
                    readDuration: "1 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-17.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-living-room-who-dey-at-home',
                },
                {
                    id: 16,
                    title: "Dear Diary from the Master Bath",
                    description: "Hey Bengals fans, Who Dey here, and today, your favorite feline mascot is on a mission to uncover the secrets of a Fischer Homes’...",
                    pubTimestamp: "Dec 18, 2023 1:21:13 PM",
                    readDuration: "1 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-16.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-master-bath-who-dey-at-home',
                },
                {
                    id: 15,
                    title: "Dear Diary from the Kids Bedroom",
                    description: "Hey, Who Dey fans! Your favorite striped explorer is back, and oh boy, did I stumble upon a treasure trove of Bengals magic this time!",
                    pubTimestamp: "Dec 11, 2023 1:21:13 PM",
                    readDuration: "1 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-15.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-kids-bedroom-who-dey-at-home',
                },
                {
                    id: 14,
                    title: "Dear Diary from a Fischer Homes Community",
                    description: "Greetings, Bengals devotees! Who Dey here, and I'm switching up the playbook this week to showcase not just a Fischer Home... ",
                    pubTimestamp: "Dec 04, 2023 9:47:22 AM",
                    readDuration: "1 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-14.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-a-fischer-homes-community-who-dey-at-home',
                },
                {
                    id: 13,
                    title: "Dear Diary from a Cincinnati Friendsgiving",
                    description: "Who Dey checking in, ready to take on another fabulous Fischer Homes adventure! This time, we're unwrapping the magic within...",
                    pubTimestamp: "Nov 27, 2023 11:12:31 AM",
                    readDuration: "1 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-13.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-a-cincinnati-friendsgiving-who-dey-at-home',
                },
                {
                    id: 12,
                    title: "Dear Diary from the Bedroom",
                    description: "Who Dey here, ready to unveil the grandeur of another enchanting space in a Fischer Home. This week, we're exploring the...",
                    pubTimestamp: "Nov 20, 2023 8:48:25 AM",
                    readDuration: "2 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-12.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-bedroom-who-dey-at-home',
                },
                {
                    id: 11,
                    title: "Dear Diary from the Heart of the Home",
                    description: "Who Dey here, and I'm back to explore the fantastic homes offered by Fischer Homes! This week, I'm on a grand adventure to...",
                    pubTimestamp: "Nov 13, 2023 10:02:06 AM",
                    readDuration: "2 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-11.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-heart-of-the-home-who-dey-at-home',
                },
                {
                    id: 10,
                    title: "Dear Diary from my At-Home Tailgate",
                    description: "Welcome, Bengals fans! Today, we're taking the excitement outdoors as we craft the perfect game day experience that revolves...",
                    pubTimestamp: "Nov 06, 2023 10:05:51 AM",
                    readDuration: "2 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-10.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-my-at-home-tailgate-who-dey-at-home',
                },
                {
                    id: 9,
                    title: "Dear Diary from the Deck",
                    description: "Boo! It's Who Dey, your spirited Cincinnati Bengals mascot, here to add a touch of Halloween spirit to the football season! ...",
                    pubTimestamp: "Oct 30, 2023 9:18:18 AM",
                    readDuration: "1 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-9.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-deck-who-dey-at-home',
                },
                {
                    id: 8,
                    title: "Dear Diary from my Favorite Spaces",
                    description: "Hey there, Bengals fans! It's Who Dey, and I'm excited to take you on a special tour of a Fischer Home during this bye week... ",
                    pubTimestamp: "Oct 23, 2023 9:37:46 AM",
                    readDuration: "2 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-8.jpg',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-my-favorite-spaces-who-dey-at-home',
                },
                {
                    id: 7,
                    title: "Dear Diary from the Kitchen",
                    description: "Hello, Cincinnati Bengals fans! Who Dey here, your spirited Bengals mascot, with another exciting Who Dey at Home update. This...",
                    pubTimestamp: "Oct 16, 2023 9:43:57 AM",
                    readDuration: "1 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-7.webp',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-kitchen-who-dey-at-home',
                },
                {
                    id: 6,
                    title: "Dear Diary from the Loft",
                    description: "Hey there, fellow Bengals fans! It's Who Dey, your spirited Cincinnati Bengals mascot, back for another thrilling edition of...",
                    pubTimestamp: "Oct 09, 2023 10:45:13 AM",
                    readDuration: "2 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-6.webp',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-loft-who-dey-at-home',
                },
                {
                    id: 5,
                    title: "Dear Diary from the Front Yard",
                    description: "It's your favorite mascot, Who Dey, here once again to explore the enchanting world of Fischer Homes and the unique features... ",
                    pubTimestamp: "Oct 02, 2023 9:53:15 AM",
                    readDuration: "1 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-5.webp',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-front-yard-who-dey-at-home',
                },
                {
                    id: 4,
                    title: "Dear Diary from the Morning Room",
                    description: "Who Dey here, ready to share some exciting news with you. I'm going to bring you an inside look at some of the most fabulous...",
                    pubTimestamp: "Sep 25, 2023 8:00:00 AM",
                    readDuration: "2 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-4.webp',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-morning-room-who-dey-at-home',
                },
                {
                    id: 3,
                    title: "Dear Diary from the Front Porch",
                    description: "Who Dey is back, and this week, I'm excited  to showcase another part of a Fischer Home haven - the front porch and its...",
                    pubTimestamp: "Sep 15, 2023 12:04:44 PM",
                    readDuration: "2 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-3.webp',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-front-porch-who-dey-at-home',
                },
                {
                    id: 2,
                    title: "Dear Diary from the Owner's Suite",
                    description: "Who Dey here, and I am so excited for the football season to be in full swing. Only two days remain before we have our first...",
                    pubTimestamp: "Sep 11, 2023 10:30:05 AM",
                    readDuration: "2 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-2.webp',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-the-owners-suite-who-dey-at-home',
                },
                {
                    id: 1,
                    title: "Dear Diary from Who Dey at Home",
                    description: "Hey there, Bengals fans! Who Dey here, and boy do I have some exciting news to share with you! I've traded my helmet for a...",
                    pubTimestamp: "Sep 7, 2023 12:24:14 PM",
                    readDuration: "2 MIN READ",
                    imageUrl: '/images/site/cincinnati-bengals/who-dey-blog-img-1.webp',
                    destinationUrl: 'https://blog.fischerhomes.com/dear-diary-from-who-dey-at-home',
                }
            ];

            $scope.currentIndex = 0;

            $scope.prevGallery = function () {
                if ($scope.currentIndex > 0) {
                    scrollGallery(false);
                }
            };

            $scope.nextGallery = function () {
                scrollGallery(true);
            };

            function checkBoundary(right){
                var innerElement = document.getElementById('gallery-inner-wrapper');
                var outerElement = document.getElementById('gallery-outer-wrapper');

                var innerRect = innerElement.getBoundingClientRect();
                var outerRect = outerElement.getBoundingClientRect();

                if(right){
                    if(innerRect.right >= outerRect.right + 50) {
                        return true;
                    }
                } else {
                    if(innerRect.left <= 0) {
                        return true;
                    }
                }

                return false;
            }

            function scrollGallery(increment){
                var galleryElement = document.getElementById('gallery-inner-wrapper');
                var outerItemWrapper = document.getElementById(`outer-wrapper-item-${$scope.currentIndex}`);
                
                var outerItemWrapperLeft = outerItemWrapper.getBoundingClientRect().left;
                var outerItemWrapperWidth = outerItemWrapper.clientWidth;
                var currentPosition = galleryElement.getBoundingClientRect().left;
                
                var newPosition;
                if(increment){
                    newPosition = currentPosition - outerItemWrapperWidth - outerItemWrapperLeft;
                    $scope.currentIndex++;
                } else {
                    newPosition = currentPosition + outerItemWrapperWidth - outerItemWrapperLeft;
                    $scope.currentIndex--;
                }

                galleryElement.style.left = `${newPosition}px`;

                // Since the CSS animation invoked by the above style change is 
                // set to 300 ms, this timeout will ensure the animation is 
                // complete before function enableGalleryBtns() is called.
                setTimeout(() => {
                    enableGalleryBtns();
                }, 300)

            }

            function enableGalleryBtns(){
                
                var prevGalleryBtn = document.getElementById('left-carousel-btn');
                var nextGalleryBtn = document.getElementById('right-carousel-btn');
                if($scope.currentIndex <= 0) {
                    prevGalleryBtn.setAttribute('disabled','disabled');
                    prevGalleryBtn.classList.add('disabled');

                } else {
                    prevGalleryBtn.removeAttribute('disabled');
                    prevGalleryBtn.classList.remove('disabled');
                }
                
                if($scope.currentIndex == $scope.galleryItems.length - 1 || !checkBoundary(true)) {
                    nextGalleryBtn.setAttribute('disabled', 'disabled');
                    nextGalleryBtn.classList.add('disabled');
                } else {
                    nextGalleryBtn.removeAttribute('disabled');
                    nextGalleryBtn.classList.remove('disabled');
                }
            }

            $scope.slides = [
                {
                    id: 19,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-19-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Browns',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/Qb4_A1PA5vo?controls=0&rel=0'),
                },
                {
                    id: 18,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-18-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Chiefs',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/2FYExa8Vza0?controls=0&rel=0'),
                },
                {
                    id: 17,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-17-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Steelers',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/GgfZeUsoRRI?controls=0&rel=0'),
                },
                {
                    id: 16,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-16-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Vikings',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/X878b_4Ji5A?controls=0&rel=0'),
                },
                {
                    id: 15,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-15-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Colts',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/yxtcn-GvadA?controls=0&rel=0'),
                },
                {
                    id: 14,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-14-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals at Jaguars',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/wCiay17PHME?controls=0&rel=0'),
                },
                {
                    id: 13,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-13-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Steelers',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/igw8OjfFMIk?controls=0&rel=0'),
                },
                {
                    id: 12,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-12-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals at Ravens',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/oHoZjNjZUV0?controls=0&rel=0'),
                },
                {
                    id: 11,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-11-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Texans',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/DOhqNMt8O1w?controls=0&rel=0'),
                },
                {
                    id: 10,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-10-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Bills',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/ARo7XAovn44?controls=0&rel=0'),
                },
                {
                    id: 9,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-9-img.jpg',
                    classname: 'who-dey-home',
                    description: 'Bengals at SF 49ers',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/mgeTB5bzVnw?controls=0&rel=0'),
                },
                {
                    id: 8,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-8-img.webp',
                    classname: 'who-dey-home',
                    description: 'Bengals Bye Week',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/QrnLp1x8NdM?controls=0&rel=0'),
                },
                {
                    id: 7,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-7-img.webp',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Seahawks',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/RZ4UPz0XF0M?controls=0&rel=0'),
                },
                {
                    id: 6,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-6-img.webp',
                    classname: 'who-dey-home',
                    description: 'Bengals @ Cardinals',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/2ol-T2ssts8?controls=0&rel=0'),
                },
                {
                    id: 5,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-5-img.webp',
                    classname: 'who-dey-home',
                    description: 'Bengals @ Titans',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/k0BhD5iHlXs?controls=0&rel=0'),
                },
                {
                    id: 4,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-4-img.webp',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Rams',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/y9Si9OlbdP4?controls=0&rel=0'),
                },
                {
                    id: 3,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-3-img.webp',
                    classname: 'who-dey-home',
                    description: 'Bengals vs. Ravens',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/SVPY5crCq8U?controls=0&rel=0'),
                },
                {
                    id: 2,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-2-img.webp',
                    classname: 'who-dey-home',
                    description: 'Bengals @ Browns',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/EPl54v8Ewto?controls=0&rel=0'),
                },
                {
                    id: 1,
                    type: 'youtube',
                    img: '/images/site/cincinnati-bengals/video-1-img.webp',
                    classname: 'who-dey-home',
                    description: 'Who Dey at Home!',
                    mediaUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/fYHCXQiZrzg?controls=0&rel=0'),
                },
            ];

            function waitForElement(selector) {
                return new Promise((resolve) => {
                const intervalId = setInterval(() => {
                    const element = document.querySelector(selector);
                    if (element) {
                    clearInterval(intervalId);
                    resolve(element);
                    }
                }, 100);
                });
            }

            waitForElement('.carousel-indicators').then((element) => {
                var switchSlides = function() {
                    $scope.slides = [];
                    if(DeviceService.check() == 'phone'){
                        $scope.slides = $scope.whodeySlides;
                    }else{
                        $scope.slides = $scope.whodeySlides;
                    }
                    $scope.$apply();
                };

                $('.video-slides').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    fade: true,
                    asNavFor: '.thumbnail-slides'
                });

                $('.thumbnail-slides').slick({
                    slidesToShow: 5,
                    responsive: [
                        {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3
                        }
                        },
                        {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2
                        }
                        }
                    ],
                    slidesToScroll: 1,
                    asNavFor: '.video-slides',
                    dots: false,
                    centerMode: false,
                    focusOnSelect: true,
                    swipeToSlide: true,
                    prevArrow: '<button type="button" class="slick-prev"><svg class="icon-arrow-left-thin" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16"><path fill="white" d="M6.293 13.707l-5-5c-0.391-0.39-0.391-1.024 0-1.414l5-5c0.391-0.391 1.024-0.391 1.414 0s0.391 1.024 0 1.414l-3.293 3.293h9.586c0.552 0 1 0.448 1 1s-0.448 1-1 1h-9.586l3.293 3.293c0.195 0.195 0.293 0.451 0.293 0.707s-0.098 0.16-0.293 0.707c-0.391 0.391-1.024 0.391-1.414 0z"></path></svg></button>',
                    nextArrow: '<button type="button" class="slick-next"><svg class="icon-arrow-right-thin" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16"><path fill="white" d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.16 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path></svg></button>',
                });
            });
        }
    ]);
}());