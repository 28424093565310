/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeComingSoonCarouselCtrl', ['$scope', 'slickConfigService', 'DeviceService', '$timeout', 'youtubeConfigService', 'youtubeEmbedUtils',
      function($scope, slickConfigService, DeviceService, $timeout, youtubeConfigService, youtubeEmbedUtils) {

        $scope.mainCarousel = [];
        $scope.active = 0;
        var loadedOnce = false;
        
        //resize slick thumbnails based on window size by reinit
        $(window).on('load resize',function(){
          $scope.imagesLoaded = false;

          var thumbCount = 5;
          var stripCount = 2;

          if(DeviceService.check() == 'iPad'){
            thumbCount = 3;
            if(DeviceService.orientation() == 'portrait'){
              thumbCount = 2;
              stripCount = 1;
            }
          }

          if(DeviceService.check() == 'phone'){
            thumbCount = Math.round($(window).width() * 0.01) - 1;
            stripCount = 1;
          }

          $scope.slickStrip = slickConfigService.baseConfig(stripCount);
          slickConfigService.dualSlideshow($scope, thumbCount);
          $timeout(function(){
            if(loadedOnce){
              $scope.imagesLoaded = true;
            }
          },300);
        });


        $scope.communityPromise
          .then(function(){
            
            $scope.images = $scope.community.carousel_images;
            
            if ($scope.community.carousel_video) {
              youtubeConfigService.default($scope, 'null', $scope.community.carousel_video);

              $scope.$on('youtube.player.ready', function($event, player) {
                $scope.playerVars.playlist = $scope.images[0].id; //sets loop
                player.mute();
                player.playVideo();
              });
            }

            $scope.imagesLoaded = true;
            loadedOnce = true;

          });

          $scope.micrositeCarouselAnalyticsConfig = {
            googleAnalytics: {
              pageViewUrl: '/ajax-goals/microsite-carousel-get-updates'
            }
          };
      }
    ])
  ;
}());
