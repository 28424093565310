(function() {
  "use strict";

  angular

    .module('modules.dataServices')

    .service("DataCache", ["$cacheFactory",
      function($cacheFactory) {
        return $cacheFactory("dataCache");
      }
    ]);

})();