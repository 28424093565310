(function() {
  'use strict';
  angular.module('app.imagegallery', []).controller('imageGalleryCtrl', ['$scope', 'dummyDataService', 'slickConfigService', '$filter', '$rootScope', 'AssetListDataService',
    function($scope, dummyDataService, slickConfigService, $filter, $rootScope, AssetListDataService) {

      slickConfigService.modalSlideshow($scope);

      $scope.allGallery = [];
      $scope.gallery = [];
      $scope.byCategory = '';
      $scope.limit = 12;
      $scope.count = 0;

      AssetListDataService.loadAssets('').then(function(response) {
        $scope.allGallery = angular.copy($filter('orderBy')(response.data.assets, 'updated_at', true));
        updateGallery();
      });

      $scope.placeholder = "ALL FILTERS";

      var updateGallery = function() {
      	// filter the gallery by a callback
        var filtered = $filter('filter')($scope.allGallery, function(value, index, array) {
        	// return true if we filter on the tags successfully
        	return ($filter('filter')(value.tags, $scope.byCategory).length > 0);
        });

        $scope.gallery = filtered.slice(0, $scope.limit);
        $scope.count = filtered.length;
      };

      $scope.$watchGroup(['byCategory', 'limit'], updateGallery);

      $scope.expand = function() {
        $scope.limit += 24;
      };

      //filters
      $scope.filters = [
        {
          title: 'Interiors',
          value: 'Interior'
        },
        {
          title: 'Exteriors',
          value: 'Exterior'
        },
        {
          title: 'Kitchens',
          value: 'Kitchen'
        },
        {
          title: "Owner's Suite",
          value: "Owner's Suite"
        },
        {
          title: 'Bedrooms / Kids Rooms',
          value: 'Bedroom'
        },
        {
          title: 'Family Rooms',
          value: 'Family Room'
        },
        {
          title: 'Dining Areas',
          value: 'Dining Area'
        },
        {
          title: 'Bathrooms',
          value: 'Bathroom'
        },
        {
          title: 'Studies/Offices',
          value: 'Office'
        },
        {
          title: 'Basements & Bonus Spaces',
          value: 'Basement'
        }
      ];

      //modal event
      $scope.triggerSlick = false;

      $rootScope.$on('modalClose', function() {
        $scope.triggerSlick = false;
      });

      $scope.applyFilter = function(filter, value) {
        var fl = filter;

        if (value) {
          fl = value;
        }

        $scope.limit = 12;
        $scope.byCategory = fl;
        $scope.placeholder = filter;

        if (filter === '') {
          $scope.placeholder = 'ALL FILTERS';
        }
      };

      //init on modal trigger & slick slide
      $scope.setSlide = function(num) {
        if (!$scope.triggerSlick) {
          $scope.slickLarge.initialSlide = num;
          $scope.slickLarge.method = {};
          $scope.slickLarge.event = {
              init: function (event, slick) {
                $scope.currentSlide = num;
              },
              afterChange: function (event, slick, currentSlide, nextSlide) {
                $scope.currentSlide = slick.currentSlide;
              }
          };
          $scope.triggerSlick = true;
        }
        $scope.filteredCaptions = $filter('filter')($scope.gallery, $scope.byCategory);
        $scope.slideCaption = $scope.filteredCaptions[num].header;
      };

    }]);
}());