(function() {
  'use strict';
  angular.module('app.ourcollections')
    .controller('collectionDetailsCtrl', [
      '$scope', 
      '$window', 
      'DataService', 
      'slickConfigService',
      'DeviceService',
      '$timeout',
      function(
        $scope, 
        $window, 
        DataService,
        slickConfigService,
        DeviceService,
        $timeout) {

        var loadedOnce = false;


        DataService($window.location.href, 'collections')
          .promise
          .then(function(collections) {
            $scope.collectionsLoaded = true;
            $scope.collections = collections;
            loadedOnce = true;
          });

        $scope.collectionPromise = DataService($window.location.href, 'collection')
          .promise
          .then(function(collection) {
            angular.forEach(collection.plans, function(plan) {
              plan.beds = {
                low: plan.bed_low,
                high: plan.bed_high
              };
              plan.baths = {
                low: plan.bath_low,
                high: plan.bath_high
              };
              plan.sqft = {
                low: plan.sqft_low,
                high: plan.sqft_high
              };
            });
            $scope.collection = collection;
          });

        $(window).on('load resize',function(){
          $scope.collectionsLoaded = false;

          var thumbCount = 6;

          if(DeviceService.check() == 'iPad'){
            thumbCount = 4;
            if(DeviceService.orientation() == 'portrait'){
              thumbCount = 3;
            }
          }
          
          if(DeviceService.check() == 'phone'){
            thumbCount = Math.round($(window).width() * 0.01) - 1;
          }

          $timeout(function(){
            slickConfigService.triggerClick($scope, "_self");
            $scope.slickConfig = slickConfigService.baseConfig(thumbCount);

            if(loadedOnce){
              $scope.collectionsLoaded = true;
            }
          },300);
        });



      }
    ]);
}());
