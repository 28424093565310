/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeCarouselCtrl', ['$scope', 'youtubeConfigService', 'youtubeEmbedUtils',
      function($scope, youtubeConfigService, youtubeEmbedUtils) {


        $scope.mainCarousel = [];
        $scope.active = 0;


        $scope.toggleCaption = function(bool){
          $scope.slideCaption = bool;
        }

        $scope.communityPromise
          .then(function(){

            if ($scope.community.carousel_images.length > 0) {
              angular.forEach($scope.community.carousel_images, function(carouselImage) {
                $scope.mainCarousel.push({
                  type: 'image',
                  url: carouselImage.image.large
                });
              });
            }

            if ($scope.community.carousel_video) {
              var id = youtubeEmbedUtils.getIdFromURL($scope.community.carousel_video);

              youtubeConfigService.default($scope, 'null', $scope.community.carousel_video);

              $scope.mainCarousel.unshift({
                type: 'youtube',
                id: id
              });
              $scope.hasVideo = true;

              $scope.$on('youtube.player.ready', function($event, player) {
                $scope.playerVars.playlist = $scope.mainCarousel[0].id; //sets loop
                player.mute();
                player.playVideo();
              });
            }

            //apply facebook meta
            console.dir($scope.mainCarousel);


          });

          $scope.toggleClass = function(target, className) {
            var el = $(target);
            el.toggleClass(className);
          };

          $scope.micrositeCarouselAnalyticsConfig = {
            googleAnalytics: {
              pageViewUrl: '/ajax-goals/microsite-carousel-get-updates'
            }
          };
      }
    ])
  ;
}());
