/*jslint white: true, nomen: true */
/*global $, angular */
(function() {
  'use strict';

  angular.module('app.common')

    .service('ResidencesListDataService', ['$http', '$q', '$filter',
      function($http, $q, $filter) {
        var residencesListDataServiceResultFailMessageText = 'No residences found',
          data = {
            residencesPromise: null,
            residencePromise: null
          },

          getResidences = function() {
            if (data.residencesPromise === null) {
              data.residencesPromise = $http.get('/api/residence', {
                headers: {
                  'Cache-Control': 'no-cache'
                },
                cache: true
              }).then(residencesListDataServiceWash);
            }

            return data.residencesPromise;
          },

          getResidencesByCommunityID = function(communityID) {
            data.residencesPromise = $http.get('/api/residence', {
                headers: {
                  'Cache-Control': 'no-cache'
                },
                params: {
                    community_id: communityID
                },
                cache: true
              }).then(residencesListDataServiceWash);

            return data.residencesPromise;
          },

          getResidence = function(communityId) {
            if (data.residencePromise === null) {
              data.residencePromise = $http.get('/api/residence/' + residenceId, {
                cache: true
              }).then(residencesListDataServiceWash);
            }

            return data.residencePromise;
          },

          residencesListDataServiceWash = function(result) {
            var successResult = {},
              failResult = {
                status: 'failure',
                residences: [],
                errors: residencesListDataServiceResultFailMessageText
              };

            if (result === undefined || result.data === undefined || result.data.status === undefined) {
              return failResult;
            }

            if (result.data.status === 'failure') {
              return failResult;
            }

            if (result.data.residences) {
              result.data.residences = JSON.parse(result.data.residences);
              angular.forEach(result.data.residences, function(residence) {
                formatResidence(residence);
              });
              successResult.residences = result.data.residences;
            } else if (result.data.residence) {
              successResult.residence = formatResidence(result.data.residence);
            }

            successResult.status = result.data.status;
            successResult.errors = 'none';

            return successResult;
          },

          formatResidence = function(residence) {
            var residenceThubnails,
              residenceItems;

            residenceThubnails = getResidenceThumbnails(residence);
            residence.image_thumb = residenceThubnails.imageThumb;
            residence.image_full = residenceThubnails.imageFull;

            residenceItems = getResidenceItems(residence);
            residence.beds = residenceItems.beds;
            residence.baths = residenceItems.baths;
            residence.sqft = residenceItems.sqft;

            return residence;
          },

          getResidenceThumbnails = function(residence) {
            var tempThumb,
              tempFull,
              result = {
                imageThumb: "/images/site/full-interest-form/plan-1.jpg",
                imageFull: "/images/site/full-interest-form/plan-1.jpg"
              };

            if (typeof residence.elevationImages !== 'undefined' && residence.elevationImages.length > 0) {
              tempThumb = residence.elevationImages[0].img.small;
              tempThumb = tempThumb.replace("(", "%28");
              tempThumb = tempThumb.replace(")", "%29");
              result.imageThumb = tempThumb;

              tempFull = residence.elevationImages[0].img.large;
              tempFull = tempFull.replace("(", "%28");
              tempFull = tempFull.replace(")", "%29");
              result.imageFull = tempFull;
            }

            return result;
          },

          getResidenceItems = function(residence) {
            var tempBathsLow,
              tempBathsHigh,
              tempBedsLow,
              tempBedsHigh,
              tempSqftLow,
              tempSqftHigh,
              result = {
                baths: '',
                beds: ''
              };

            tempBathsLow = parseFloat(residence.bath_low);
            if (tempBathsLow % 1 === 0) {
              tempBathsLow = Math.floor(tempBathsLow);
            }

            tempBathsHigh = parseFloat(residence.bath_high);
            if (tempBathsHigh % 1 === 0) {
              tempBathsHigh = Math.floor(tempBathsHigh);
            }

            tempBedsLow = parseFloat(residence.bed_low);
            if (tempBedsLow % 1 === 0) {
              tempBedsLow = Math.floor(tempBedsLow);
            }

            tempBedsHigh = parseFloat(residence.bed_high);
            if (tempBedsHigh % 1 === 0) {
              tempBedsHigh = Math.floor(tempBedsHigh);
            }

            tempSqftLow = parseInt(residence.sqft_low);
            tempSqftHigh = parseInt(residence.sqft_high);

            if (tempBathsLow === tempBathsHigh) {
              result.baths = tempBathsLow;
            } else {
              result.baths = tempBathsLow + ' - ' + tempBathsHigh;
            }

            if (tempBedsLow === tempBedsHigh) {
              result.beds = tempBedsLow;
            } else {
              result.beds = tempBedsLow + ' - ' + tempBedsHigh;
            }

            if (tempSqftLow === tempSqftHigh || tempSqftHigh === 0) {
              result.sqft = $filter('number')(tempSqftLow);
            } else {
              result.sqft = $filter('number')(tempSqftLow) + ' - ' + $filter('number')(tempSqftHigh);
            }

            return result;
          };

          return {
            getResidences: getResidences,
            getResidence: getResidence,
            getResidencesByCommunityID: getResidencesByCommunityID
          };

      }]);
}());
