/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('maxWidth', ['device', function(device) {
      return {
        restrict: 'A',
        link: function(scope, elem, attrs) {

          $(window).on('load resize', function(){
            elem.width($(window).width() * 0.875);
          })

        }
      }
    }]);
}());