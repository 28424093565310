/* jslint white: true, nomen: true */
(function commonServicesModuleInit() {
  /* global googleApiKey */
  'use strict';

  /*
    In this module, we can store configuration and other services
    that will be shared by SmartEdit and Milestone.
  */

  angular
    .module("modules.googleMaps")

    .config(['uiGmapGoogleMapApiProvider',
      function(uiGmapGoogleMapApiProvider) {
        uiGmapGoogleMapApiProvider.configure({
          key: googleApiKey,
          v: '3',
          libraries: 'places'
        });
      }
    ]);

}());