/* globals angular */

(function () {
  'use strict';

  angular
    .module('app.podcast')
    .controller('podcastCtrl', ['$scope', '$filter',
      function ($scope, $filter) {

        $scope.episodes = [{
          thumbnail: '',
          title: 'Episode 1: Just Breathe, It\'s Gonna Be OK!',
          date: 'DEC 12, 2019',
          blurb: 'Buying a house can seem overwhelming, and you might not know where to begin. Alisha Woodeshick will show you where to start!'
        }, {
          thumbnail: '',
          title: 'Episode 2: New > Used. Choose Inspiration over Perspiration',
          date: 'DEC 19, 2019',
          blurb: 'Decisions, decisions! Steve Whaley shares why choosing a new home will leave you feeling inspired and less stressed.'
        }, {
          thumbnail: '',
          title: 'Episode 3: Our Design Experts Make It Personal',
          date: 'DEC 19, 2019',
          blurb: 'One of the best parts of building a new home is getting to pick out all the stuff! Kristie Patterson guides you through working with the Fischer Homes design experts to truly make your new home your own.'
        }, {
          thumbnail: '',
          title: 'Episode 4: PMI, LTV, OMG! All the Steps to Stress-Free Financing',
          date: 'DEC 19, 2019',
          blurb: 'Elizabeth Breitenstein uncovers the myths about financing a new home, including how much you can afford, how much you have to put down, credit scores, and what to look for in a lender.'
        }, {
          thumbnail: '',
          title: 'Episode 5: The 3 Questions You HAVE to Ask Before Building a Home',
          date: 'DEC 19, 2019',
          blurb: 'Is it worth it? Which builder should I choose? Tom Hansen from Fischer Homes answers these questions you may be asking yourself and more.'
        }, {
          thumbnail: '',
          title: 'Episode 6: A Five-Level What?',
          date: 'DEC 19, 2019',
          blurb: 'Go behind the design with Dan O’Connell, and find out how and why floorplans are created—whether it is a ranch, two-story or a 5-level home.'
        }, {
          thumbnail: '',
          title: 'Episode 7: Where and what to build... Take your pick!',
          date: 'DEC 19, 2019',
          blurb: 'Not sure where you want to build? Emily Murray explains location is everything—not only the community you build in but the homesite itself.'
        }, {
          thumbnail: '',
          title: 'Episode 8: Put a Red Bow on It',
          date: 'DEC 19, 2019',
          blurb: 'You\'ve heard of all the reasons why you should build a new home, but what about all of the reasons why you should choose Fischer Homes? Carrie Rogiers walks you through the decision. If you are interested in speaking with a New Home Specialist, contact one at fischerhomes.com.'
        }];

      }
    ]);
}());
