(function(){
  'use strict';

  angular.module('app.common')

  /**
   *
   * Provides various viewport detect helpers
   *
   */
  .service('DeviceService', [function(){

    var deviceService = {};

    /**
     *
     * Returns device type
     *
     */

    deviceService.check = function() {
      var type = "desktop";
      /*detect iPad only*/
      if ($(window).width() > 767 && $(window).width() < 1025) {
        type = "iPad";
      }
      /*detect phone only*/
      if ($(window).width() < 768) {
        type = "phone";
      }
      return type;
    };

    /**
     *
     * Returns device orientation state
     *
     */

    deviceService.orientation = function() {
      if ($(window).width() < $(window).height()) {
        return "portrait";
      }
      return "landscape";
    };

    /**
     *
     * Checks for touchscreen device
     *
     */

    deviceService.touch = function() {
      var is_touch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      return is_touch;
    };
    
    return deviceService;
  }]);
})();