/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('app.common')
  .filter('escape', function() {
    return function(string) {
      if (!string) {
        return;
      }

      return '"'+string.replace(" ", "%20")+'"';
    }
  });
}());
