/*jslint white: true, nomen: true */
/*global angular, $, navigator */
(function() {
  'use strict';

  angular.module('app.common')
    .controller('menuCtrl', [
      '$scope',
      'DeviceService',
      'CommunitiesListDataService',
      'FormService',
      '$window',
      '$timeout',
      'regionStorageFactory',
      '$rootScope',
      function(
        $scope,
        DeviceService,
        CommunitiesListDataService,
        FormService,
        $window,
        $timeout,
        regionStorageFactory,
        $rootScope,
      ) {

        $scope.regionName = regionStorageFactory.getRegionName();

        if(!$scope.regionName){
          $scope.regionName = 'Select A Region';
        }

        $scope.chooseRegion = 'CHOOSE A REGION';
        $scope.chooseRegionSeo = null;
        $scope.regions = {};

        CommunitiesListDataService.getRegions().then(function(result) {
          $scope.regions = result.regions;
        });

        $scope.schoolDistrictDisabled = true;

        $scope.loginPanel = 'login';

        $scope.dropdownWide = false;

        $scope.handleClose = FormService.handleClose;

        //Region
        if (regionStorageFactory.get()) {
          $scope.chooseRegion = regionStorageFactory.get().name;
          $scope.chooseRegionSeo = regionStorageFactory.get().seo_name;
        }
        $rootScope.$on('selectedRegion', function(event, region) {
          // console.log("caught selectedRegion", event, region);
          $scope.chooseRegion = region.name;
          $scope.chooseRegionSeo = region.seo_name;
          $scope.regionName = region.name;

        });

        $scope.regionSelect = function(name, seo_name) {
          // console.log("fired regionSelect", name, seo_name);
          $scope.chooseRegion = name;
          $scope.chooseRegionSeo = seo_name;
          regionStorageFactory.set(name, seo_name);
        };

        $scope.parseRegionAlways = function(template, section) {
          // if (template === null || regionStorageFactory.get()) {
          //   var region = regionStorageFactory.get();
          //   $window.location.href = region.url + section;
          // } else {
            $scope.$broadcast('triggerModal', {
              path: template,
              section: section
            });
          // }
        };

        $scope.parseRegion = function(template, section) {
          if (template === null || regionStorageFactory.get()) {
            var region = regionStorageFactory.get();
            $window.location.href = region.url + section;
          } else {
            $scope.$broadcast('triggerModal', {
              path: template,
              section: section
            });
          }
        };

        $scope.parseRegionMobile = function(template, section) {
          $scope.$broadcast('triggerModal', {
            path: template,
            section: section
          });
        };

        $scope.parseRegionDropdown = function(){
          if (regionStorageFactory.get()) {
            var region = regionStorageFactory.get();
            $window.location.href = region.url + 'communities';
          }
        };

        //Menu position
        $scope.toggleDropdownWide = function(toggle){
          //run on desktop and phone
          if(!DeviceService.touch() || DeviceService.check() == 'phone'){
            $scope.dropdownWide = toggle;
          }
        }

        $scope.tabletToggle = function(){
          //run on touch devices except phone
          if(DeviceService.touch() && DeviceService.check() != 'phone'){
            $scope.dropdownWide = !$scope.dropdownWide;
          }
        };

        //IE detect
        if (navigator.userAgent.match(/Trident\/7\./)) {
          $('html').addClass('is-ie');
        }

        if (navigator.userAgent.toLowerCase().indexOf("ios") > -1) {
          $('html').addClass('is-ios');
        }

        //Touch detect
        $(window).on('load resize', function(){
          if (DeviceService.touch()){
            $('body').bind('click',function(e){
              $rootScope.$broadcast('mobile-touch', e);
            })
          }
        })

        $scope.emit = function(string){
          $rootScope.$broadcast(string);
        };

        $scope.linkTo = function(url) {
          $scope.dropdownWide = false;
          if (!DeviceService.touch()) {
            $window.open(url, '_self');
          }
        };

        $scope.analyticsConfig = {
          googleAnalytics: {
            pageViewUrl: '/ajax-goals/qsu-header-nav'
          }
        };

        $scope.followUs = document.getElementById('follow-us-cta-text');

        this.$window = $window;
        this.$timeout = $timeout;
        this.rootElem = null;
        this.announcementsElem = null;
        this.navbarElem = null;
        this.navbarElem2 = null;
        this.navbarElem3 = null;
        this.navbarElem4 = null;
        this.$postLink = function() {
          this.rootElem = $window.document.documentElement;
          this.announcementsElem = $window.document.querySelector('.announcements');
          this.navbarElem = $window.document.querySelector('#mainHeader .navbar-header');
          this.navbarElem2 = $window.document.querySelector('#main-nav .navbar-header');
          if (this.announcementsElem) {
            $window.document.body.classList.add('has-announcements');
            $window.addEventListener('resize', this.handleResize, false);
            this.handleResize();
          }
        };

        this.$onDestroy = function() {
          if (this.announcementsElem) {
            this.$window.removeEventListener('resize', this.handleResize);
          }
        };

        this.handleResize = function() {
          // this.navbarElem = $window.document.querySelector('#mainHeader .navbar-header');
          this.navbarElem2 = $window.document.querySelector('#main-nav .navbar-header');
          this.filterbarElem = $window.document.querySelector('#reg-filter-bar');
          const announcmentH = this.announcementsElem ? Math.floor(this.announcementsElem.getBoundingClientRect().height) : 0;
          // const navbarElemH = this.navbarElem ? this.navbarElem.getBoundingClientRect().height : 0;
          const navbarElem2H = this.navbarElem2 ? Math.floor(this.navbarElem2.getBoundingClientRect().height) : 0;
          const filterbarElemH = this.filterbarElem ? Math.floor(this.filterbarElem.getBoundingClientRect().height) : 0;
          const additionalMargin = -1; // Account for odd 1px gap between navbar and announcement
          // const total = announcmentH + navbarElemH  + navbarElem2H + additionalMargin;
          const total = announcmentH + navbarElem2H + additionalMargin;

          this.rootElem.style.setProperty('--headerHeight', total + 'px');
          this.rootElem.style.setProperty('--navbarHeight', navbarElem2H + 'px');
          this.rootElem.style.setProperty('--announcementHeight', announcmentH + 'px');
          this.rootElem.style.setProperty('--filterbarHeight', filterbarElemH + 'px');
        };

        this.handleResize = this.handleResize.bind(this);

        this.$timeout(this.handleResize, 0);
      }]);
}());
