/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentMoveDateSelect', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-move-date-select',
        replace: true,
        scope: {
          defaultPlaceholder: '@',
          model: '=',
          formObj: '=',
          formScope: '=',
          selectName: '=?',
          isRequired: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          var self = this;

          if (! self.defaultPlaceholder) {
            self.defaultPlaceholder = 'Desired Move Date';
          }
          if (! self.selectName) {
            self.selectName = 'move_date';
          }

          if (typeof self.isRequired === 'undefined') {
            self.isRequired = true;
          }

          self.methods = ['1-3 months', '3-6 months', '6+ months'];

        }]
      };

    }]);
}());