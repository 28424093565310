/*jslint white: true, nomen: true */
/*global angular */

(function() {
  'use strict';

  angular.module("app.common")
    .directive("miniMap", function() {
      return {
        restrict: 'E',
        transclude: true,
        scope: {
          'latitude': '=',
          'longitude': '=',
          'googleMyBusinessUrl': '@?'
        },
        templateUrl: '/directive/mini-map'
      };
    });
}());
