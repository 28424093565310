/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function() {
    'use strict';
  
    angular.module('app.microsite')
      .controller('MicrositeBlogCtrl', ['$scope', '$http', 'slickConfigService',
        function($scope, $http, slickConfigService) {
            $scope.$on('user-selected-community', function(event, community) {
                $scope.community = community;
                $scope.hubSpotData = [];
                $scope.blogs = [];
                $scope.hubSpotDataPromise = null;
                
                $scope.getHubSpotData = function(topicName) {
                    var apiUrl = '/api/hubspot-cms-data';
                    if (topicName) {
                        apiUrl += '?name=' + encodeURIComponent(topicName);
                    }
                    $scope.hubSpotDataPromise = $http.get(apiUrl).then(function(response) {
                        $scope.blogs = response.data;
                        function waitForElement(selector) {
                            return new Promise((resolve, reject) => {
                              const intervalId = setInterval(() => {
                                const element = document.querySelector(selector);
                                if (element) {
                                  clearInterval(intervalId);
                                  resolve(element);
                                }
                              }, 100);
                          
                              setTimeout(() => {
                                clearInterval(intervalId);
                                reject(new Error(`Element with selector "${selector}" did not appear in the DOM.`));
                              }, 30000); 
                            });
                          }
                          
                          waitForElement('#blog-container').then((element) => {
                            var carousel = $(element).slick({
                              slidesToShow: 3,
                              slidesToScroll: 1,
                              infinite: true,
                              arrows: true,
                              prevArrow: '<button type="button" class="slick-prev"><</button>',
                              nextArrow: '<button type="button" class="slick-next">></button>'
                            });
              
                          }).catch(error => {
                            console.error(error);
                          });
                    }, function(error) {
                        console.error('Error fetching data from HubSpot:', error);
                    });
                };
                const regionLookup = community.region.name.split(',')[0];
                $scope.getHubSpotData(regionLookup);
                

            });
        }]);
  }());
  