(function() {
  'use strict';

  angular.module('app.forms')
    .directive('fhFormSidebarStayUpdated', [function() {
      return {
        restrict: "E",
        scope: {
          buttonText: '=',
          regionDropdown: '=',
          messageLeft: '=?',
          toggleTarget: '=?',
          analyticsConfig: '=',
          formType: '='
        },
        bindToController: true,
        controllerAs: 'vm',
        templateUrl: 'forms/fh-form-sidebar-stay-updated',
        link: function() {},
        controller: [
          'FormService',
          'DeviceService',
          'regionStorageFactory',
          '$scope',
          '$timeout',
          'AnalyticsService',
          function(
            FormService,
            DeviceService,
            regionStorageFactory,
            $scope,
            $timeout,
            AnalyticsService
          ) {


            var self = this;
            var prefillMode = false;
            var formType = self.formType === 'default' ? 'getFastAnswers' : self.formType;
            var destination = self.formType === 'default' ? '/forms/quick-signup' : '/forms/meet-and-greet';
            var prefillData = FormService.prefillForm(prefillMode, formType);

            self.formData    = prefillData.formData;
            self.formResult  = prefillData.formResult;

            $scope.$on('user-selected-floorplan', function(event, floorplan) {
              self.formData.selected_plans = [floorplan.id];
            });

            $scope.$on('user-selected-model-home', function(event, modelHome) {
              self.formData.selected_model_homes = [modelHome.id];
            });

            $scope.$on('user-selected-home', function(event, home) {
              self.formData.selected_homes = [home.id];
            });

            $scope.$on('user-selected-community', function(event, community) {
              self.formData.selected_communities = [community.id];
            });

            if (self.regionDropdown) {
              self.regions = FormService.getRegionsForSelect();
              self.formData.type = 'energywise';
            }

            self.selectRegion = function(id) {
              self.formData.selected_regions = [id];
            };

            self.checkFormValidation = function(form) {
              self.triedSubmit = true;

              if (! form.$valid) {
                return;
              }

              processGetInTouchForm();
            };

            function processGetInTouchForm() {
              self.formResult.showForm = false;
              self.formResult.showLoader = true;

              prepareFormData();

              FormService.submit(destination, self.formData)
                .then(function(result) {
                  self.formResult = result;

                  AnalyticsService.logTrackingClick(self.analyticsConfig);

                  toggleOpen();
                });
            }

            function prepareFormData() {
              var region = regionStorageFactory.get();

              if (! self.regionID && region) {
                self.formData.selected_regions = [region.id];
                self.regionID = region.id;
              }

            }

            function toggleOpen() {
              if (! self.toggleTarget) {
                return;
              }

              $timeout(function(){
                $(self.toggleTarget).toggleClass('open');
              }, 2000);
            }

          }]
      };
    }]);
})();