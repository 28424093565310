(function() {

  'use strict';
  /* global $, angular */

  angular
    .module('app.common')

    .filter('trustAsResourceUrl', ['$sce', function($sce) {
      return function(val) {
        return $sce.trustAsResourceUrl(val);
      };
    }])
  ;

})();