/* jslint white: true, nomen: true */
/* global angular */
(function () {
  'use strict';

  angular.module('app.common')
    .controller('BannerHaveAQuestionCtrl', ['$scope', '$localStorage',
      function ($scope, $localStorage) {
        // var storageDriver = $localStorage;

        $scope.rand = function (high) {
          return Math.floor(Math.random() * high);
        };

        $scope.chooseImage = function () {
          var images = [
            'sales-agents-group-2.jpg'
          ];

          /*
          if (storageDriver.bannerHaveAQuestionImage) {
            images.splice(images.indexOf(storageDriver.bannerHaveAQuestionImage), 1);
          }
          */

          var r = Math.floor(Math.random() * images.length);
          console.log(r);
          var selectedImage = images[r];

          /*
          storageDriver.bannerHaveAQuestionImage = selectedImage;
          */

          return selectedImage;
        };

        $scope.roll = function () {
            console.log("Rollin");
          // $scope.class = $scope.rand(2);
          // deactivate a/b test on button by setting the class to 1 (button combined)
          $scope.class = 1;
          $scope.image = $scope.chooseImage();
        };

        $scope.roll();
      }
    ]);
}());
