(function() {
  "use strict";

  angular
    .module("app.common")

    .directive('communityImage', [ '$timeout', function($timeout) {

      return {
        restrict: 'A',
        scope: {
          community: '=',
          size: '@'
        },
        link: function(scope, element, attr) {

          if (scope.size === undefined || !scope.size) {
            scope.size = 'medium';
          }

          var validImages = [
            "community.coverImage." + scope.size,
            "community.carousel_images[0].image." + scope.size,
            "community.logoImage." + scope.size,
            "community.elevations[0].img." + scope.size,
            "community.assets[0].image." + scope.size,
            "community.plan.assets[0].image." + scope.size,
            "community.plan.elevations[0].img." + scope.size,
            "community.residence.elevationGallery[0].img." + scope.size,
            "community.residence.elevations[0].img." + scope.size,
            "community.residences[0].elevationGallery[0].img." + scope.size,
            "community.residences[0].elevations[0].img." + scope.size,
            "community.carouselImages[0].image." + scope.size,
            "'/images/not_found.png'"
          ];

          var nextImage = function(){
            var nextSrc = validImages.shift();

            if (!nextSrc) {
              return false;
            }

            element.attr('community-image-debug', nextSrc);

            // Don't forget to eval the src, since it can use scope attributes
            nextSrc = scope.$eval(nextSrc);

            if (nextSrc) {
              element[0].src = nextSrc;
              element[0].style.backgroundImage = 'url("' + nextSrc + '")';
            }
            else {
              nextImage();
            }
          };

          // element.on('error', nextImage);

          scope.$on('$destroy', function() {
            //element.off('error', nextImage);
          });

          scope.$watch('community', function(is, was){
            if (is) {
              $timeout(function(){
                nextImage();
              });
            }
          });

        }
      };

    }]);
})();
