(function(){
	'use strict';

	angular.module('app.common')
	.directive('imageResizer', ['$window', '$timeout', function($window, $timeout){
		return {
			restrict: 'A',
			scope: {
				img : '@imageResizer'
			},
			link: function(scope, elem) {

				var windowResizeTimeoutPromise;
				angular.element($window).bind('resize', function(){
					  $timeout.cancel(windowResizeTimeoutPromise);
				    windowResizeTimeoutPromise = $timeout(parseImage, 500);
				});

				scope.$watch('img', function(is,was){
					if (is === was) {
						return;
					}

					parseImage();
				});

				$timeout(parseImage, 300);

				function parseImage(){
					//get and explode rawUrl...must call on large image
					var rawUrl = scope.img.split('large');
					//parse imageUrl with correct size
					var imageUrl = rawUrl[0] + checkSize() + rawUrl[1];
					//set new imageUrl
					$(elem).css('background-image', 'url('+imageUrl+')');
				}

				function checkSize(){
					var elemWidth = $(elem).width();
					var size = 'hd';
					if(elemWidth < 1200){size = 'large';}
					if(elemWidth < 540){size = 'medium';}
					if(elemWidth < 200){size = 'small';}

					return size;
				}

			}
		};
	}]);
}());