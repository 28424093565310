/*jslint white: true, nomen: true */
/*global angular, $, _ */
(function() {
  'use strict';

  angular.module('app')

  .service('uiGmapPromises', ['uiGmapIsReady', 'uiGmapGoogleMapApi', function(uiGmapIsReady, uiGmapGoogleMapApi) {
    var GoogleMapsApiReadyPromise = uiGmapGoogleMapApi
      .then(function(GoogleMaps) {
        GoogleMaps.controlStyle = 'azteca';
        return GoogleMaps;
      }),
      GoogleMapsUIReadyPromise = uiGmapIsReady.promise();

    return {
      // Resolves after *both* the Google Maps API is ready AND any maps in the template are loaded.
      // Returns the GoogleMaps API object for creating new GoogleMaps services like LatLng or places.
      GoogleMapsIsAllReady: function() {
        return GoogleMapsApiReadyPromise
          .then(function(GoogleMaps) {
            return GoogleMapsUIReadyPromise
              .then(function(maps) {
                return maps[0].map;
              });
          });

      },
      GoogleMapsApiReady: function() {
        return GoogleMapsApiReadyPromise;
      },

      GoogleMapsUIReady: function() {
        return GoogleMapsUIReadyPromise;
      }

    };
  }]);
}());
