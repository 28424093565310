/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('mcustomScrollbar', ['$timeout', function($timeout) {
      return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
          $(window).on('load', function() {
            $(elem).mCustomScrollbar({
              setHeight: 700,
              scrollbarPosition: 'outside',
              live: true,
              scrollInertia: false
            });
          })
        }
      };
    }]);
}());
