(function () {
  'use strict';

  angular.module('app.forms')
    .directive('fhFormRegisterToday', [function () {
      return {
        restrict: 'E',
        scope: {
          regionId: '=',
          buttonText: '=',
          regionDropdown: '=',
          messageLeft: '=?',
          toggleTarget: '=?',
          analyticsConfig: '=',
          formType: '='
        },
        bindToController: true,
        controllerAs: 'vm',
        templateUrl: 'forms/fh-form-register-today',
        link: function () {},
        controller: [
          'FormService',
          'DeviceService',
          'DataService',
          'regionStorageFactory',
          '$scope',
          '$timeout',
          '$window',
          'AnalyticsService',
          function (
            FormService,
            DeviceService,
            DataService,
            regionStorageFactory,
            $scope,
            $timeout,
            $window,
            AnalyticsService
          ) {
            var self = this;
            var prefillMode = false;
            var formType = self.formType === 'default' ? 'salesCenter' : self.formType;
            var destination = self.formType === 'default' ? '/forms/quick-signup' : '/forms/meet-and-greet';
            var prefillData = FormService.prefillForm(prefillMode, formType);

            self.formData = prefillData.formData;
            self.formResult = prefillData.formResult;

            // if (self.regionDropdown) {
            self.regions = FormService.getRegionsForSelect();
            // }

            self.analyticsConfig = {
              googleAnalytics: {
                pageViewUrl: '/ajax-goals/qsu-sales-center'
              }
            };

            $timeout(function () {
              self.regionID = self.regionId;
            }, 375);

            self.selectRegion = function (id) {
              self.formData.selected_regions = [id];
            };

            self.selectCommunity = function (id) {
              self.formData.selected_communities = [id];
            };

            self.checkFormValidation = function (form) {
              self.triedSubmit = true;

              if (!form.$valid) {
                return;
              }

              processGetInTouchForm();
            };

            function processGetInTouchForm () {
              self.formResult.showForm = false;
              self.formResult.showLoader = true;

              prepareFormData();

              FormService.submit(destination, self.formData)
                .then(function (result) {
                  self.formResult = result;

                  AnalyticsService.logTrackingClick(self.analyticsConfig);

                  $timeout(function () {
                    $window.location.reload();
                  }, 3000);
                });
            }

            function prepareFormData () {
              // var region = regionStorageFactory.get();

              // if (!self.regionID && region) {
                self.formData.selected_regions = [self.regionId];
                // self.regionID = region.id;
              // }
            }
          }]
      };
    }]);
})();
