$(function(){
	'Use Strict';
	angular.module('app.joinourteam').controller('joinOurTeamCtrl',['$scope', '$location', 'dummyDataService','youtubeConfigService',
		function($scope, $location, dummyDataService, youtubeConfigService){

		youtubeConfigService.default($scope,'null','C49IB3Lpw5w');

        $scope.slides = ['/images/site/join-our-team/hero-bkg.jpg'];
        $scope.showPlayer = false;
		$scope.activeTabIndex = 0;

		$scope.showVideo = function(){
			$scope.video = 'C49IB3Lpw5w';
			$scope.showPlayer = true;
		}

		$scope.setLocation = function(e, page){
			e.preventDefault();
			$scope.toggleForm();
			$location.path('/about/careers/'+page).replace();
		}

		$scope.setActiveTab = function(num){
			$scope.activeTabIndex = num;
			$scope.toggleForm();
		}

		$scope.toggleForm = function(){
			$scope.inlineFormShow = true;
			if($scope.activeTabIndex == 0 || $scope.activeTabIndex == 3){
				$scope.inlineFormShow = false;
			}
		}

    $scope.meetAndGreetAnalyticsConfig = {
      googleAnalytics: {
        pageViewUrl: '/ajax-goals/join-our-team-meet-and-greet'
      }
    };

    $scope.joinOurTeamFooterAnalyticsConfig = {
      googleAnalytics: {
        pageViewUrl: '/ajax-goals/qsu-join-our-team-footer'
      }
    };

	}])
}());