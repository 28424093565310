/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('app.common')
    .directive('dropPins', ['$timeout',
      function($timeout) {
        return {
          restrict: 'A',
          link: function(scope, elem, attrs) {

            $timeout(function() {

              if (attrs.pinOnly) {
                elem.parent().find('.pin').hide().velocity('stop').velocity('transition.shrinkIn', {
                  stagger: 300,
                  delay: 300
                });

                //trigger popover
                elem.on('mouseenter mouseleave', function() {
                  //cal popup position
                  if (elem.offset().top < elem.parent().height() * 0.4) {
                    elem.addClass('bottom');
                  }
                  elem.toggleClass('open');
                });

              } else {
                elem.find('li').on('click', function() {
                  $(this).addClass('active').siblings().removeClass('active');
                  $('.pin-wrapper:eq(' + $(this).data('pin') + ')').addClass('active').siblings().removeClass('active');
                  $('.pin-wrapper.active .pin').hide().velocity('stop').velocity('transition.shrinkIn', {
                    stagger: 300
                  });

                })
              }

            }, 0);

          }
        };
      }
    ]);
}());
