/*jslint white: true, nomen: true */
/*global angular, $, ScrollMagic */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('smScrollspyHomeshow', ['$timeout', '$rootScope', '$location', 'DeviceService',
      function($timeout, $rootScope, $location, DeviceService) {



        return {
          restrict: 'A',
          link: function(scope, elem, attrs) {

            //get url
            var basePath = $location.path();

            var baseUrl = basePath.split('/')
                   .slice(0,3)
                   .toString()
                   .replace(/,/g,'/');

            var updateUrl = function(el, section) {
              $location.update_path( baseUrl + section );

              el.addClass('active')
                .siblings()
                .removeClass('active');
            };

            //force self height for affixing
            $(window).on('load resize',function(){
              elem.height(elem.height());
            });

            var controller = new ScrollMagic.Controller();

            function scrollto(elemTgrt) {
              var offset = 0;
              if (attrs.dskOffset) {
                offset = attrs.dskOffset;
              }
              if (DeviceService.check() == 'phone' && attrs.mblOffset) {
                offset = attrs.mblOffset;
              }

              $('html, body').animate({
                scrollTop: $('#' + elemTgrt).offset().top - offset
              }, 1000);
            }

            $timeout(function() {
              elem.find('li a').add('#subnav').each(function() {
                //combinelistener for all elements including parent

                var smOffset = -200;
                var trigger = $(this).attr('href');

                if (DeviceService.check() == 'iPad'  && DeviceService.orientation() == 'portrait' ){
                  smOffset = -80;
                }

                if (DeviceService.check() == 'phone'){
                  smOffset = -60;
                }

                if ($(this).attr('id') == "subnav") {
                  trigger = "#" + attrs.id;
                }

                var scene = new ScrollMagic.Scene({
                    triggerElement: trigger,
                    triggerHook: "onLeave",
                    offset: smOffset
                  })
                  .on("enter leave", function(e) {

                    if (trigger == "#subnav") {

                      if (e.type == 'enter') {
                        $('header').addClass('slideUp isloaded');
                        elem.addClass('affixed');
                      } else {
                        $('header').removeClass('slideUp');
                        elem.removeClass('affixed');
                      }

                    } else {

                      if (e.type == "enter") {
                        var el = elem.find('a[href="' + trigger + '"]').closest('li');
                        var section = trigger.replace('#','/');
                        updateUrl( el, section );
                      } else {
                        var el = elem.find('li.active').prev();
                        if (el.length > 0) {
                          var section = $(el).find('a').attr('href').replace('#','/');
                          updateUrl( el, section );
                        }
                      }



                    }
                  })
                  .addTo(controller);
              })
            }, 100).then(function() {
              //scroll to on load
              // if (url[0]) {
              //   $timeout(function() {
              //     scrollto(url[0]);
              //   }, 1500)
              // }
            })

          }
        }
      }
    ]);
}());
