(function(){
  "use strict";

  angular.module("app.common")
  .directive("registerTodayForm", function(){
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        sub: '@',
        regionId: '=',
        button: '@',
        regionDropdown: '@',
        classname: '@',
        analyticsConfig: '=',
        formType: '@'
      },
      templateUrl: '/directive/register-today-form'
    };
  });
}());