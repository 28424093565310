/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentAddress', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-address',
        replace: true,
        scope: {
          formObj: "=",
          formScope: "="
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {}]
      };

    }]);
}());