(function() {

  'use strict';

  angular
    .module("modules.staticMap")
    .directive('popoverPosition', ['$timeout',
      function($timeout) {
        return {
          link: function(scope, elem, attrs) {

            // defer to the next $digest cycle
            $timeout(function() {

              var offset = $(elem).offset().left;
              offset = parseInt(offset, 10);

              if (offset < 0) {

                var left = $(elem).css("left");
                left = parseInt(left, 10);

                var newLeft = left + Math.abs(offset);

                $(elem).css({
                  left: newLeft + 'px',
                  right: 'auto'
                });

              }

            });

          }
        };
      }
    ]);

})();