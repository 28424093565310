(function () {
  'use strict';

  angular.module('app.forms')
    .directive('fhFormScheduleMyTour', [function () {
      return {
        restrict: 'E',
        scope: {
          buttonText: '=',
          regionDropdown: '=',
          messageLeft: '=?',
          toggleTarget: '=?',
          analyticsConfig: '=',
          formType: '=',
          formSourceType: '=',
          deviceType: '=?',
          tourDates: '=?',
          tourTimes: '=?',
          modelLocation: '=?',
        },
        bindToController: true,
        controllerAs: 'vm',
        templateUrl: 'forms/fh-form-schedule-my-tour',
        link: function () {},
        controller: [
          'FormService',
          'DeviceService',
          'regionStorageFactory',
          '$scope',
          '$timeout',
          '$http',
          'AnalyticsService',
          function (
            FormService,
            DeviceService,
            regionStorageFactory,
            $scope,
            $timeout,
            $http,
            AnalyticsService
          ) {
            var self = this;
            var prefillMode = false;
            var formType = (!self.formType) ? 'getFastAnswers' : self.formType;
            var formSourceType = (!self.formSourceType) ? 'getFastAnswers' : self.formSourceType;
            var destination = (!self.formType) ? '/forms/quick-signup' : '/forms/meet-and-greet';
            var prefillData = FormService.prefillForm(prefillMode, formType);

            self.formData = prefillData.formData;
            self.formResult = prefillData.formResult;
            self.formData._source_type = formSourceType;

            self.deviceType = DeviceService.check();

            $scope.$on('user-selected-floorplan', function (event, floorplan) {
              self.formData.selected_plans = [floorplan.id];
            });

            $scope.$on('user-selected-model-home', function (event, modelHome) {
              self.formData.selected_model_homes = [modelHome.id];
            });

            $scope.$on('user-selected-home', function (event, home) {
              self.formData.selected_homes = [home.id];
            });

            $scope.$on('user-selected-community', function (event, community) {
              self.formData.selected_communities = [community.id];
            });

            self.second_step = false;

            self.edit = function () {
              self.second_step = false;
            };

            self.proceed = function (form) {
              if (!self.formData.tour_date || !self.formData.tour_time) {
                self.triedSubmit = true;
                return;
              }

              self.triedSubmit = false;
              self.second_step = true;
            };

            if (self.regionDropdown) {
              self.regions = FormService.getRegionsForSelect();
              self.formData.type = 'energywise';
            }

            self.selectRegion = function (id) {
              self.formData.selected_regions = [id];
            };

            self.tour_times = [];

            self.selectTourDate = function (date) {
              self.formData.tour_date = date;

              if (!self.tourTimes || typeof date.getDay() === 'undefined') {
                return;
              }

              self.tour_times = self.tourTimes[date.getDay()];
            };

            self.checkFormValidation = function (form) {
              self.triedSubmit = true;

              if (!form.$valid) {
                return;
              }

              processGetInTouchForm();
            };

            self.modelAddress = function () {
              return self.modelLocation.address + ' ' +
                self.modelLocation.city + ' ' +
                self.modelLocation.state + ', ' +
                self.modelLocation.zip;
            };

            self.calendarEventLink = function (type) {
              var title = 'Model Home Tour';

              if (self.formData.requested_virtual_tour) {
                title = 'Live Video ' + title;
              }

              var params = {
                type: type,
                event: 'schedule_tour',
                title: title,
                start_date: self.formData.tour_date,
                start_time: self.formData.tour_time,
                address: self.modelAddress(),
                state: self.modelLocation.state
              };

              var preparedParams = [];

              for (var param in params) {
                preparedParams.push(
                  param + '=' + encodeURIComponent(params[param])
                );
              }

              return '/calendar/create?' + preparedParams.join('&');
            };

            function processGetInTouchForm () {
              self.formResult.showForm = false;
              self.formResult.showLoader = true;

              prepareFormData();

              FormService.submit(destination, self.formData)
                .then(function (result) {
                  self.formResult = result;
                  self.formResult.showCalendarEvent = true;

                  AnalyticsService.logTrackingClick(self.analyticsConfig);

                  toggleOpen();
                });
            }

            function prepareFormData () {
              var region = regionStorageFactory.get();

              if (!self.regionID && region) {
                self.formData.selected_regions = [region.id];
                self.regionID = region.id;
              }
            }

            function toggleOpen () {
              if (!self.toggleTarget) {
                return;
              }

              $timeout(function () {
                $(self.toggleTarget).toggleClass('open');
              }, 2000);
            }
          }]
      };
    }]);
})();
