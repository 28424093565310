/*jslint white: true, nomen: true */
/*global angular */

(function() {
  'use strict';

  angular.module('app')
    .controller('CookieBannerCtrl', ['$scope', 'localStorageService', function($scope, localStorageService) {
      $scope.localStorageKey = 'cookieBanner';

      if (localStorageService.get($scope.localStorageKey)) {
        $('.cookie-banner').removeClass('is-open');
      } else {
        $('.cookie-banner').addClass('is-open');
      }

      $scope.closePopupCovid19 = function () {
        localStorageService.set($scope.localStorageKey, true);
        $('.cookie-banner').removeClass('is-open');
      };
    }]);
}());
