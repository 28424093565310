angular
  .module('modules.snapshotRange')

  .directive('snapshotRange', ['$filter', function($filter) {
    'use strict';

    return {
      link: function(scope, elem, attrs) {

        elem = $(elem);

        var content = elem.html().replace(/,/g, '');

        // split on a hyphen-minus character
        var split_content = content.split('-');

        // only works if we get ["CHUNK", "CHUNK"]
        if (split_content.length === 2) {
          var new_content = $filter('snapshotRange')({
            'low': split_content[0],
            'high': split_content[1]
          }, attrs.decimals || 0, attrs.symbol || '');
          elem.html(new_content);
          return;
        }
      }

    };
  }])
;
