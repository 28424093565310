/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .filter('removeDashes', [function() {
      return function(string) {
        if (!angular.isString(string)) {
          return string;
        }
        return string.replace(/-/g, '');
      };
    }]);
}());
