(function(){
	'use strict';

	angular.module('app.discoverthedifference')
	.directive('calculateOffset', ['$timeout', 'DeviceService', function($timeout, DeviceService){
		return {
			restrict: 'A',
			link: function(scope, elem, attrs){

				$(window).on('load resize', function(){
					var wh = $(window).height();
					var offset = 0.35;

					if(wh < 800){
						offset = 0.25;
					}

					if(DeviceService.check() == 'phone'){
						offset = 0.4;
					}			

					var h = wh * offset;

					$timeout(function(){
						elem.find('.item .top')
							.add('.item.start')
							.add('.item.end')
							.height(h);
						elem.find('i.fa').css('top', h - 70);
					},0);
				});

			}
		}
	}])
}());