(function() {
  'use strict';

  angular

    .module('app.moveindetail', [])

    .controller('moveInDetailCtrl', [
      '$scope',
      'dummyDataService',
      'slickConfigService',
      'DataService',
      'DeviceService',
      '$timeout',
      '$window',
      function(
        $scope,
        dummyDataService,
        slickConfigService,
        DataService,
        DeviceService,
        $timeout,
        $window
      ) {

        $scope.home = {};
        $scope.floorplans = [];
        var loadedOnce = false;

        $scope.homePromise = DataService($window.location.href, 'home')
          .promise
          .then(function(home) {
            $scope.$broadcast('user-selected-home', home);
            $scope.home = home;
          })
        ;

        //resize slick thumbnails based on window size by reinit
        $(window).on('load resize',function(){
          $scope.imagesLoaded = false;

          var thumbCount = 5;
          var stripCount = 2;

          if(DeviceService.check() == 'iPad'){
            thumbCount = 3;
            if(DeviceService.orientation() == 'portrait'){
              thumbCount = 2;
              stripCount = 1;
            }
          }

          if(DeviceService.check() == 'phone'){
            thumbCount = Math.round($(window).width() * 0.01) - 1;
            stripCount = 1;
          }

          $scope.slickStrip = slickConfigService.baseConfig(stripCount);
          slickConfigService.dualSlideshow($scope, thumbCount);
          $timeout(function(){
            if(loadedOnce){
              $scope.imagesLoaded = true;
            }
          },300);
        });

        // inject images into slick slideshow
        // $scope.images = dummyDataService.images();
        $scope.floorplanLevels = dummyDataService.floorplanLevels();

        $scope.homePromise
          .then(function() {
            $scope.images = $scope.home.assets;

            // TODO: Better to make these into sub-controllers, instead of
            // injecting mystery variables into the local $scope -wo
            $scope.imagesLoaded = true;
            loadedOnce = true;
          })
        ;

        $scope.homePromise
          .then(function() {
            $scope.residence = $scope.home.residence;
          })
        ;

        $scope.setSlide = function(num) {
          $scope.modalSlide = num;
        };

        $scope.moveInReadyDetailAnalyticsConfig = {
          googleAnalytics: {
            pageViewUrl: '/ajax-goals/qsu-move-in-detail'
          }
        };

      }
    ])
  ;
}());