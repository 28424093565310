(function(){
	'use strict';
	angular.module('app.common')
	.controller('regionalSmsCtrl',[
		'$scope',
		'regionStorageFactory', 
		function(
			$scope, 
			regionStorageFactory){

				$scope.sms = regionStorageFactory.getRegionPhone();

				if(!$scope.sms){
					$scope.sms = '855-342-2281';
				}

				$scope.tel = $scope.sms.replace(/-/g,'');
	}]);
}());