(function() {
  'use strict';
  angular
    .module('app.common')
    .directive('toggleClass', function() {
      return {
        restrict: 'A',
        scope: {
          toggleClass: '@'
        },
        link: function(scope, elem, attrs) {
          elem.bind('click', function() {
            var el = elem;
            if ('target' in attrs) {
              el = $(attrs.target);
            }
            el.toggleClass(scope.toggleClass);
          });
        }
      };
    });
})();