/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .filter('readMore', [function() {
      return function(content, limit, type) {

        if (content === undefined || !content) {
          return "";
        }

        if (limit === undefined) {
          limit = 5;
        }

        var output = "";

        var split = content.split(" ", limit).join(' ').length;
        var copy_1 = content.substring(0, split);
        var copy_2 = content.substring(split+1, content.length);

        output = "<span class='summary'>" +
                copy_1 + "<span class='dots'>...</span>" +
                "<span class='read-more-toggle'>" + copy_2 + "</span>" +
                "<br/><span class='read-more-toggle'></span>" +
                "</span>";

        if (type == 'homepage'){
          output = copy_1 + "...";
        }

        return output;
      };
    }]);
}());
