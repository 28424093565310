(function() {
  "use strict";

  angular
    .module('modules.laravelParameters')
    .service('laravelParameters', [ '$window', '$location',
      function($window, $location) {

        return {
          // This function now returns (as best it can) the <base href=""> value.
          // We can use this to fetch communities by their seo-name without being
          // coupled to laravel's routing table.
          segment: function(segment) {

            var segments = $window
              .location
              .pathname;

            // give me the part to trim off (/contact)
            var trim = $location
              .path();

            // Give me the pathname with the $location.path() trimmed off.
            // Also needs to escape slashes (like /contact to \/contact) for regex.
            var base = segments.replace( new RegExp(trim.replace('/', '\/') + "$"), '');

            base = base.replace(/\/$/, '');
            base = base.replace(/^\//, '');

            // Return as an array so we can keep using laravelParameters.segment(0)
            return [base];
          }
        };

      }
    ])
  ;
})();
