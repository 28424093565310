(function(){
	'use strict';
	angular.module('app.common')
	.directive('socialShare', ['$location', function($location){
		return {
			restrict: 'A',
			scope: {
				ssSrc: '@',
				ssTag: '@',
				ssType: '@'
			},
			link: function(scope, elem){

				elem.bind('click', function(){					
				    var u = scope.ssSrc;
				    var t = scope.ssTag;
				    var galleryUrl = $location.absUrl();

				    if (scope.ssType == 'facebook') {
					    window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(u)+'&t='+encodeURIComponent(t),'sharer','toolbar=0,status=0,width=626,height=436');
					    return false;
				    }
				    if (scope.ssType == 'pinterest') {
					    window.open('http://www.pinterest.com/pin/create/button/?url='+galleryUrl+'&media='+u+'&description='+t,'Pinterest','toolbar=0,status=0,width=626,height=436');
					    return false;
				    }
				    if (scope.ssType == 'twitter') {
				    	window.open('https://twitter.com/intent/tweet?text="'+t+'" - '+u+' Visit us at www.fischerhomes.com today!','Twitter','toolbar=0,status=0,width=626,height=436');
				    	return false;
				    }
				    if (scope.ssType == 'instagram') {
				    	window.open('something','Instagram','toolbar=0,status=0,width=626,height=436');
				    	return false;
				    }
				})

			}
		}
	}])
}());