(function() {
  'use strict';
  angular
    .module('app.common')
    .directive('toggleDeviceClass', ['DeviceService', '$rootScope', function(DeviceService, $rootScope) {
      return {
        restrict: 'A',
        scope: {
          toggleDeviceClass: '@',
          subnav: '@'
        },
        link: function(scope, elem, attrs) {

          $(window).on('load resize', function(){
            if(DeviceService.check() == 'phone'){

              elem.addClass(scope.toggleDeviceClass);

              if(scope.subnav){
                elem.find('a').on('click',function(){
                  $(scope.subnav).click();
                })
              }

              $rootScope.$on('mainNavToggle',function(){
                if(elem.hasClass('in')){ 
                 $(scope.subnav).click();
                }
              })
            }else if(DeviceService.touch() && !scope.subnav){
              elem.addClass(scope.toggleDeviceClass);
            }else{
              elem.removeClass(scope.toggleDeviceClass);
            }
          });

        }
      };
    }]);
})();