/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
'use strict';
    angular.module('app.common').directive('countDown', [
        function () {
            return {
                restrict: 'E',
                scope: {
                    autoStart: '@',
                    emitEvents: '@',
                    interval: '@',
                    now: '&',
                    time: '@',
                    tag: '@',
                    targetDateTime: '@'
                },
                template: '<div class="countdown">'
                    + '<span class="countdown-item day" ng-if="days > 0"><span>{{days}}</span> Days</span>'
                    + '<span class="countdown-item hour"><span>{{hours}}</span> Hours</span>'
                    + '<span class="countdown-item minute"><span>{{minutes}}</span> Minutes</span>'
                    + '<span class="countdown-item second"><span>{{seconds}}</span> Seconds</span>'
                  + '</div>',
                controller: ['$scope', '$interval', function ($scope, $interval) {
                    var MILLISECONDS_SECOND = 1000;
                    var MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND;
                    var MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE;
                    var MILLISECONDS_DAY = 24 * MILLISECONDS_HOUR;


                    // Default values
                    $scope.autoStart = $scope.autoStart || 'true';
                    $scope.emitEvents = $scope.emitEvents || 'false';
                    $scope.interval = Number($scope.interval) || 1000; // default to 1 second
                    $scope.now = $scope.now || function() { return new Date().getTime(); };
                    $scope.time = Number($scope.time) || 0; // default to 0 milliseconds
                    $scope.tag = $scope.tag || 'span';
                    $scope.targetDateTime = $scope.targetDateTime || null; // default to null

                    // Assuming targetDateTime is in UTC, just convert it to a timestamp
                    var targetDate = new Date($scope.targetDateTime + "Z").getTime();
                    console.log(targetDate, $scope)

                    // Initialize internal state
                    $scope.totalMilliseconds = targetDate ? (targetDate - (Date.now() || new Date().getTime())) : Number($scope.time);
                    $scope.endTime = (Date.now() || new Date().getTime()) + $scope.totalMilliseconds;
                    $scope.counting = false;
                    var intervalPromise;

                    // Computed properties as methods
                    function updateValues() {
                        $scope.days = Math.floor($scope.totalMilliseconds / MILLISECONDS_DAY);
                        $scope.hours = Math.floor(($scope.totalMilliseconds % MILLISECONDS_DAY) / MILLISECONDS_HOUR);
                        $scope.minutes = Math.floor(($scope.totalMilliseconds % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE);
                        $scope.seconds = Math.floor(($scope.totalMilliseconds % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND);

                        console.log('updateValues called:', $scope.totalMilliseconds, $scope.days, $scope.hours, $scope.minutes, $scope.seconds);
                    }

                    updateValues();

                    // Methods
                    $scope.start = function() {
                        if ($scope.emitEvents === 'true') {
                            $scope.$emit('start');
                        }

                        intervalPromise = $interval(() => {
                            $scope.totalMilliseconds -= Number($scope.interval);
                            updateValues();

                            if ($scope.totalMilliseconds <= 0) {
                                if ($scope.emitEvents === 'true') {
                                    $scope.$emit('end');
                                    $interval.cancel(intervalPromise);
                                }
                            }
                        }, Number($scope.interval));
                    };


                    $scope.pause = function() {
                        $scope.counting = false;
                        $interval.cancel(intervalPromise);
                    };

                    $scope.restart = function() {
                        $scope.pause();
                        $scope.totalMilliseconds = targetDate ? (targetDate - (Date.now() || new Date().getTime())) : Number($scope.time);
                        $scope.start();
                    };

                    // Auto-start if specified
                    if ($scope.autoStart === 'true') {
                      $scope.start();
                    }
                }]
            }
        }
    ]);
}());
