/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentPrimaryPhone', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-primary-phone',
        replace: true,
        scope: {
          formObj: "=",
          formScope: "="
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: ['$scope', function($scope) {
          $scope.phoneNumberPattern = (function() {
            var regexp = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;
            return {
              test: function(value) {
                return regexp.test(value);
              }
            };
          })();
        }]
      };

    }]);
}());
