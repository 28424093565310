(function(){
	'use strict';
	angular.module('app.energywise')
	.controller('energywiseCtrl', ['$scope', function ($scope) {

		$scope.hers=[];

		for(var i = 150; i > -1; i-=10){
			$scope.hers.push(i);
		}

    $scope.energywiseAnalyticsConfig = {
      googleAnalytics: {
        pageViewUrl: '/ajax-goals/qsu-energywise'
      }
    };

	}])
}());