(function() {
  'use strict';

  angular.module('app.forms')
    .directive('fhFormTourAModelHome', [function() {
      return {
        restrict: "E",
        scope: {
          formResult: "=",
          analyticsConfig: '='
        },
        bindToController: true,
        controllerAs: 'vm',
        templateUrl: '/forms/fh-form-tour-a-model-home',
        link: function() {},
        controller: [
          'FormService',
          'DeviceService',
          'regionStorageFactory',
          '$scope',
          '$filter',
          '$timeout',
          'AnalyticsService',
          function(
            FormService,
            DeviceService,
            regionStorageFactory,
            $scope,
            $filter,
            $timeout,
            AnalyticsService
          ) {


            var self = this,
              prefillMode = false,
              prefillData = FormService.prefillForm(prefillMode, 'quickSignup');


            self.formResult = {};
            self.formData    = prefillData.formData;
            self.formResult  = prefillData.formResult;
            self.regions     = [];
            // self.regionID    = false;
            self.regionPhone = FormService.getDefaultRegionPhone();
            self.regions = FormService.getRegionsForSelect();

            self.selectRegion = function(id) {
              self.formData.selected_regions = [id];
            };

            self.checkFormValidation = function(form) {
              self.triedSubmit = true;

              if (! form.$valid) {
                return;
              }

              processGetInTouchForm();
            };

            function processGetInTouchForm() {
              self.formResult.showForm = false;
              self.formResult.showLoader = true;

              prepareFormData();

              FormService.submit('/forms/quick-signup', self.formData)
                .then(function(result) {
                  self.formResult = result;
                  self.formResult.dirty = false;

                  AnalyticsService.logTrackingClick(self.analyticsConfig);

                });
            }

            function prepareFormData() {
              var region = regionStorageFactory.get();

              if (! self.regionID && region) {
                self.formData.selected_regions = [region.id];
                self.regionID = region.id;
              }
            }

          }]
      };
    }]);
})();