/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function() {
  'use strict';

	angular.module('app.homepage')
	.controller("homepageCtrl", [
		'$scope',
		'$timeout',
		'DeviceService',
		'$window',
		'CommunitiesListDataService',
		'TestimonialListDataService',
		'$rootScope',
		'regionStorageFactory',
		function($scope,
			$timeout,
			DeviceService,
			$window,
			CommunitiesListDataService,
			TestimonialListDataService,
			$rootScope,
			regionStorageFactory
		) {

			TestimonialListDataService.getTestimonials().then(function(result){
				$scope.testimonials = result.data.testimonials.filter(function(testimonial) {
					return testimonial.style !== 'video';
				});
			});

			CommunitiesListDataService.getRegions().then(function(result){
				$scope.regionDropdown = result.regions;
			});

	// 		$scope.toggled = function(open) {
	// 		  if (DeviceService.check() == 'phone' && open) {
	// 		    $('html, body').animate({
	// 		      scrollTop: $('section#welcome-home .col-left button').offset().top - parseInt(75, 10)
    //       }, 750);
	// 		  }
    //   }

			var switchSlides = function() {
				$scope.slides = [];

	        	// if(DeviceService.check() == 'phone'){
	        	// 	$scope.slides = $scope.mobileSlides;
	        	// }else{
				// 	$scope.slides = $scope.homepageSlides;
	        	// }
				$scope.slides = $scope.homepageSlides;
	            $scope.$apply();
			};

			angular.element($window).bind('load resize', switchSlides);

			// this hack makes me physically sick but it's the only thing I can think of
			// to overcome a browser bug where autoplay is not honored if the dom makes
			// any change to the <video> element. Which, of course, we need to do. -wo
			$timeout(function(){
				switchSlides();
				$timeout(function(){
					var video = document.getElementById('main-video')
					if (video) {
						video.play();
					}
				});
			});

	        $scope.closePopovers = function(event){
	        	if($(event.target).closest('.pin').length < 1){
	        		$rootScope.$broadcast('closePopOvers',{id:null});
	        	}
	        }

	        $scope.mobileTrigger = function(e){
	        	if(DeviceService.check() == 'phone'){
	        		e.preventDefault();
	        		$('#main-video')[0].play();
	        	}
	        };

			$scope.generatePopoverContent = function(pin){
				return '<a href="/find-new-homes/' + pin.seo + '/communities"><i class="fa fa-search"></i> SEARCH</a>';
			}

	        $scope.mobileSlides = [
				// {
				// 	type: 'image',
				// 	slideMobile: true,
				// 	img: '/images/site/homepage/slides/mobile-background.jpg',
				// 	cta: 'FIND YOUR HOME',
				// 	ctaLink: '/images/site/homepage/slides/fischer.mp4'
				// }
	        ];

	        $scope.pins = [
	        	{
	        		title: 'Columbus, OH',
	        		seo: 'columbus/ohio',
	        		top: '25%',
	        		left: '58%'
	        	},
	        	{
	        		title: 'Indianapolis, IN',
	        		seo: 'indianapolis/indiana',
	        		top: '28%',
	        		left: '44%'
	        	},
	        	{
	        		title: 'Atlanta, GA',
	        		seo: 'atlanta/georgia',
	        		top: '60%',
	        		left: '58%'
	        	},
	        	{
	        		title: 'Cincinnati + Northern Kentucky',
              seo: 'cincinnati-area/ohio',
	        		top: '29%',
	        		left: '51.5%'
	        	},
	        	{
	        		title: 'Dayton, OH',
	        		seo: 'dayton/ohio',
	        		top: '27%',
	        		left: '54%'
            },
            {
              title: 'St. Louis, MO',
              seo: 'st-louis/missouri',
              top: '35%',
              left: '25%'
            },
            {
              title: 'Louisville, KY',
              seo: 'louisville/kentucky',
              top: '36%',
              left: '48%'
            },
            {
              title: 'Northwest Florida',
              seo: 'northwest-florida',
              top: '80.5%',
              left: '51%'
            }
	        ];

	        $scope.parseRegion = function(template, section) {
	          if (template === null || regionStorageFactory.get()) {
	            var region = regionStorageFactory.get();
	            $window.location.href = region.url + section;
	          } else {
	            $scope.$broadcast('triggerModal', {
	              path: template,
	              section: section
	            });
	          }
	        };

			$scope.homepageSlides = [
			{
				id: 0,
				type: 'video',
				classname: 'video',
				title: 'welcoming spaces',
				cta: 'FIND YOUR NEW HOME'
			}
			];

			var menuHeader = document.getElementsByClassName('navbar-header')[0];
			var menuToggle = document.getElementsByClassName('navbar-toggle')[0];

			var menuToggleObserver = new MutationObserver((mutationsList, observer) => {
				var classList = menuToggle.classList;

				if(!classList.contains('collapsed')) {
					menuHeader.style.backgroundColor = 'black';
				} else {
					menuHeader.style.backgroundColor = '';
				}
			})

			var menuToggleObserverConfig = {attributes: true, attributeFilter: ['class']};

			menuToggleObserver.observe(menuToggle, menuToggleObserverConfig);

			if(!menuToggle.classList.contains('collapsed')){
				menuHeader
			}


			menuHeader.classList.remove('dark-scroll');

			window.addEventListener('scroll', function () {

				var scrollPosition = window.scrollY;
				var scrollThreshold = window.innerHeight - 120;

				if (scrollPosition > scrollThreshold) {
				  menuHeader.classList.add('dark-scroll');
				} else {
				  menuHeader.classList.remove('dark-scroll');
				}
			  });



		}
	]);

}());
