/*jslint white: true, nomen: true */
/*global angular, $, window, navigator */
(function() {
  'use strict';

  angular.module('app.common')
    .controller("inViewCtrl", ["$scope",
      function($scope) {

        $scope.displaySection = function(inview, elem) {
          if (inview) {
        console.log("inview is init");
            $(elem).addClass('in');
          }
        };

      }
    ]);
}());
