/*jslint white: true, nomen: true */
/*global $, angular */
(function() {
  'use strict';

  angular.module('app.common')

  .service('CMSService', ['$http', function($http) {
    return {
      loadContents: function(type) {
        return $http.get('/api/cms/'+type);
      }
    };
  }]);
}());
