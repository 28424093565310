/*jslint white: true, nomen: true */
/*global $, angular */
(function() {
  'use strict';

  angular.module('app.common')

  .service('AssetListDataService', ['$http', function($http) {
    var data = {
      assetsPromise: null,
      videosPromise: null,
    };

    return {
      loadAssets: function(type) {
        if (data.assetsPromise === null) {
          var apiUrl = '/api/asset/global';
          if (type) {
            apiUrl = apiUrl+'?'+type+'=1';
          }

          data.assetsPromise = $http.get(apiUrl, {
            headers: {
              'Cache-Control': 'no-cache'
            },
            cache: true
          });
        }

        return data.assetsPromise;
      },
      loadVideos: function(type) {
        if (data.videosPromise === null) {
          var apiUrl = '/api/video';
          if (type) {
            apiUrl = apiUrl+'?'+type+'=1';
          }

          data.videosPromise = $http.get(apiUrl, {
            headers: {
              'Cache-Control': 'no-cache'
            },
            cache: true
          });
        }

        return data.videosPromise;
      }
    };
  }]);
}());
