/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('customAccordian', [
      "$timeout",
      function($timeout) {
        return {
          restrict: 'A',
          link: function(scope, elem, attrs) {

            $timeout(function() {

              var $target = $(elem).find('.panel-outer');

              $target.bind('click', function() {
                if ($(this).hasClass('open')) {
                  $(this).removeClass('open');
                } else {
                  $(elem).find('.open').removeClass('open');
                  $(this).addClass('open');
                }
              })
            }, 0)

          }
        };
      }
    ]);
}());
