(function() {
  "use strict";

  angular
    .module('modules.googleMaps')
    .factory('googleMapsPlacesServiceFactory', ['uiGmapPromises',
      function(uiGmapPromises) {

        return {
          getPlacesService: function(map) {

            return uiGmapPromises
              .GoogleMapsApiReady()
              .then(function(GoogleMaps) {

                return new GoogleMaps.places.PlacesService(map);

              });

          }
        };

      }
    ])
  ;

})();