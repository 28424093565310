/*jslint white: true, nomen: true */
/*global $, angular */
(function() {
  'use strict';

  angular.module('app.common')

    .service('RegionsListDataService', ['$http', '$q', function($http, $q) {
      var regionsListDataServiceResultFailMessageText = 'No regions found',
        data = {
          regionsPromise: null,
          regionPromise: null
        },

        getRegions = function() {
          if (data.regionsPromise === null) {
            data.regionsPromise = $http.get('/api/region', {
              headers: {
                'Cache-Control': 'no-cache'
              },
              cache: true
            }).then(regionsListDataServiceWash);
          }

          return data.regionsPromise;
        },

        getRegion = function(communityId) {
          if (data.regionPromise === null) {
            data.regionPromise = $http.get('/api/region/' + regionId, {
              cache: true
            }).then(regionsListDataServiceWash);
          }

          return data.regionPromise;
        },

        regionsListDataServiceWash = function(result) {
          var successResult = {},
            failResult = {
              status: 'failure',
              regions: [],
              errors: regionsListDataServiceResultFailMessageText
            };

          if (result === undefined || result.data === undefined || result.data.status === undefined) {
            return failResult;
          }

          if (result.data.status === 'failure') {
            return failResult;
          }

          if (result.data.regions) {
            successResult.regions = result.data.regions;
          } else if (result.data.region) {
            successResult.region = result.data.region;
          }

          successResult.status = result.data.status;
          successResult.errors = 'none';

          return successResult;
        };

      return {
        getRegions: getRegions,
        getRegion: getRegion,
      };

    }]);
}());
