(function(){
	'use strict';
	angular.module('app.contactus')
	.controller('contactUsCtrl',['$scope','CommunitiesListDataService', function($scope, CommunitiesListDataService){

		CommunitiesListDataService.getRegions().then(function(result){
			$scope.regions = result.regions;
		});

	}]);
}());