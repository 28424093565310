(function(){
  "use strict";

  angular.module("app.common")
  .directive("sortList", function(){
    return {
      transclude: true,
      replace: true,
      scope: {
        sort: '=',
        reverse: '=',
        column: '@'
      },
      link: function(scope, element){

        element.addClass('sort-item');

        scope.toggleSort = function(sortColumn) {
          if (sortColumn === scope.sort) {
            scope.reverse = !scope.reverse;
            return;
          }

          scope.sort = sortColumn;
          scope.reverse = false;
        };

        scope.sortClass = function(column, direction) {
          if (column == scope.sort && direction == scope.reverse) {
            return 'active';
          }
          return false;
        };
      },
      template: " " +
        "<span class='sort-item' ng-click=\"toggleSort(column)\"> " +
        "    <ng-transclude></ng-transclude> " +
        "    <span class='arrows'> " +
        "        <i class='arrow fa fa-angle-up' ng-class=\"sortClass(column, false)\"></i> " +
        "        <i class='arrow fa fa-angle-down' ng-class=\"sortClass(column, true)\"></i> " +
        "    </span> " +
        "</span> "
    };
  });
}());