/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
    'use strict';
    angular.module('app.common')
        .service('dummyDataService', [function() {

            var dummyCopy = "This plan has it all. A huge pantry, private study, and a special angled window wall in the family room. Luxurious owner's suite, and three bedrooms - each with its own large, walk-in closet. Stairs are convenietly located near the rear of the home. If these are the basic features of the Marshall floorplan, just imagine what you can do with the options!";

            var communities = [
                {
                    image: '/images/site/microsite/thumb-home-02.jpg',
                    name: "BARRET LANDING",
                    location: 'FULTON COUNTY SCHOOL DISTRICT',
                    priceLow: 420,
                    priceHigh: 550,
                    ammenities: [
                        'golf',
                        'pets',
                        'gazebo',
                        'fountain',
                        'park',
                        'pool',
                        'gym',
                        'school'
                    ],
                    signature: true
                }, {
                    image: '/images/site/microsite/thumb-home-02.jpg',
                    name: "BARRET LANDING",
                    location: 'FULTON COUNTY SCHOOL DISTRICT',
                    priceLow: 420,
                    priceHigh: 550,
                    ammenities: [
                        'golf',
                        'pets',
                        'gazebo',
                        'gym',
                        'school'
                    ],
                    modelHome: true,
                    comingSoon: true
                }, {
                    image: '/images/site/microsite/thumb-home-02.jpg',
                    name: "BARRET LANDING",
                    location: 'FULTON COUNTY SCHOOL DISTRICT',
                    priceLow: 420,
                    priceHigh: 550,
                    ammenities: [
                        'golf',
                        'pets',
                        'gazebo',
                        'fountain',
                        'gym',
                        'school'
                    ],
                }, {
                    image: '/images/site/microsite/thumb-home-02.jpg',
                    name: "BARRET LANDING",
                    location: 'FULTON COUNTY SCHOOL DISTRICT',
                    priceLow: 420,
                    priceHigh: 550,
                    ammenities: [
                        'fountain',
                        'park',
                        'pool',
                        'gym'
                    ],
                    comingSoon: true
                }
            ];

            var regions = [
                {
                    location: 'ATLANTA, GA'
                },
                {
                    location: 'CINCINNATI, OH',
                    area: [
                        {
                            name: 'Greater Cincinnati',
                            link: ''
                        },
                        {
                            name: 'Cincinnati',
                            link: ''
                        },
                        {
                            name: 'Northern Kentucky',
                            link: ''
                        },
                        {
                            name: 'Southeast Indiana',
                            link: ''
                        }
                    ]
                },
                {
                    location: 'COLUMBUS, OH'
                },
                {
                    location: 'DAYTON, OH'
                },
                {
                    location: 'INDIANAPOLIS, IN'
                }
            ];

            var moveInReady = [
                {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "ANDOVER",
                    address: "1840 Kingsbury Drive, Cumming, GA",
                    price: 399875,
                    beds: 5,
                    baths: 3.5,
                    sqft: 3865,
                    availability: "Ready Now"
                }, {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "ANDOVER",
                    address: "1840 Kingsbury Drive, Cumming, GA",
                    price: 399875,
                    beds: 5,
                    baths: 3.5,
                    sqft: 3865,
                    availability: "Ready Now"
                }, {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "ANDOVER",
                    address: "1840 Kingsbury Drive, Cumming, GA",
                    price: 399875,
                    beds: 5,
                    baths: 3.5,
                    sqft: 3865,
                    availability: "Ready Now"
                }, {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "ANDOVER",
                    address: "1840 Kingsbury Drive, Cumming, GA",
                    price: 399875,
                    beds: 5,
                    baths: 3.5,
                    sqft: 3865,
                    availability: "Ready Now"
                }, {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "ANDOVER",
                    address: "1840 Kingsbury Drive, Cumming, GA",
                    price: 399875,
                    beds: 5,
                    baths: 3.5,
                    sqft: 3865,
                    availability: "Ready Now"
                }
            ];

            var homeModels = [
                {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "LIFESTYLE CLUBHOUSE",
                    location: 'Edenwood',
                    address: "22 Watervine Ct. Dallas, GA 30132"
                }, {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "LIFESTYLE CLUBHOUSE",
                    location: 'Edenwood',
                    address: "22 Watervine Ct. Dallas, GA 30132"
                }, {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "LIFESTYLE CLUBHOUSE",
                    location: 'Edenwood',
                    address: "22 Watervine Ct. Dallas, GA 30132"
                }, {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "LIFESTYLE CLUBHOUSE",
                    location: 'Edenwood',
                    address: "22 Watervine Ct. Dallas, GA 30132"
                }, {
                    image: '/images/site/microsite/thumb-home.jpg',
                    name: "LIFESTYLE CLUBHOUSE",
                    location: 'Edenwood',
                    address: "22 Watervine Ct. Dallas, GA 30132"
                }
            ];

            var floorplans = [
                {
                    image: '/images/site/microsite/thumb-home-02.jpg',
                    name: "MARSHALL",
                    elevations: 3,
                    priceLow: 420,
                    priceHigh: 550,
                    bedsLow: 4,
                    bedsHigh: 5,
                    bathsLow: 3.5,
                    bathsHigh: 3.5,
                    sqftLow: 3865,
                    sqftHigh: 5413,
                    levels: "Two Story",
                    featured: false,
                    details: dummyCopy
                }, {
                    image: '/images/site/microsite/thumb-home-02.jpg',
                    name: "MARSHALL",
                    elevations: 3,
                    priceLow: 420,
                    priceHigh: 550,
                    bedsLow: 4,
                    bedsHigh: 5,
                    bathsLow: 3.5,
                    bathsHigh: 3.5,
                    sqftLow: 3865,
                    sqftHigh: 5413,
                    levels: "Two Story",
                    featured: false,
                    details: dummyCopy
                }, {
                    image: '/images/site/microsite/thumb-home-02.jpg',
                    name: "MARSHALL",
                    elevations: 3,
                    priceLow: 420,
                    priceHigh: 550,
                    bedsLow: 4,
                    bedsHigh: 5,
                    bathsLow: 3.5,
                    bathsHigh: 3.5,
                    sqftLow: 3865,
                    sqftHigh: 5413,
                    levels: "Two Story",
                    featured: false,
                    details: dummyCopy
                }, {
                    image: '/images/site/microsite/thumb-home-02.jpg',
                    name: "MARSHALL",
                    elevations: 3,
                    priceLow: 420,
                    priceHigh: 550,
                    bedsLow: 4,
                    bedsHigh: 5,
                    bathsLow: 3.5,
                    bathsHigh: 3.5,
                    sqftLow: 3865,
                    sqftHigh: 5413,
                    levels: "Two Story",
                    featured: false,
                    details: dummyCopy
                }
            ];

            var images = [
                {
                    image: '/images/site/global/template-slide-001.jpg',
                    caption: 'exterior one',
                    category: 'exteriors'
                }, {
                    image: '/images/site/global/template-slide-001a.jpg',
                    caption: 'exterior two',
                    category: 'exteriors'
                }, {
                    image: '/images/site/global/template-slide-001b.jpg',
                    caption: 'exterior three',
                    category: 'exteriors'
                },
                {
                    image: '/images/site/global/template-slide-001.jpg',
                    caption: 'exterior one',
                    category: 'exteriors'
                }, {
                    image: '/images/site/global/template-slide-001a.jpg',
                    caption: 'exterior two',
                    category: 'exteriors'
                }, {
                    image: '/images/site/global/template-slide-001b.jpg',
                    caption: 'exterior three',
                    category: 'exteriors'
                },
                {
                    image: '/images/site/global/template-slide-002.jpg',
                    caption: 'interior one',
                    category: 'interiors'
                }, {
                    image: '/images/site/global/template-slide-002a.jpg',
                    caption: 'interior two',
                    category: 'interiors'
                }, {
                    image: '/images/site/global/template-slide-002b.jpg',
                    caption: 'interior three',
                    category: 'interiors'
                }, {
                    image: '/images/site/global/template-slide-001.jpg',
                    caption: 'brochures one',
                    link: '/images/site/vendor-bid/pdf/',
                    category: 'brochures'
                }, {
                    image: '/images/site/global/template-slide-001.jpg',
                    caption: 'brochures two',
                    link: '/images/site/vendor-bid/pdf/',
                    category: 'brochures'
                }, {
                    image: '/images/site/global/template-slide-001.jpg',
                    caption: 'brochures three',
                    link: '/images/site/vendor-bid/pdf/',
                    category: 'brochures'
                }, {
                    image: '/images/site/global/template-slide-002.jpg',
                    caption: 'videos one',
                    category: 'videos'
                }, {
                    image: '/images/site/global/template-slide-002.jpg',
                    caption: 'videos two',
                    category: 'videos'
                }, {
                    image: '/images/site/global/template-slide-002.jpg',
                    caption: 'videos three',
                    category: 'videos'
                }
            ];

            var floorplanLevels = [
                {
                    name: '1st FLOOR',
                    image: '/images/site/global/template-floorplan.jpg'
                },
                {
                    name: '1st FLOOR W/ OPTIONS',
                    image: '/images/site/global/template-floorplan.jpg'
                },
                {
                    name: '2nd FLOOR',
                    image: '/images/site/global/template-floorplan.jpg'
                },
                {
                    name: '2nd FLOOR W/ OPTIONS',
                    image: '/images/site/global/template-floorplan.jpg'
                },
                {
                    name: 'BASEMENT',
                    image: '/images/site/global/template-floorplan.jpg'
                },
                {
                    name: 'FINISHED BASEMENT',
                    image: '/images/site/global/template-floorplan.jpg'
                }
            ];

            var collections = [
                {
                    image: '/images/site/our-collections/thumb-001.jpg',
                    caption: 'GRAND <br/>ESTATES CUSTOM'
                },
                {
                    image: '/images/site/our-collections/thumb-002.jpg',
                    caption: 'MASTERPIECE'
                },
                {
                    image: '/images/site/our-collections/thumb-003.jpg',
                    caption: 'DESIGNER'
                },
                {
                    image: '/images/site/our-collections/thumb-001.jpg',
                    caption: 'MAPLE STREET'
                },
                {
                    image: '/images/site/our-collections/thumb-002.jpg',
                    caption: 'THE NEW <br/>PATIO HOMES'
                },
                {
                    image: '/images/site/our-collections/thumb-003.jpg',
                    caption: 'PATIO HOMES'
                },
                {
                    image: '/images/site/our-collections/thumb-001.jpg',
                    caption: 'GALERY II'
                },
                {
                    image: '/images/site/our-collections/thumb-002.jpg',
                    caption: 'GALLERY'
                },
                {
                    image: '/images/site/our-collections/thumb-003.jpg',
                    caption: 'VILLA II'
                }
            ];

            var designProcess = [
                {
                    title: 'Visit a Model',
                    image: '/images/site/lifestyle/step-1.jpg?v=2',
                    detail: 'Visit a Fischer Homes model and be inspired ! When you visit a model home you will see all of the latest Fischer Homes has to offer. Your new Fischer Home will incorporate all of the latest design trends and finishing touches'
                },
                {
                    title: 'Attend Preview Days',
                    image: '/images/site/lifestyle/step-2.jpg?v=2',
                    detail: 'Our Design Centers showcase the various choices you can make as you personalize your new Fischer Home. By visiting on preview days you will get a sense of what choices you need to make, the numerous options you have to choose from, and you can begin thinking about what styles and finishes best suit your tastes.'
                },
                {
                    title: 'The Selections Appointment',
                    image: '/images/site/lifestyle/step-3.jpg?v=2',
                    detail: 'Meet with one of our talented designers who can advise you on your dream home. Working with your designer you are guided through the design process and get to personalize your home with the counters, cabinets, faucets and more that will truly make your home unique.'
                },
                {
                    title: 'The Sign Off',
                    image: '/images/site/lifestyle/step-4.jpg',
                    detail: 'You get a final review and the chance to see a summary of everything you have selected for your new Fischer Home. Once this is complete everything is set and the design is complete for your new Fischer Home.'
                }
            ];

            var designCenters = [
                {
                    location: 'ATLANTA',
                    phone: '4042209951',
                    address: '2675 Paces Ferry Road SE, Suite #350, Atlanta, Georgia 30339',
                    latitude: 33.866572,
                    longitude: -84.476042,
                    link: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=2675+Paces+Ferry+Road+SE+Suite+%23350+Atlanta,+GA+30339&aq&sll=37.822293,-85.76824&sspn=7.98959,16.907959&vpsrc=0&t=h&ie=UTF8&hq&hnear=2675+Paces+Ferry+Rd+SE+%23350,+Atlanta,+Georgia+30339&z=17',
                    previewTimes: '<p>By reservation only. Contact your Sales Counselor to schedule today!</p>',
                    appointmentTimes: '<p>Monday - Friday  |  9 AM - 5 PM</p>'
                },
                {
                    location: 'CINCINNATI',
                    subLocation: '& NORTHERN KY',
                    phone: '8594411989',
                    address: '3940 Olympic Blvd, Suite 100, Erlanger, Kentucky 41018',
                    latitude: 39.054555,
                    longitude: -84.629275,
                    link: 'https://www.google.com/maps/place/3940+Olympic+Blvd+%23100,+Erlanger,+KY+41018/@39.067447,-84.628201,14z/data=!4m5!3m4!1s0x8841c840f9299a03:0xae1ed5b83896a251!8m2!3d39.0545382!4d-84.629275',
                    previewTimes: '<p>Every Wednesday | 9 AM - 5 PM | By Reservation Only<br />1st and 3rd Saturdays of the Month | 9 AM - 12 PM | By Reservation Only</p>',
                    appointmentTimes: '<p>Monday - Friday | 9 AM - 5 PM</p>'
                },
                {
                    location: 'COLUMBUS',
                    phone: '6148962554',
                    address: '7965 North High Street, Suite 20, Columbus, Ohio 43235',
                    latitude: 40.127655,
                    longitude: -83.017317,
                    link: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=7965+North+High+Street,+Suite+20,+Columbus,+Ohio+43235&sll=37.0625,-95.677068&sspn=48.688845,113.90625&ie=UTF8&ll=40.127441,-83.017159&spn=0.185068,0.444946&z=12',
                    previewTimes: '<p>Thursday & Friday | 9 AM - 3 PM | By Reservation Only</p>',
                    appointmentTimes: '<p>Monday - Friday | 9 AM - 5 PM</p>'
                },
                {
                    location: 'DAYTON',
                    phone: '9376604100',
                    address: '7777 Washington Village Drive, Suite 300',
                    latitude: 39.633349,
                    longitude: -84.195802,
                    link: 'https://www.google.com/maps/place/7777+Washington+Village+Dr+%23300,+Washington+Township,+OH+45459/@39.6333498,-84.1958027,18.3z/data=!4m13!1m7!3m6!1s0x884088d7734c1ac9:0x69b9be41923b3d2a!2s7777+Washington+Village+Dr+%23300,+Washington+Township,+OH+45459!3b1!8m2!3d39.6337477!4d-84.1948559!3m4!1s0x884088d7734c1ac9:0x69b9be41923b3d2a!8m2!3d39.6337477!4d-84.1948559',
                    previewTimes: '<p>2nd and 4th Wednesdays of the Month | 5 PM - 7 PM | By Reservation Only</p>',
                    appointmentTimes: '<p>Monday - Friday | 9 AM - 5 PM</p>'
                },
                {
                    location: 'INDIANAPOLIS',
                    phone: '3173482500',
                    address: '6602 E 75th St #400, Indianapolis, IN 46250',
                    latitude: 39.891578,
                    longitude: -86.054593,
                    link: 'https://www.google.com/maps?f=q&source=s_q&hl=en&geocode&q=6602+East+75th+Street,+Suite+400,+Indianapolis,+IN+46250&aq&sll=39.892486,-86.056358&sspn=0.008824,0.021415&ie=UTF8&hq&hnear=6602+E+75th+St+%23400,+Indianapolis,+Indiana+46250&ll=39.891596,-86.054707&spn=0.008759,0.021415&t=h&z=16',
                    previewTimes: '<p>By reservation only. Contact your Sales Counselor to schedule today!</p>',
                    appointmentTimes: '<p>Monday - Friday | 9 AM - 5 PM</p>'
                },
                {
                    location: 'LOUISVILLE',
                    phone: '5024509400',
                    address: '2301 Terra Crossing Blvd, Suite 106, Louisville, KY 40245',
                    latitude: 38.2713989,
                    longitude: -85.497019,
                    link: 'https://www.google.com/maps/place/2301+Terra+Crossing+Blvd+%23106,+Louisville,+KY+40245/@38.2713989,-85.497019,17z/data=!3m1!4b1!4m5!3m4!1s0x886998e1ee433ad1:0x598e0c99ed82b82e!8m2!3d38.2713989!4d-85.4948303',
                    previewTimes: 'By reservation only. Contact your Sales Counselor to schedule today!',
                    appointmentTimes: '<p>Monday - Friday | 8 AM - 5 PM</p>'
                },
                {
                    location: 'NORTHWEST FLORIDA',
                    phone: '8502142066',
                    address: '806 Breakfast Point Blvd, Panama City Beach, FL 32407',
                    latitude: 30.2042718,
                    longitude: -85.7985578,
                    link: 'https://www.google.com/maps/place/Breakfast+Point+East+by+Fischer+Homes/@30.2042718,-85.7985578,15z/data=!4m6!3m5!1s0x88938fe3c582e395:0xeef41abf48f5dd3f!8m2!3d30.2042718!4d-85.7985578!16s%2Fg%2F11h3c590_3',
                    previewTimes: 'By reservation only. Please give us a call at the number above to schedule a time!',
                    appointmentTimes: '<p>Monday - Friday | 8 AM - 5 PM</p>'
                },
                {
                    location: 'ST. LOUIS',
                    phone: '6362652170',
                    address: '801 Upland Loop, O\'Fallon, MO 63368',
                    latitude: 38.743768,
                    longitude: -90.756750,
                    link: 'https://www.google.com/maps/place/801+Upland+Loop+Ofallon,+MO+63368/@38.743768,-90.75675,17z',
                    previewTimes: '<p>2nd and 4th Thursdays of the Month | 5PM - 7PM | By Reservation Only</p>',
                    appointmentTimes: '<p>Monday - Friday | 9 AM - 5 PM</p>'
                }
            ];
            var biddingDocuments = [
                {
                    jobNumber: 'BDG-007',
                    homeType: 'Blair - Basement',
                    product: 'Designer',
                    drawingsPdf: '/images/site/vendor-bid/pdf/BDG-007-Blair-Construction-Drawings.pdf',
                    drawingsDwf: '/images/site/vendor-bid/pdf/BDG-007-Blair-Construction-Drawings.dwf',
                    selections: '/images/site/vendor-bid/pdf/BDG-007-Blair-Selections.pdf',
                    plotPlan: '/images/site/vendor-bid/pdf/BDG-007-Blair-Plot-Plan.pdf'
                },
                {
                    jobNumber: 'BDG-012',
                    homeType: 'Foster-Basement',
                    product: 'Designer',
                    drawingsPdf: '/images/site/vendor-bid/pdf/BDG-012-Foster-Construction-Drawings.pdf',
                    drawingsDwf: '/images/site/vendor-bid/pdf/BDG-012-Foster-Construction-Drawings.dwf',
                    selections: '/images/site/vendor-bid/pdf/BDG-012-Foster-Selections.pdf',
                    plotPlan: '/images/site/vendor-bid/pdf/BDG-012-Foster-Plot-Plan.pdf'
                },
                {
                    jobNumber: 'MHD-024',
                    homeType: 'Cumberland - Basement',
                    product: 'Maple Street',
                    drawingsPdf: '/images/site/vendor-bid/pdf/MHD-024-Cumberland-Construction-Drawings.pdf',
                    drawingsDwf: '/images/site/vendor-bid/pdf/MHD-024-Cumberland-Construction-Drawings.dwf',
                    selections: '/images/site/vendor-bid/pdf/MHD-024-Cumberland-Selections-Landscaping.pdf',
                    plotPlan: '/images/site/vendor-bid/pdf/MHD-024-Cumberland-Plot-Plan.PDF'
                },
                        {
                    jobNumber: 'MHD-123',
                    homeType: 'Yosemite - Basement',
                    product: 'Maple Street',
                    drawingsPdf: '/images/site/vendor-bid/pdf/MHD-123-Yosemite-Construction-Drawings.pdf',
                    drawingsDwf: '/images/site/vendor-bid/pdf/MHD-123-Yosemite-Construction-Drawings.dwf',
                    selections: '/images/site/vendor-bid/pdf/MHD-123-Yosemite-Selections.pdf',
                    plotPlan: '/images/site/vendor-bid/pdf/MHD-123-Yosemite-Plot-Plans.pdf'
                },
                {
                    jobNumber: 'SML-093',
                    homeType: 'Yosemite - Slab',
                    product: 'Maple Street',
                    drawingsPdf: '/images/site/vendor-bid/pdf/SML-093-Yosemite-Slab-Construction-Drawings.pdf',
                    drawingsDwf: '/images/site/vendor-bid/pdf/SML-093-Yosemite-Slab-Construction-Drawings.dwf',
                    selections: '/images/site/vendor-bid/pdf/SML-093-Yosemite-Slab-Selections.pdf',
                    plotPlan: '/images/site/vendor-bid/pdf/SML-093-Yosemite-Slab-Plot-Plan.pdf'
                }
            ];

            this.communities = function() {
                return communities;
            }

            this.moveInReady = function() {
                return moveInReady;
            }

            this.regions = function() {
                return regions;
            }

            this.homeModels = function() {
                return homeModels;
            }

            this.floorplans = function() {
                return floorplans;
            }

            this.floorplanLevels = function() {
                return floorplanLevels;
            }

            this.images = function() {
                return images;
            }

            this.collections = function() {
                return collections;
            }

            this.designProcess = function() {
                return designProcess;
            }

            this.designCenters = function() {
                return designCenters;
            }

            this.biddingDocuments = function() {
                return biddingDocuments;
            }

        }]);
}());
