(function () {
  'use strict';

  angular.module('app.common')
    .directive('snapshotInfo', function () {
      return {
        restrict: 'E',
        transclude: true,
        scope: {
          beds: '@',
          baths: '@',
          sqft: '@',
          levels: '@',
          basement: '@'
        },
        templateUrl: function (elem, attrs) {
          return attrs.templateUrl;
        }
      };
    });
})();
