/* global angular */
(function() {
  'use strict';
  angular
    .module("app.forms")
    .service("AnalyticsService", [
      'GoogleAnalyticsService',
      'ClickyService',
      function(
        GoogleAnalyticsService,
        ClickyService
      ) {
        var service = {};

        // Example config to be passed from parent page's controller to form directives
        service.getDefaultAnalyticsConfig = function() {
          return {
            clicky: {
              name: 'Example Form',
              goal: '123',
              pageViewUrl: '/ajax-goals/example'
            },
            googleAnalytics: {
              pageViewUrl: '/ajax-goals/example'
            }
          };
        };

        service.logTrackingClick = function(analyticsConfig, result) {
          GoogleAnalyticsService.sendPageView(analyticsConfig.googleAnalytics.pageViewUrl);
          ClickyService.sendPageView(analyticsConfig.clicky);

          return true;
        };

        return service;
      }]);
})();