/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentCommunitySelect', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-community-select',
        replace: true,
        scope: {
          formObj: '=',
          formScope: '=',
          model: '=',
          defaultPlaceholder: '@',
          changeFunction: '=?',
          isDisabled: '=?',
          selectName: '=?',
          selectedRegion: '=?',
          source: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [
          '$scope',
          'CommunitiesListDataService',
          function(
            $scope,
            CommunitiesListDataService
          ) {
            var self = this;

            if (!self.defaultPlaceholder) {
              self.defaultPlaceholder = 'Select A Community*';
            }

            if (typeof self.changeFunction === 'undefined') {
              self.changeFunction = function() {};
            }
            /*
            if (typeof self.loadFunction === 'undefined') {
              self.changeFunction = function() {};
            }
            */
            if (typeof self.selectName === 'undefined') {
              self.selectName = 'community_select';
            }

            if (typeof self.isDisabled === 'undefined') {
              self.isDisabled = false;
            }

            $scope.$watch(angular.bind(self, function() {
              return self.selectedRegion;
            }), function(newVal, oldVal) {
              if (newVal === oldVal) {return;}
              loadCommunities(newVal);
            }, true);

            var loadCommunities = function(regionID) {
              CommunitiesListDataService
                .getCommunitiesByRegion(regionID)
                .then(function(result) {
                  self.source = result.communities;

                  if (self.source.length) {
                    self.isDisabled = false;
                    if (!self.defaultPlaceholder) {
                      self.defaultPlaceholder = 'SELECT A COMMUNITY';
                    }
                  } else {
                    self.isDisabled = true;
                    self.defaultPlaceholder = 'NO COMMUNTIES IN REGION';
                  }
                });
            };

          }]
      };

    }]);
}());