/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function() {
    'use strict';

    angular.module('app.common')
        .directive('hubspotForm', function() {
            return {
                restrict: 'E',
                scope: {
                    formId: '@?',
                    context: '@?',
                    community: '@?'
                },
                template: '<div id="{{formContainerId}}"></div>',
                controller: ['$scope', '$http', function($scope, $http) {
                    $scope.formContainerId = 'hs-form-container-' + Math.floor(Math.random() * 10000);
                    
                    $scope.$onInit = function() {
                        if ($scope.formId && $scope.context === 'random') {
                            $scope.getRandomFormId();
                        } else {
                            $scope.initializeHubSpotForm($scope.formContainerId, $scope.formId);
                        }
                    };

                    $scope.getRandomFormId = function() {
                        var formIds = $scope.formId.split(',').map(function(id) { return id.trim(); });
                        var randomFormId = formIds[Math.floor(Math.random() * formIds.length)];
                        $scope.initializeHubSpotForm($scope.formContainerId, randomFormId);
                    };

                    $scope.initializeHubSpotForm = function(elemId, formId) {
                        if (typeof hbspt !== 'undefined') {
                            hbspt.forms.create({
                                region: "na1",
                                portalId: "8273304",
                                formId: formId,
                                target: `#${elemId}`,
                                onFormReady: function(form) {
                                    const formElement = form.get(0);

                                    if($scope.community){
                                        const communityField = formElement.querySelector(`[name="community_of_interest_1"`);
                                        if (communityField) {
                                            communityField.value = $scope.community;
                                        } else {
                                            console.warn(`HubSpot Forms: Community name '${$scope.community}' provided, but form field not found on Form ID: ${formId}.`);
                                        }
                                    }

                                    form.find('input[type="text"], input[type="email"], textarea').on('input', function() {
                                        $scope.$emit('formDirtyStateChange', {isDirty: true});
                                    });

                                    form.find('select, input[type="checkbox"], input[type="radio"]').on('change', function() {
                                        $scope.$emit('formDirtyStateChange', {isDirty: true});
                                    });

                                    const labels = formElement.getElementsByTagName('label');
                                    Array.from(labels).forEach((label)=>{
                                        if(!label.classList.contains('hs-form-booleancheckbox-display')){
                                            const labelText = label.textContent;
                                            const inputId = label.htmlFor;
                                            const inputElem = formElement.querySelector(`#${inputId}`);
                                            if(inputElem){
                                            inputElem.placeholder = labelText;
                                            label.style.display = 'none';
                                            }
                                        }
                                    });
                                },
                                onFormSubmit: function(){
                                
                                    const emailInputValue = document.getElementById(`email-${formId}`).value;

                                    if (emailInputValue) {
                                        $http({
                                            method: 'POST',
                                            url: '/api/hubspot-crm-data/contact-id',
                                            data: {
                                                email: emailInputValue,
                                            }
                                        }).then(function successCallback(response) {
                                            window.dataLayer = window.dataLayer || [];
                                            window.dataLayer.push({
                                                'event': 'Form Submission',
                                                'user_id': response.data.contactId
                                            });
                                        }, function errorCallback(response) {
                                            console.error('Error:', response);
                                        });
                                    } else {
                                        console.warn('Email address not found.');
                                    }
                                }   
                            });
                        }
                    };
                }],
                link: function(scope, element, attrs) {
                    scope.$onInit();
                }
            };
        });
}());