/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('toggleDropdown', ['DeviceService',
      function(DeviceService) {
        return {
          restrict: 'A',
          link: function(scope, elem, attrs) {

            $(window).on('load resize', function(){
              if(DeviceService.touch()){
                elem.addClass('is-touch')
                .find('> a').on('click', function(){
                  if(attrs.dirtyCheck != 'true'){
                    elem.toggleClass('open')
                    .siblings().removeClass('open');
                  }
                });
              }else{
                var triggerIn = 'mouseenter';
                if('clickOnly' in attrs){
                  triggerIn = 'click';
                }
                elem.removeClass('is-touch')
                .on(triggerIn, function(){
                  elem.addClass('open')
                  .siblings().removeClass('open');
                })
                .on('mouseleave', function(){
                  elem.removeClass('open');
                })
              }
            });

            scope.$on('mobile-touch', function(event,prop){
              var num = $(prop.target).closest('.is-touch').length;
              if(!num){
                elem.removeClass('open');
              }
            });

          }
        };
      }
    ]);
}());
