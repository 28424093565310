(function(){
	'use strict';
	angular.module('app.designoverview',[])
	.controller('designOverviewCtrl', [
		'$scope',
		'$timeout',
		'slickConfigService',
		'CommunitiesListDataService',
		'DataService',
	function(
		$scope,
		$timeout,
		slickConfigService,
		CommunitiesListDataService,
		DataService){

		$scope.quizResult = [0,0,0];

		$scope.homes = [
			{
				image: '/images/site/microsite/thumb-home.jpg',
				title: '',
				type: 'Single Family Home',
				elevations: 3
			},
			{
				image: '/images/site/microsite/thumb-home.jpg',
				title: '',
				type: 'Single Family Home',
				elevations: 3
			},
			{
				image: '/images/site/microsite/thumb-home.jpg',
				title: '',
				type: 'Single Family Home',
				elevations: 3
			},
			{
				image: '/images/site/microsite/thumb-home.jpg',
				title: '',
				type: 'Single Family Home',
				elevations: 3
			},
			{
				image: '/images/site/microsite/thumb-home.jpg',
				title: '',
				type: 'Single Family Home',
				elevations: 3
			},
			{
				image: '/images/site/microsite/thumb-home.jpg',
				title: '',
				type: 'Single Family Home',
				elevations: 3
			},
			{
				image: '/images/site/microsite/thumb-home.jpg',
				title: '',
				type: 'Single Family Home',
				elevations: 3
			},
			{
				image: '/images/site/microsite/thumb-home.jpg',
				title: '',
				type: 'Single Family Home',
				elevations: 3
			}
		];

		DataService('/design/home-collections', 'collections')
			.promise
			.then(function(collections){
				angular.forEach(collections, function(obj){

					if (obj.seo_name == 'grand-estates-custom-collection') {
						$scope.grandEstate = obj.plans;
					}

					if (obj.seo_name == 'maple-street-collection') {
						$scope.mapleStreet = obj.plans;
					}

					if (obj.seo_name == 'masterpiece-collection') {
						$scope.masterPiece = obj.plans;
					}

					if (obj.seo_name == 'designer-collection') {
						$scope.designer = obj.plans;
					}

				});
		});

		$scope.results = _.chunk($scope.homes, 4);

		$scope.error = "";
		$scope.qIndex = 0;
		$scope.activePartner = null;
		$scope.slickSettings = slickConfigService.baseConfig(1);

		$scope.quizTitle = [
			'What\'s your <span>ideal vacation?</span>',
			'Which vehicle <span>appeals to you most?</span>',
			'How do you like to <span>spend your weekends?</span>'
		];

		$scope.partners = [
			{
				name: 'FISCHER DESIGN',
				longName: 'THE FISCHER DESIGN TEAM',
				image: '/images/site/design/team.jpg',
				bios: [
					{
						name: 'William L. Kaufholz, AIA',
						title: 'Director of Architecture, Fischer Homes',
						image: '/images/site/design/william-Kaufholz.jpg',
						info: 'Bill brings over 35 years of experience to Fischer Homes. He has both a Bachelors of Architecture and Bachelors of Environmental Design from The University of Oklahoma. Bill is a member of the American Institute of Architects and the National Council of Architectural Registration Board.',
						subTitle: 'What Inspires Bill',
						subInfo: 'I find walking through early 20th century neighborhoods provides me a wealth of inspiration. Items that I usually carefully take note of are the scale and massing of the homes, expression of the entry, integration of the garage with the home, material usage and detailing, window sizes and placement and the usage of color.'
					},
					{
						name: 'Daniel O’Connell',
						title: 'Design Manager, Fischer Homes',
						image: '/images/site/design/daniel-oconnel.jpg',
						info: 'Dan has over 20 years of experience with Fischer Homes in both Single Family Residential Design and Construction. He received a Bachelor of Science in Architecture and Bachelor of Arts in Psychology from the University of Cincinnati.',
						subTitle: 'What Inspires Dan',
						subInfo: ' Past experience, Historical Homes, Established Cincinnati/Local Neighborhoods, I enjoy looking at other people’s homes. It also helpful sometime to identify and fix the problem someone else didn’t figure out. Out of Market visits are also great for new and different ideas, but a lot of the time harder to translate and apply to local conditions.'
					},
					{
						name: 'Stephen Glaser',
						title: 'Fischer Homes Architectural Designer',
						image: '/images/site/design/stephen_glaser.jpg',
						info: 'Steve has over 20 years of experience is Residential Design and Architecture, focusing on mid-level to high-end single family product complimented with active adult, entry level, and multi-family design. He holds a Bachelor of Science in Architecture from the University of Detroit.',
						subTitle: 'What Inspires Steve',
						subInfo: 'My Grandfather, Ken Smith is my inspiration. He was an Engineering Professor at the University of Detroit and along with my Grandmother, they raised twelve kids. His vast knowledge, intelligence, and compassion has been a role model for many people. He continues to be an inspiration to me on a professional and personal level.'
					}
				]
			},
			{
				name: 'BSB DESIGN',
				image: '/images/site/design/bsb-design.jpg',
				info: 'BSB Design was founded in 1966 and has received hundreds of regional and national awards for innovative design, and Better Homes & Gardens Magazine has called the firm "America\'s Leading Family Home Architect".',
				portfolio:[
					'/images/site/design/bsb-image-001.jpg',
					'/images/site/design/bsb-image-002.jpg',
					'/images/site/design/bsb-image-003.jpg'
				]
			},
			{
				name: 'CARSON LOONEY',
				image: '/images/site/design/j-carson-looney.jpg',
				info: 'Based in Memphis, Tennessee, Carson is a Founding Principal in charge of Residential Architecture with LRK Inc. Carson\'s designs focus on proper proportion, scale and detail, resulting in homes with a timeless, regional character. He is best known for solution-oriented design features.',
				portfolio:[
					'/images/site/design/carson-image-001.jpg',
					'/images/site/design/carson-image-002.jpg',
					'/images/site/design/carson-image-003.jpg'
				]
			},
			{
				name: 'DOMINICK TRINGALI',
				image: '/images/site/design/dominick-tringali.jpg',
				info: 'Dominick Tringali believes that architecture should feel both old and new, with timeless qualities that he calls the "essence of design". Dominick is President and CEO of Dominick Tringali Architects of Detroit, Michigan and has earned numerous national honors.',
				portfolio:[
					'/images/site/design/dominick-image-001.jpg',
					'/images/site/design/dominick-image-002.jpg',
					'/images/site/design/dominick-image-003.jpg'
				]
			}
		];

		$scope.setScore = function(index,score){
			$scope.quizResult[index] = score;
		};

		$scope.next = function(){
			if ($scope.quizResult[$scope.qIndex] == 0) {
				$scope.error = "please select one before moving on";
			} else {
				$scope.error = "";
				$scope.qIndex++;
			}

			if ($scope.qIndex == 3) {
				$scope.qIndex = "last";

				var pattern = $scope.quizResult.toString();

				$scope.quizAnswers = [
					{
						group: ['1,1,1','2,1,1','3,1,2'],
						cards: $scope.grandEstate.slice(0,3)
					},
					{
						group: ['1,1,2','2,1,2','3,2,3'],
						cards: $scope.mapleStreet.slice(0,3)
					},
					{
						group: ['1,1,3','2,1,3','3,3,2'],
						cards: $scope.masterPiece.slice(0,3)
					},
					{
						group: ['1,2,1','2,2,2','3,1,1'],
						cards: $scope.designer.slice(0,3)
					},
					{
						group: ['1,2,2','2,1,1','3,3,1'],
						cards: $scope.grandEstate.slice(3,6)
					},
					{
						group: ['1,2,3','2,3,3','3,1,3'],
						cards: $scope.mapleStreet.slice(3,6)
					},
					{
						group: ['1,3,1','2,3,1','3,2,1'],
						cards: $scope.masterPiece.slice(3,6)
					},
					{
						group: ['1,3,2','2,3,2','3,2,2'],
						cards: $scope.designer.slice(3,6)
					},
					{
						group: ['1,3,3','2,2,3','3,3,3'],
						cards: $scope.mapleStreet.slice(6,9)
					}
				];

				//parse results
				angular.forEach($scope.quizAnswers, function(obj){

					for (var i = 0; i < obj.group.length; i++) {
						if (pattern == obj.group[i]) {
							$scope.plans = obj.cards;
							break;
						}
					}
				});

				//implement results here
				$scope.slickSettings.enabled = false;

				$timeout(function(){
					$scope.slickSettings.enabled = true;
				},0);
			}
		};

		$scope.reset = function(){
			$scope.quizResult = [0,0,0];
			$scope.qIndex = 0;
		};

		$scope.toggleInlineModal = function(idx){
			$scope.inlineModal = 'show';
			$scope.activePartner = $scope.partners[idx];
		};

	}
	]);
}());