(function() {
  'use strict';

  angular.module('app.common')
    .filter('optionVal', [function() {
      return function(value, param) {
        console.log(value);
        return value;
      }
    }])
    .filter('cat', [function() {

      return function(value, param) {
        return value + param;
      };

    }]);
}());
