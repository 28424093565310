/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('buttonDropdownToggle', ['$timeout', '$rootScope','scrollMagic', function($timeout, $rootScope, scrollMagic) {

      return {
        templateUrl: '/common/buttonDropdownToggle',
        restrict: 'E',
        scope: {
          title: '=',
          items: '=',
          ngModel: '=',
          disabled: '='
        },
        replace: true,
        link: function(scope, element, attrs) {
          var el = element[0];
          scope.isActive = false;
          scope.selectedItem = {
            value: 0
          };

          if(scope.items && scope.items.length > 0) {
            scope.placeholder = scope.items[0].name;
          } else {
            scope.placeHolder = 'Select an option';
          }

          // $(el).on('click', '.dropdown-toggle', function() {
          //   $(this).toggleClass('in');
          //   $(this).next().toggleClass('open');
          // });

          //toggles dropdown direction for subnav on homepage
          if('dirToggle' in attrs){
            scrollMagic.triggerClass('#subnav .dropdown-menu', '#subnav', 'dropdown-bottom', 'onEnter', $(window).height() * 0.5);
          }

          //swap button classes
          if('classname' in attrs){
            $(element).find('.dropdown-toggle').toggleClass('white-outline ' + attrs.classname);
          }

          scope.$watch('ngModel', function(newValue, oldValue) {
            if (newValue !== oldValue) {
              angular.forEach(scope.items, function(item) {
                if (item.value === newValue) {
                  scope.selectedItem.value = item.value;
                  scope.placeholder = item.name;
                }
              });
            }
          });

          var handleOuterClick = function(e) {
            //e.preventDefault();

            var isOuterClick = false,
              children = el.children,
              srcElement = e.srcElement,
              i, l;

            if (scope.isActive) {
              if (srcElement !== el && $(el).has(srcElement).length === 0) {
                isOuterClick = true;
                for (i = 0, l = children.length; i < l; i += 1) {
                  if (srcElement === children[i]) {
                    isOuterClick = false;
                  }
                }
              }
              if (isOuterClick) {
                $timeout(function() {
                  scope.toggle();
                }, 0);
              }
            }
          };

          scope.toggle = function() {
            if (!scope.disabled) {
              scope.isActive = !scope.isActive;
              if (scope.isActive === true) {
                $timeout(function() {
                  window.addEventListener('click', handleOuterClick);
                }, 100);
              } else {
                window.removeEventListener('click', handleOuterClick);
              }
            }
          };

          scope.selectItem = function(item) {
            scope.selectedItem.value = scope.items.indexOf(item);
            scope.placeholder = item.name;

            $rootScope.$broadcast('selectedOption', {
              name: item.name,
              value: item.value
            });

            scope.ngModel = item.value;

            scope.toggle();
          };
        }
      };

    }]);
}());
