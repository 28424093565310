/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentCommunity', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-community',
        replace: true,
        scope: {
          formObj: '=',
          formScope: '=',
          isDisabled: '=?',
          isRequired: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          var self = this;

          if (typeof self.isDisabled === 'undefined') {
            self.isDisabled = false;
          }

          if (typeof self.isRequired === 'undefined') {
            self.isRequired = false;
          }

        }]
      };

    }]);
}());