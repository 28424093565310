/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';
  angular.module('app.common')
    .filter('telFormat', [function() {
      return function(tel, type) {

        if (!tel) {
          return '';
        }

        var city, number, ext,
          value = tel.toString().trim().replace(/^\+|,/, '');

        if (typeof type === 'undefined' && value.match(/[^0-9]/)) {
          return tel;
        }

        value = value.replace(/-/g, '');

        switch (value.length) {
          case 10: // +1PPP####### -> C (PPP) ###-####
            city = value.slice(0, 3);
            number = value.slice(3);
            ext = '';
            break;

          case 11: // +CPPP####### -> CCC (PP) ###-####
            city = value.slice(1, 4);
            number = value.slice(4);
            ext = '';
            break;

          case 12: // +CCCPP####### -> CCC (PP) ###-####
            city = value.slice(3, 5);
            number = value.slice(5);
            ext = '';
            break;

          case 13: // INCLUDE EXTENSION
            city = value.slice(0, 3);
            number = value.slice(3, -3);
            ext = ' ext.' + value.slice(-3);
            break;

          default:
            return tel;
        }

        if(type === "()"){
          type = "-";
          city = "("+city+") ";
        } else {
          city = city + type;
        }

        number = number.slice(0, 3) + type + number.slice(3);

        return (city + number + ext).trim();
      };
    }]);
}());
