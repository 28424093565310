/*jslint white: true, nomen: true */
/*global $, angular */
/*global communitiesListDataServiceWash, communitiesListDataServiceApartmentsWash */
/*global floorplansListDataServiceWash, communitiesListDataServiceStoragesWash */
(function() {
  'use strict';

  angular.module('app.common')

    .service('CommunitiesListDataService', ['$http', '$q', '$filter',
      function($http, $q, $filter) {
        var communitiesListDataServiceResultFailMessageText = 'No communities found',
          homesListDataServiceResultFailMessageText = 'No homes found',
          data = {
            regionsPromise: null,
            regionPromise: null,
            communitiesPromise: null,
            communityPromise: null,
            floorplansPromise: null,
            residencesPromise: null,
            residencePromise: null,
            homesPromise: null,
            homePromise: null,
            collectionsPromise: null,
          },

          getRegions = function() {
            if (data.regionsPromise === null) {
              data.regionsPromise = $http.get('/api/region', {
                headers: {
                  'Cache-Control': 'no-cache'
                },
                cache: true
              }).then(regionsListDataServiceWash);
            }

            return data.regionsPromise;
          },

          formatRegion = function(region) {
            return region;
          },

          getRegion = function() {
            if (data.regionPromise === null) {
              data.regionPromise = $http.get('/api/region/' + regionId, {
                cache: true
              }).then(regionsListDataServiceWash);
            }

            return data.regionPromise;
          },

          regionsListDataServiceWash = function(result) {
            var successResult = {},
              failResult = {
                status: 'failure',
                regions: [],
                errors: communitiesListDataServiceResultFailMessageText
              };

            if (result === undefined || result.data === undefined || result.data.status === undefined) {
              return failResult;
            }

            if (result.data.status === 'failure') {
              return failResult;
            }

            successResult = {
              status: result.data.status,
              regions: result.data.regions,
              errors: 'none'
            };

            if (result.data.regions) {
              angular.forEach(result.data.regions, function(region) {
                formatRegion(region);
              });
              successResult.regions = result.data.regions;
            } else if (result.data.region) {
              successResult.region = formatRegion(result.data.region);
            }

            return successResult;
          },

          getCommunities = function() {
            if (data.communitiesPromise === null) {
              data.communitiesPromise = $http.get('/api/community', {
                headers: {
                  'Cache-Control': 'no-cache'
                },
                cache: true
              }).then(communitiesListDataServiceWash);
            }

            return data.communitiesPromise;
          },

          getCommunitiesByRegion = function(regionID) {
            data.communitiesPromise = $http.get('/api/community', {
              headers: {
                'Cache-Control': 'no-cache'
              },
              params: {region_id: regionID},
              cache: true
            }).then(communitiesListDataServiceWash);

            return data.communitiesPromise;
          },

          getCommunity = function(communityId) {
            if (data.communityPromise === null) {
              data.communityPromise = $http.get('/api/community/' + communityId, {
                cache: true
              }).then(communitiesListDataServiceWash);
            }

            return data.communityPromise;
          },

          formatCommunity = function(community) {
            var communityThubnails,
              communityItems;

            community.longitude = parseFloat(community.longitude);
            community.latitude = parseFloat(community.latitude);
            angular.forEach(community.assets, function(asset) {
              asset.type = 'single-family';
            });

            communityThubnails = getCommunityThumbnails(community);
            community.image_thumb = communityThubnails.imageFull;
            community.image_full = communityThubnails.imageFull;

            communityItems = getCommunityItems(community);
            community.beds = communityItems.beds;
            community.baths = communityItems.baths;
            community.sqft = communityItems.sqft;

            community.collectionName = getCommunityCollectionName(community);

            angular.forEach(community.residences, function(residence) {
              residence.favoriteableType = 'residence';
            });

            community.favoriteableType = 'community';

            return community;
          },

          getCommunityThumbnails = function(community) {
            var tempThumb,
              tempFull,
              result = {
                imageThumb: "/images/site/full-interest-form/community-1.jpg",
                imageFull: "/images/site/full-interest-form/community-1.jpg"
              };

            if (typeof community.assets !== 'undefined' && community.assets.length > 0) {
              tempThumb = community.assets[0].image.small;
              tempThumb = tempThumb.replace("(", "%28");
              tempThumb = tempThumb.replace(")", "%29");
              result.imageThumb = tempThumb;

              tempFull = community.assets[0].image.large;
              tempFull = tempFull.replace("(", "%28");
              tempFull = tempFull.replace(")", "%29");
              result.imageFull = tempFull;
            }

            return result;
          },

          getCommunityItems = function(community) {
            var tempBathsLow,
              tempBathsHigh,
              tempBedsLow,
              tempBedsHigh,
              tempSqftLow,
              tempSqftHigh,
              result = {
                baths: '',
                beds: ''
              };

            tempBathsLow = parseFloat(community.bath_low);
            if (tempBathsLow % 1 === 0) {
              tempBathsLow = Math.floor(tempBathsLow);
            }

            tempBathsHigh = parseFloat(community.bath_high);
            if (tempBathsHigh % 1 === 0) {
              tempBathsHigh = Math.floor(tempBathsHigh);
            }

            tempBedsLow = parseFloat(community.bed_low);
            if (tempBedsLow % 1 === 0) {
              tempBedsLow = Math.floor(tempBedsLow);
            }

            tempBedsHigh = parseFloat(community.bed_high);
            if (tempBedsHigh % 1 === 0) {
              tempBedsHigh = Math.floor(tempBedsHigh);
            }

            tempSqftLow = parseInt(community.sqft_low);
            tempSqftHigh = parseInt(community.sqft_high);

            if (tempBathsLow === tempBathsHigh) {
              result.baths = tempBathsLow;
            } else {
              result.baths = tempBathsLow + ' - ' + tempBathsHigh;
            }

            if (tempBedsLow === tempBedsHigh) {
              result.beds = tempBedsLow;
            } else {
              result.beds = tempBedsLow + ' - ' + tempBedsHigh;
            }

            if (tempSqftLow === tempSqftHigh) {
              result.sqft = $filter('number')(tempSqftLow);
            } else {
              result.sqft = $filter('number')(tempSqftLow) + ' - ' + $filter('number')(tempSqftHigh);
            }

            return result;
          },

          getCommunityCollectionName = function(community) {
            var result = false;
            if (community.collection && typeof community.collection.name !== 'undefined') {
              result = community.collection.name;
            }

            return result;
          },

          communitiesListDataServiceWash = function(result) {
            var communitiesAssets = [],
              successResult = {},
              failResult = {
                status: 'failure',
                communities: [],
                communitiesAssets: [],
                errors: communitiesListDataServiceResultFailMessageText
              };

            if (result === undefined || result.data === undefined || result.data.status === undefined) {
              return failResult;
            }

            if (result.data.status === 'failure') {
              return failResult;
            }

            if (result.data.communities) {
              angular.forEach(result.data.communities, function(community) {
                formatCommunity(community);
              });

              successResult.communities = result.data.communities;
              successResult.communitiesAssets = communitiesAssets;
            } else if (result.data.community) {
              successResult.community = formatCommunity(result.data.community);
              successResult.community.communityType = 'community';
            }

            successResult.status = result.data.status;
            successResult.errors = 'none';

            return successResult;
          },

          getFloorplans = function() {
            if (data.floorplansPromise === null) {
              data.floorplansPromise = $http.get('/api/plan', {
                headers: {
                  'Cache-Control': 'no-cache'
                },
                cache: true
              }).then(floorplansListDataServiceWash);
            }

            return data.floorplansPromise;
          },

          formatFloorplan = function(floorplan) {
            floorplan.favoriteableType = 'plan';

            return floorplan;
          },

          floorplansListDataServiceWash = function(result) {
            var successResult = {},
              failResult = {
                status: 'failure',
                floorplans: [],
                errors: communitiesListDataServiceResultFailMessageText
              };

            if (result === undefined || result.data === undefined || result.data.status === undefined) {
              return failResult;
            }

            if (result.data.status === 'failure') {
              return failResult;
            }

            successResult = {
              status: result.data.status,
              floorplans: result.data.plans,
              errors: 'none'
            };

            if (result.data.plans) {
              angular.forEach(result.data.plans, function(plan) {
                formatFloorplan(plan);
              });
              successResult.plans = result.data.plans;
            } else if (result.data.plan) {
              successResult.plan = formatFloorplan(result.data.plan);
            }

            return successResult;
          },

          getResidences = function() {
            if (data.residencesPromise === null) {
              data.residencesPromise = $http.get('/api/residence', {
                headers: {
                  'Cache-Control': 'no-cache'
                },
                cache: true
              }).then(residencesListDataServiceWash);
            }

            return data.residencesPromise;
          },

          getResidence = function(residenceId) {
            if (data.residencePromise === null) {
              data.residencePromise = $http.get('/api/residence/' + residenceId, {
                cache: true
              }).then(residencesListDataServiceWash);
            }

            return data.residencePromise;
          },

          formatResidence = function(residence) {
            residence.favoriteableType = 'residence';
            if (residence.plan) {
              if (residence.plan.floorplans) {
                angular.forEach(residence.plan.floorplans, function(item) {
                  residence.floorplans[residence.floorplans.length] = item;
                });
                residence.floorplans.concat(residence.plan.floorplans);
              }
            }

            return residence;
          },

          residencesListDataServiceWash = function(result) {
            var successResult = {},
              failResult = {
                status: 'failure',
                residences: [],
                errors: communitiesListDataServiceResultFailMessageText
              };

            if (result === undefined || result.data === undefined || result.data.status === undefined) {
              return failResult;
            }

            if (result.data.status === 'failure') {
              return failResult;
            }

            successResult = {
              status: result.data.status,
              errors: 'none'
            };

            if (result.data.residences) {
              angular.forEach(result.data.residences, function(residence) {
                formatResidence(residence);
              });
              successResult.residences = result.data.residences;
            } else if (result.data.residence) {
              successResult.residence = formatResidence(result.data.residence);
            }

            return successResult;
          },

          getApartmentResidence = function(residenceId) {
            if (data.apartmentResidencePromise === null) {
              data.apartmentResidencePromise = $http.get('/api/apartment-residence/' + residenceId, {
                cache: true
              }).then(apartmentResidencesListDataServiceWash);
            }

            return data.apartmentResidencePromise;
          },

          formatApartmentResidence = function(residence) {
            residence.favoriteableType = 'apartmentResidence';

            return residence;
          },

          apartmentResidencesListDataServiceWash = function(result) {
            var successResult = {},
              failResult = {
                status: 'failure',
                residences: [],
                errors: communitiesListDataServiceResultFailMessageText
              };

            if (result === undefined || result.data === undefined || result.data.status === undefined) {
              return failResult;
            }

            if (result.data.status === 'failure') {
              return failResult;
            }

            successResult = {
              status: result.data.status,
              errors: 'none'
            };

            if (result.data.apartmentResidences) {
              angular.forEach(result.data.apartmentResidences, function(apartmentResidence) {
                formatApartmentResidence(apartmentResidence);
              });
              successResult.apartmentResidences = result.data.apartmentResidences;
            } else if (result.data.apartmentResidence) {
              successResult.apartmentResidence = formatApartmentResidence(result.data.apartmentResidence);
            }

            return successResult;
          },

          getHomes = function() {
            if (data.homesPromise === null) {
              data.homesPromise = $http.get('/api/home', {
                headers: {
                  'Cache-Control': 'no-cache',
                },
                cache: true
              }).then(homesListDataServiceWash);
            }

            return data.homesPromise;
          },

          getHome = function(homeId) {
            if (data.homePromise === null) {
              data.homePromise = $http.get('/api/home/' + homeId, {
                cache: true
              }).then(homesListDataServiceWash);
            }

            return data.homePromise;
          },

          formatHome = function(home) {
            home.longitude = parseFloat(home.longitude);
            home.latitude = parseFloat(home.latitude);
            angular.forEach(home.assets, function(asset) {
              asset.type = 'single-family';
            });

            if (home.community) {
              home.community = formatCommunity(home.community);
            }

            return home;
          },

          homesListDataServiceWash = function(result) {
            var homesAssets = [],
              successResult = {},
              failResult = {
                status: 'failure',
                homes: [],
                homesAssets: [],
                errors: homesListDataServiceResultFailMessageText
              };

            if (result === undefined || result.data === undefined || result.data.status === undefined) {
              return failResult;
            }

            if (result.data.status === 'failure') {
              return failResult;
            }

            if (result.data.homes) {
              angular.forEach(result.data.homes, function(home) {
                formatHome(home);
              });

              successResult.homes = result.data.homes;
              successResult.homesAssets = homesAssets;
            } else if (result.data.home) {
              successResult.home = formatHome(result.data.home);
            }

            successResult.status = result.data.status;
            successResult.errors = 'none';

            return successResult;
          },

          getCollections = function() {
            if (data.collectionsPromise === null) {
              data.collectionsPromise = $http.get('/api/collection', {
                headers: {
                  'Cache-Control': 'no-cache'
                },
                cache: true
              }).then(collectionsListDataServiceWash);
            }

            return data.collectionsPromise;
          },

          formatCollection = function(collection) {
            return collection;
          },

          collectionsListDataServiceWash = function(result) {
            var successResult = {},
              failResult = {
                status: 'failure',
                collections: [],
                errors: communitiesListDataServiceResultFailMessageText
              };

            if (result === undefined || result.data === undefined || result.data.status === undefined) {
              return failResult;
            }

            if (result.data.status === 'failure') {
              return failResult;
            }

            successResult = {
              status: result.data.status,
              collections: result.data.collections,
              errors: 'none'
            };

            if (result.data.collections) {
              angular.forEach(result.data.collections, function(collection) {
                formatRegion(collection);
              });
              successResult.collections = result.data.collections;
            } else if (result.data.collection) {
              successResult.collection = formatRegion(result.data.collection);
            }

            return successResult;
          };

        return {
          getRegions: getRegions,
          getRegion: getRegion,
          getCommunities: getCommunities,
          getCommunitiesByRegion: getCommunitiesByRegion,
          getCommunity: getCommunity,
          getFloorplans: getFloorplans,
          getResidences: getResidences,
          getResidence: getResidence,
          getApartmentResidence: getApartmentResidence,
          getHomes: getHomes,
          getHome: getHome,
          getCollections: getCollections,
        };
      }]);
}());
