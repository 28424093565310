/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('focusHover', [function() {
      return {
        restrict: 'A',
        link: function(scope, elem, attrs) {

          elem.hover(function() {
            elem.addClass('active').parent().addClass('hovered');
          }, function() {
            elem.removeClass('active').parent().removeClass('hovered');
          })

        }
      };
    }]);
}());
