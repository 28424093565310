$(function(){
	'use strict';
	angular.module('app.whychoosefischer')
  .controller('whyChooseFischerCtrl',['$scope',
		function($scope){

    $scope.whyChooseFischerAnalyticsConfig = {
      googleAnalytics: {
        pageViewUrl: '/ajax-goals/qsu-why-choose-fischer'
      }
    };

	}]);
}());