/*
global
  down_payment,
  property_tax,
  home_insurance,
  interest_rate,
  interest_rate_adjustment,
  pmi
*/
(function() {
  "use strict";

  angular.module("app.common")
    .directive("mortgageCalculator", ['mortageCalculatorService', function(mortageCalculatorService) {
      return {
        restrict: 'E',
        transclude: true,
        scope: {
          setCalc: '&calc'
        },
        templateUrl: '/components/mortgage-calculator',
        link: function(scope) {

          var round = function(number, precision) {
            var factor = Math.pow(10, precision);
            var tempNumber = number * factor;
            var roundedTempNumber = Math.round(tempNumber);
            return roundedTempNumber / factor;
          };

          //missing input fields
          scope.salesPrice = mortageCalculatorService.getPrice();
          scope.downPayment = (scope.salesPrice * (down_payment/100)).toFixed(2);
          scope.EstimatedYearlyPropertyTaxes = property_tax;
          scope.EstimatedMonthlyCommunityFees = 0;
          scope.EstimatedMonthlyHomeownerFees = home_insurance;
          scope.interestBase = round(parseFloat(interest_rate), 3);
          scope.interestRate = round(parseFloat(interest_rate) + parseFloat(interest_rate_adjustment), 3);
          scope.term = 30;
          scope.pmi = pmi;

          scope.calculate = function() {
            var pmiPayment = 0, propertyTaxPayment = 0, homeInsurancePayment = 0;

            var principle = scope.salesPrice - scope.downPayment;
            scope.principle = principle;

            scope.salePercent = parseFloat((scope.downPayment/scope.salesPrice)*100).toFixed(2);

            var payments = scope.term*12;
            var monthlyInterestRate = (parseFloat(interest_rate) + parseFloat(interest_rate_adjustment)) / 100 / 12;
            var basePayment = principle * monthlyInterestRate * (Math.pow(1 + monthlyInterestRate, payments)) / (Math.pow(1 + monthlyInterestRate, payments) - 1);

            if (pmi && pmi > 0 && scope.salePercent < 20) {
              pmiPayment = principle * (pmi/100/12);
            }

            if (scope.EstimatedYearlyPropertyTaxes && scope.EstimatedYearlyPropertyTaxes > 0) {
              propertyTaxPayment = scope.salesPrice * (scope.EstimatedYearlyPropertyTaxes/100/12);
            }

            if (scope.EstimatedMonthlyHomeownerFees && scope.EstimatedMonthlyHomeownerFees > 0) {
              homeInsurancePayment = scope.salesPrice * (scope.EstimatedMonthlyHomeownerFees/100/12);
            }

            scope.totalPayment = basePayment + pmiPayment + propertyTaxPayment + homeInsurancePayment;

            scope.setCalc({
              price: scope.totalPayment
            });

            return scope.totalPayment;
          };

          scope.calculate();

        }
      };
    }]);
}());
