/* jslint white: true, nomen: true */
/* global angular */
(function () {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentTimeSelect', [function () {
      return {
        restrict: 'E',
        templateUrl: 'form-component-time-select',
        replace: true,
        scope: {
          defaultPlaceholder: '@',
          model: '=',
          formObj: '=',
          formScope: '=',
          selectName: '=?',
          isRequired: '=?',
          formOptions: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function () {},
        controller: [function () {
          var self = this;

          if (!self.defaultPlaceholder) {
            self.defaultPlaceholder = 'Choose A Time';
          }
          if (!self.selectName) {
            self.selectName = 'time';
          }

          if (typeof self.isRequired === 'undefined') {
            self.isRequired = true;
          }
        }]
      };
    }]);
}());
