$(function(){
	'Use Strict';
	angular.module('app.lifestyle').controller('lifestyleCtrl',['$scope', '$location','$timeout', 'dummyDataService',
		function($scope, $location, $timeout, dummyDataService){

		$scope.activeStep = 0;
		$scope.activeTabIndex = 0;
		$scope.transition = false;

		$scope.designProcess = dummyDataService.designProcess();
		$scope.designCenters = dummyDataService.designCenters();

		$scope.slides = [
			'/images/site/lifestyle/hero-slide-bkg.jpg?v=2'
		];


		$scope.setLocation = function(e, page){
			e.preventDefault();
			$location.path('/design/lifestyle/'+page).replace();
		}

		$scope.setStep = function(num){
			$scope.transition = true;
			$timeout(function(){
				$scope.activeStep = num;
				$scope.transition = false;
			},300);
		}

		$scope.setActiveTab = function(num){
			$scope.activeTabIndex = num;
		}

    $scope.lifestyleAnalyticsConfig = {
      googleAnalytics: {
        pageViewUrl: '/ajax-goals/qsu-lifestyle'
      }
    };

	}])
}());