/* global interest_rate, interest_rate_adjustment, down_payment, pmi */
/* global property_tax, home_insurance, disclaimer, angular, $ */
(function () {
  'use strict';

  angular
    .module('app.floorplandetail')
    .controller('floorplanDetailCtrl', [
      '$scope',
      'mortageCalculatorService',
      'slickConfigService',
      '$filter',
      '$rootScope',
      '$window',
      'DataService',
      'regionStorageFactory',
      'DeviceService',
      '$timeout',
      function (
        $scope,
        mortageCalculatorService,
        slickConfigService,
        $filter,
        $rootScope,
        $window,
        DataService,
        regionStorageFactory,
        DeviceService,
        $timeout
      ) {
        $scope.slickFloorplan = slickConfigService.baseConfig(1);
        slickConfigService.triggerClick($scope, '_self');
        $scope.modalSlide = 0;
        slickConfigService.modalSlideshow($scope);
        $scope.triggerSlick = false;
        $scope.planLoaded = false;
        $scope.images;
        $scope.showZoom = true;
        var loadedOnce = false;

        $(window).on('load resize', function () {
          $scope.imagesLoaded = false;

          var thumbCount = 5;
          var stripCount = 2;

          if (DeviceService.check() == 'iPad') {
            thumbCount = 3;
            if (DeviceService.orientation() == 'portrait') {
              thumbCount = 2;
              stripCount = 1;
            }
          }

          if (DeviceService.check() == 'phone') {
            thumbCount = Math.round($(window).width() * 0.01) - 1;
            stripCount = 1;
          }

          $timeout(function () {
            /*
            if images are loading for the first time, allow floorplanPromise to to init the slideshow or
            else assume the slides are ready and init the slideshow after calculating thumbsize
            */
            $scope.slickStrip = slickConfigService.baseConfig(stripCount);
            slickConfigService.dualSlideshow($scope, thumbCount);

            if (loadedOnce) {
              $scope.imagesLoaded = true;
            }
          }, 300);
        });

        $scope.floorplanPromise = DataService($window.location.href, 'plan')
          .promise
          .then(function (floorplan) {
            $scope.$broadcast('user-selected-floorplan', floorplan);

            // The API picks if .communities will be "Generous" or not
            // floorplan.communities = floorplan.activeCommunitiesGenerousPlanMaster;

            // if floorplan has no communities associated, it will throw following error:
            //    Reduce of empty array with no initial value
            if ($scope.regionId && floorplan.communities.length > 0) {
              var price = floorplan.communities
                .reduce(function (min, c) {
                  if (c.region_id == $scope.regionId) {
                    if (c.price > 0 && c.price < min) {
                      min = c.price;
                    }
                  }
                });

              $scope.setPrice(price);
            }

            floorplan.beds = floorplan.bed_low + '-' + floorplan.bed_high;
            floorplan.baths = floorplan.bath_low + '-' + floorplan.bath_high;
            floorplan.sqft = floorplan.sqft_low + '-' + floorplan.sqft_high;

            $scope.floorplan = floorplan;
            $scope.floorplan.model_homes = (floorplan.model_homes !== undefined) ? floorplan.model_homes : floorplan.modelHomes;

            angular.forEach($scope.floorplan.elevationImages, function (elevationImage) {
              elevationImage.image = elevationImage.img;
              delete elevationImage.img;
            });

            if (DeviceService.check() == 'phone') {
              $scope.images = $scope.floorplan.elevationImages;
            } else {
              $scope.images = $scope.floorplan.videos
                .concat($scope.floorplan.elevationImages
                  .concat($scope.floorplan.assets));
            }

            $scope.planLoaded = true;

            return floorplan;
          }).then(function () {
            $scope.imagesLoaded = true;
            loadedOnce = true;
          });

        // inject images into slick slideshow
        $scope.regionPhone = regionStorageFactory.getRegionPhone() || '1-855-342-2281';

        $scope.regionId = regionStorageFactory.get().id;

        $scope.$watch('regionId', function (is, was) {
          if (is == was) return;

          $scope.floorplanPromise.then(function () {

          });
        });

        $scope.regionFilter = function (community) {
          // Dont' fail if there is some reason we don't have a Region selected
          if (!$scope.regionId) {
            return community;
          }

          // Support "Greater Cincinnati" communities
          if ($scope.regionId == 1) {
            if (community.region_id == 1 || Math.floor(community.region_id / 10) == 1) {
              return community;
            }
          }

          // Support normal communities
          return (community.region_id == $scope.regionId) ? community : false;
        };

        $scope.setPrice = function (price) {
          $scope.floorplanPromise.then(function () {
            $scope.floorplan.price = price;
            mortageCalculatorService.setPrice(price);
            $scope.floorplan.payment = calculatePayment(price);
          });
        };

        $scope.disclaimer = disclaimer;

        $scope.getCalc = function (price) {
          $scope.monthlyPayments = price;
        };

        $scope.setSlide = function (num) {
          $scope.modalSlide = num;
        };

        var calculatePayment = function (price) {
          var pmiPayment = 0;
          var propertyTaxPayment = 0;
          var homeInsurancePayment = 0;

          var principle = price - (price * (down_payment / 100));

          var payments = 30 * 12;
          var monthlyInterestRate = (parseFloat(interest_rate) + parseFloat(interest_rate_adjustment)) / 100 / 12;
          var basePayment = principle * monthlyInterestRate * (Math.pow(1 + monthlyInterestRate, payments)) / (Math.pow(1 + monthlyInterestRate, payments) - 1);

          if (pmi && pmi > 0) {
            pmiPayment = principle * (pmi / 100 / 12);
          }

          if (property_tax && property_tax > 0) {
            propertyTaxPayment = price * (property_tax / 100 / 12);
          }

          if (home_insurance && home_insurance > 0) {
            homeInsurancePayment = price * (home_insurance / 100 / 12);
          }

          return basePayment + pmiPayment + propertyTaxPayment + homeInsurancePayment;
        };

        $scope.floorplanDetailAnalyticsConfig = {
          googleAnalytics: {
            pageViewUrl: '/ajax-goals/qsu-floorplan-detail'
          }
        };


        /**
         * @todo Identify the scope and impact of migrating this to a dynamic
         * list, then migrate to a dynamically driven list of regions.
         */
        $scope.allRegions = {
          '47': { order: 1, id: 47, name: 'Atlanta, GA', url: 'atlanta/georgia' },
          '22': { order: 2, id: 22, name: 'Columbus, OH', url: 'columbus/ohio' },
          '35': { order: 3, id: 35, name: 'Indianapolis, IN', url: 'indianapolis, indiana' },
          '48': { order: 4, id: 48, name: 'Louisville, KY', url: 'louisville/kentucky' },
          '51': { order: 5, id: 51, name: 'St. Louis, MO', url: 'st-louis' },
          '16': { order: 6, id: 16, name: 'Dayton, OH', url: 'dayton/ohio' },
          '1': { order: 7, id: 1, name: 'Cincinnati', url: 'cincinnati-area/ohio' },
          '11': { order: 8, id: 11, name: 'Cincinnati, OH', url: 'cincinnati/ohio' },
          '13': { order: 9, id: 13, name: 'Northern Kentucky', url: 'northern-kentucky' },
          '14': { order: 10, id: 14, name: 'Southeastern Indiana', url: 'southeastern-indiana' },
        };

        $scope.regionPrice = function (region_id) {
          if (typeof $scope.floorplan === 'undefined') return;
          if (typeof $scope.floorplan.communities === 'undefined') return;
          if ($scope.floorplan.communities.length === 0) return;

          var price = $scope.floorplan.communities.reduce(function (min, c) {
            if (c.region_id == region_id || (region_id == 1 && Math.floor(c.region_id / 10) == 1)) {
              if (!min || (c.min_price > 0 && c.min_price < min)) {
                min = c.min_price;
              }
            }
            return min;
          }, 0);

          return price;
        };

        $scope.inRegion = function (region_id) {
          if (typeof $scope.floorplan === 'undefined') return;
          if (typeof $scope.floorplan.communities === 'undefined') return;
          if ($scope.floorplan.communities.length === 0) return;

          var exists = $scope.floorplan.communities.filter(function (c) {
            if (c.region_id == region_id || (region_id == 1 && Math.floor(c.region_id / 10) == 1)) {
              return true;
            }
            return false;
          });
          return !!exists.length;
        };

        $scope.setRegion = function (region_id) {
          var region_name = $scope.allRegions[region_id].name;
          var region_seo = $scope.allRegions[region_id].url;
          regionStorageFactory.set(region_name, region_seo);
          $scope.regionId = region_id;

          $scope.setPrice($scope.regionPrice(region_id));

          // $scope.floorplan.communities = $scope.floorplan.communities.filter(function (c) {
          //   return c.region_id == region_id;
          // });
        };
      }
    ]);
}());
