/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentUiSelect', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-ui-select',
        replace: true,
        scope: {
          source: '=',
          changeFunction: '=?',
          defaultPlaceholder: '=?',
          formScope: "=",
          isInvalid: "=?"
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: ['FormComponentService', function(FormComponentService) {
          var self = this;

          self.changeFunction = FormComponentService.defineAttr(self.changeFunction, function() {});
          // self.uiModel = [];
          self.placeholder = FormComponentService.definePlaceholder(
              self.defaultPlaceholder,
              'Select A Community Of Interest',
              'select'
            );
          console.log('invalid? ', self.isInvalid);
        }]
      };

    }]);
}());