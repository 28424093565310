(function() {
  "use strict";

  angular
    .module("modules.trustHtml")
    .filter('trustHtml', ['$sce', function($sce) {
      return $sce.trustAsHtml;
    }])
  ;

})();
