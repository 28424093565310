(function(){
	'use strict';
	angular.module('app.common')
	.directive('popoverDir', ['$rootScope', function($rootScope){
		return{
			restrict: 'A',
			link: function(scope, elem, attrs){
				var index = attrs.popoverId;
				scope.trigger = false;

				//check if other popups are open,
				//check if broadcasted index does not match this index
				//close popover
				$rootScope.$on('closePopOvers', function(event, arg){
					if(arg.id != index){
						scope.trigger = false;
					}
				});

				elem.bind('mouseenter',function(){
					$rootScope.$broadcast('closePopOvers',{id:index});
					scope.trigger = true;
					scope.$apply();
				});

			}
		}
	}]);
}());