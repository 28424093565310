(function(){
	'use strict';
	angular.module('app.ourcollections')
	.controller('ourCollectionsCtrl',['$scope', function($scope){

		$scope.slides = [
			{
				image: "/images/site/our-collections/hero-bkg.jpg",
				caption: "Our Collections"
			}
		];

	}]);
}());