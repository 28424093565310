(function() {
  'Use strict';
  angular.module('app.common').service('mortageCalculatorService', [function() {

    var price;

    this.setPrice = function(setPrice) {
      price = setPrice;
    }

    this.getPrice = function() {
      return price;
    }

  }]);
}());
