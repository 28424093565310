(function(){
	'use strict';
	angular.module('app.stlouis')
	.controller('stLouisCtrl',['$scope',
		function($scope){

		$scope.slide = {
				image: "/images/site/st-louis/hero-bkg.jpg",
				caption: "St Louis"
			}
		;

	}]);
}());