/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .service('shuffleArray', [function() {

      this.shuffle = function(array) {
        var m = array.length,
          t, i;
        while (m) {
          i = Math.floor(Math.random() * m--);
          t = array[m];
          array[m] = array[i];
          array[i] = t;
        }

        return array;
      }

    }]);
}());
