/*jslint white: true, nomen: true */
/*global $, angular */
(function() {
  'use strict';

  angular.module('app.common')

    .service('WarrantyItemsDataService', ['$http', function($http) {
      var warrantyItemsDataServiceResultFailMessageText = 'No warranty items found',
        data = {
          warrantyItemPromise: null,
          removeImagePromise: null
        },

        createWarrantyItem = function() {
          data.warrantyItemPromise = $http.get('/api/warranty-request-item/create', {
            headers: {
              'Cache-Control': 'no-cache'
            },
            cache: false
          }).then(warrantyItemWash);
          return data.warrantyItemPromise;
        },

        warrantyItemWash = function(result) {
          var successResult = {},
            failResult = {
              status: 'failure',
              warrantyItem: [],
              errors: warrantyItemsDataServiceResultFailMessageText
            };

          if (result === undefined || result.data === undefined || result.data.status === undefined) {
            return failResult;
          }

          if (result.data.status === 'failure') {
            return failResult;
          }

          if (result.data.warrantyItem) {
            successResult.warrantyItem = result.data.warrantyItem;
          }

          successResult.status = result.data.status;
          successResult.errors = 'none';

          return successResult;
        },

        removeWarrantyItemImage = function(warrantyItemId, imageId) {
          data.removeImagePromise = $http({
            url: '/api/warranty-request-item-image/' + imageId,
            params: {warrantyItemId: warrantyItemId},
            method: "DELETE",
            headers: {
              'Cache-Control': 'no-cache'
            },
            cache: false
          }).then(removeItemWash);
          return data.removeImagePromise;
        },

        removeItemWash = function(result) {
          var successResult = {},
            failResult = {
              status: 'failure',
              error: 'There was a problem deleting your image.'
            };

          if (result === undefined || result.data === undefined || result.data.status === undefined) {
            return failResult;
          }

          if (result.data.status === 'failure') {
            return failResult;
          }

          if (result.data.status === 'success') {
            successResult.status = 'success';
            return successResult;
          }
        };

      return {
        createWarrantyItem: createWarrantyItem,
        removeWarrantyItemImage: removeWarrantyItemImage
      };

    }]);
}());
