(function() {
    'use strict';
  
    angular.module('app.forms')
      .directive('fhFormBuyNow', [function() { 
        return {
          restrict: "E",
          scope: {
            userSelectedHome: '=',
            userSelectedCommunity: '=',
            analyticsConfig: '=',
            formResult: "=",
            dismissFunction: '='
          },
          bindToController: true,
          controllerAs: 'vm',
          templateUrl: 'forms/fh-form-buy-now',
          link: function() {},
          controller: [
            'FormService',
            'DeviceService',
            '$scope',
            '$timeout',
            'AnalyticsService',
            function(
              FormService,
              DeviceService,
              $scope,
              $timeout,
              AnalyticsService
            ) {
  
              var self = this,
                prefillMode = false,
                prefillData = FormService.prefillForm(prefillMode, 'buyNow');

              self.formData    = prefillData.formData;
              self.formResult  = prefillData.formResult;

              self.formData.selected_homes = [self.userSelectedHome];
              self.formData.selected_communities = [self.userSelectedCommunity];

              self.analyticsConfig = {
                googleAnalytics: {
                  pageViewUrl: '/ajax-goals/buy-now'
                }
              };
  
              self.checkFormValidation = function(form) {
                self.triedSubmit = true;
  
                if (! form.$valid) {
                  return;
                }
  
                processGetInTouchForm();
              };
  
              function processGetInTouchForm() {
                self.formResult.showForm = false;
                self.formResult.showLoader = true;
  
                prepareFormData();
  
                FormService.submit('/forms/buy-now', self.formData)
                  .then(function(result) {
                    self.formResult = result;

                    AnalyticsService.logTrackingClick(self.analyticsConfig);

                  });
              }
  
              function prepareFormData() { 
                return;
              }
  
            }]
        };
      }]);
  })();