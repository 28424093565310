(function(){
	"use strict";

	angular.module("app.common")
	.directive("accordianThumbnail", ['$location','DeviceService','$timeout', function($location, DeviceService, $timeout){
		return {
			restrict: 'E',
			transclude: true,
			scope: {
				templateData: '=templateData',
				setSlide: '&setSlide',
				modalOpen:'&openModal'
			},
			templateUrl: '/components/accordian-thumbnail.tpl.html',
			link: function(scope, elem){
				
				scope.trigger = function(idx){
					scope.modalOpen();
					scope.setSlide({num:idx});
				};

				scope.mobileFlip = function(idx){
					if(idx == 0 && DeviceService.check() == 'phone'){
						var target = elem.find('> div:first-child');

						$timeout(function(){
							target.addClass('active');
							$timeout(function(){
								target.removeClass('active');
							},1000);
						},1000);
						
					}
				}

			}
		};
	}]);
}());