angular
  .module('modules.price')

  .filter('price', ['$filter', function($filter) {
    'use strict';

    return function(param) {

      if (param === undefined || param === null || param === 0 || param === '' || param === "0") {
        return "Call for pricing";
      }

      var currency = "$" + $filter('number')(param, 0);

      return currency;

    };
  }])
;