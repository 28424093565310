(function() {
  'use strict';
  angular.module('app.forms')
    .service('TestDataService', [function() {
      var testDataService = {};

      testDataService.get = function(formName) {
        return this[formName]();
      };

      testDataService.landToSell = function() {
        return {
          acreage: "100",
          address: "123 Main Street",
          city: "Fresno",
          current_zoning: "Residential",
          email: "tester@tester.com",
          first_name: "Tester",
          last_name: "Tester",
          location_of_property: "Fresno",
          phone: "5598244493",
          questions_comments: "So many questions...",
          state: "CA",
          time_to_sell: "Now",
          type: "landToSell",
          zip: "93720"
        };
      };

      testDataService.subcontractorsAndSuppliers = function() {
        return {
          city: "Fresno",
          company_address: "123 Main Street",
          company_name: "Apple",
          contact_person: "Tester Tester",
          email: "tester@tester.com",
          experience: "20",
          number_of_crews: "10",
          origin: "http: //dev.fischer.com/about/contact-us",
          builder_phone: "5598244493",
          questions_comments: "So many comments",
          references: "All of them",
          state: "CA",
          trade: "Carpenter",
          type: "subcontractorsAndSuppliers",
          worked_for_fischer: "0",
          zip: "93720"
        };
      };

      testDataService.warrantyRequest = function() {
        return {
          first_name: "John",
          last_name: "Doe",
          email: "john@doe.com",
          phone: "1234567890",
          address_1: "123 Main Street",
          city: "Anytown",
          state: "CA",
          zip: "12345"
        };
      };

      return testDataService;
    }]);
})();