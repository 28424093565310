/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .filter('stripWords', ['DeviceService', function(DeviceService) {
      return function(string) {
      	if(DeviceService.check() == 'phone'){
        	return string.replace(/<span>[\s\S]*?<\/span>/, '');
      	}else{
      		return string;
      	}
      };
    }]);
}());
