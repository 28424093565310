/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentContactMethodSelect', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-contact-method-select',
        replace: true,
        scope: {
          defaultPlaceholder: '@',
          model: '=',
          formObj: '=',
          formScope: '=',
          selectName: '=?',
          isRequired: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          var self = this;

          if (! self.defaultPlaceholder) {
            self.defaultPlaceholder = 'Preferred Contact Method';
          }
          if (! self.selectName) {
            self.selectName = 'contact_method';
          }

          if (typeof self.isRequired === 'undefined') {
            self.isRequired = true;
          }

          self.methods = ['Phone', 'Email', 'Snail Mail'];

        }]
      };

    }]);
}());