/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .filter('breakHours', [function() {

      return function(string) {

        if(string === undefined){
          return false;
        }

        var cat = string.replace('CLOSED, ','CLOSED').replace('CLOSED','CLOSED<br/>');
        var day = [
          'pm, Monday', 'pm, Tuesday', 'pm, Wednesday', 'pm, Thursday', 'pm, Friday', 'pm, Saturday', 'pm, Sunday',
          'pm Monday', 'pm Tuesday', 'pm Wednesday', 'pm Thursday', 'pm Friday', 'pm Saturday', 'pm Sunday'];

        angular.forEach(day, function(value, key) {
          var parse = value.replace(', ','<br/>').replace(' ','<br/>');
          cat = cat.replace(value, parse);
        });

        return cat.replace("Monday","Mon")
        .replace("Tuesday","Tue")
        .replace("Wednesday","Wed")
        .replace("Thursday","Thu")
        .replace("Friday","Fri")
        .replace("Saturday","Sat")
        .replace("Sunday","Sun");

      };

    }]);
}());
