/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('defineHeight', [function() {
      return {
        restrict: 'A',
        link: function(scope, elem, attrs) {

          $(window).on('load resize',function(){
            elem.css('height','100%');//reset
            elem.css('height', elem.height());//define
          })

        }
      }
    }]);
}());
