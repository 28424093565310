(function(){
	'use strict';
	angular.module('app.buyingprocess',[])
	.controller('buyingProcessCtrl', ['$scope', '$timeout',
	function($scope, $timeout){

		$scope.steps = [
			{
				index: 1,
				image: '/images/site/buying-process/slide-001.jpg',
				title: 'Get Pre-qualified',
				details: 'Click the button below to get prequalified with Victory Mortgage, or find a lender of your choice.  Clicking the button below will give you an idea of your investment level and takes less than 10 minutes.',
				button: true
			},
			{
				index: 2,
				image: '/images/site/buying-process/slide-002.jpg',
				title: 'Choose your new home',
				details: 'Visit a model and consult with a Sales Counselor to determine desired home features, community, homesite, and budget. Your Sales Counselor will then review different floor plans with you until you find the home you’ve been searching for and you’re ready to move forward.',
				button: true
			},
			{
				index: 3,
				image: '/images/site/buying-process/slide-003.jpg',
				title: 'Sign a purchase agreement',
				details: 'Once you’ve found your dream home, you’re ready to make it yours! Your personal Sales Counselor will guide you through the purchase agreement process, and inform you of your deposit dates.  '
			},
			{
				index: 4,
				image: '/images/site/buying-process/slide-004.jpg',
				title: 'Loan Application',
				details: 'Your Loan Officer will help you complete a mortgage application and approval and then you are ready to move to the next step and make your home your own. '
			},
			{
				index: 5,
				image: '/images/site/buying-process/slide-005.jpg',
				title: 'Close on Your New Home',
				details: 'All deposits will be applied toward your final down payment due at closing. You will receive keys to your new home! ',
				button: true
			}
		];

		$scope.step = $scope.steps[0];
		$scope.tab = $scope.steps[0].index;

		$scope.setStep = function($index){
			$scope.tab = $scope.steps[$index].index;
			var hero = document.getElementById("hero");

			hero.className = "";
			$timeout(function(){
				$scope.step = $scope.steps[$index];
				hero.className = "in";
			}, 200);
		};

    $scope.buyingProcessAnalyticsConfig = {
      googleAnalytics: {
        pageViewUrl: '/ajax-goals/qsu-buying-process'
      }
    };

	}]);
}());