/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .filter('breakAddress', [function() {

      return function(string) {
        if(string === undefined){
          return false;
        }

        return string.replace(',', ",<br/>");

      };

    }]);
}());
