(function() {
  'use strict';

  angular.module('app.forms')
    .directive('fhFormQsu', [function() {
      return {
        restrict: "E",
        scope: {
          formResult: "=",
          analyticsConfig: '='
        },
        bindToController: true,
        controllerAs: 'vm',
        templateUrl: 'forms/fh-form-qsu',
        link: function() {},
        controller: [
          'FormService',
          'DeviceService',
          'DataService',
          '$scope',
          '$filter',
          '$timeout',
          'AnalyticsService',
          function(
            FormService,
            DeviceService,
            DataService,
            $scope,
            $filter,
            $timeout,
            AnalyticsService
          ) {


            var self = this,
              prefillMode = false,
              prefillData = FormService.prefillForm(prefillMode, 'quickSignup');


            self.formResult = {};
            self.formData    = prefillData.formData;
            self.formResult  = prefillData.formResult;
            self.regions     = [];
            self.regionID    = false;
            self.regionPhone = FormService.getDefaultRegionPhone();
            self.regions = FormService.getRegionsForSelect();

            // Expose the dirty state to outside the directive to prevent
            // the headerwindow from closing unintentionally
            $scope.$watch(angular.bind(self, function() {
              return self.qsuForm.$dirty;
            }), function(newVal, oldVal) {
              if(newVal !== oldVal) {
                self.formResult.dirty = newVal;
              }
            });

            var getRegionsFromDataservice = DataService('/api/region', 'regions').promise;

            self.selectRegion = function(regionID) {
              self.formData.selected_regions = [regionID];

              getRegionsFromDataservice.then(function(regions) {
                angular.forEach(regions, function(region) {
                  if (region.id === regionID) {
                    self.regionPhone = region.sales_phone;
                  }
                });
              });

            };

            self.checkFormValidation = function(form) {
              self.triedSubmit = true;

              if (! form.$valid) {
                return;
              }

              processGetInTouchForm();
            };

            function processGetInTouchForm() {
              self.formResult.showForm = false;
              self.formResult.showLoader = true;

              prepareFormData();

              FormService.submit('/forms/quick-signup', self.formData)
                .then(function(result) {
                  self.formResult = result;
                  self.formResult.dirty = false;

                  AnalyticsService.logTrackingClick(self.analyticsConfig);

                });
            }

            function prepareFormData() {
              return;
            }

          }]
      };
    }]);
})();