/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .service('uibModal', [function() {
      var plan = {},
        tab = 0,
        modalTabs = [{
          name: 'where available',
          content: []
        }, {
          name: 'photo gallery',
          content: []
        }, {
          name: 'video tour',
          content: []
        }, {
          name: 'request info',
          content: []
        }];

      this.setFloorplan = function(floorplan) {
        plan = floorplan;
      }

      this.getFloorplan = function() {
        return plan;
      }

      this.setTab = function(num) {
        tab = num;
      }

      this.getTab = function() {
        return tab;
      }

      this.getModalTabs = function() {
        return modalTabs;
      }
    }]);
}());
