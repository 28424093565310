(function(){
	'use strict';

	angular.module('app.common')
	.filter('htmlToPlainText', function() {
	    return function(text) {
	      return  text ? String(text).replace(/&.*;/gi, '').replace(/<[^>]+>/gmi, '') : '';
	    };
	  }
	);

}());