/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentMessage', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-message',
        replace: true,
        scope: {
          formObj: "=",
          formScope: "=",
          isRequired: "=?",
          defaultPlaceholder: "=?"
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          if (typeof self.isRequired === 'undefined') {
            self.isRequired = true;
          }
          if (typeof self.defaultPlaceholder === 'undefined') {
            self.defaultPlaceholder = 'Message*';
          }
        }]
      };

    }]);
}());