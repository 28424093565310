/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .service('floorplanService', ['$filter', function($filter) {

      var filters = [
        'PLAN NAME',
        'PRICE RANGE',
        '# OF BEDS',
        '# OF BATHS',
        'SQUARE FEET RANGE'
      ];

      var plans = [
        {
            image: '/images/site/microsite/thumb-home.jpg',
            name: "ANDOVER",
            address: "1840 Kingsbury Drive, Cumming, GA",
            price: 399875,
            beds: 5,
            baths: 3.5,
            sqft: 3865,
            availability: "Ready Now"
        }, {
            image: '/images/site/microsite/thumb-home.jpg',
            name: "ANDOVER",
            address: "1840 Kingsbury Drive, Cumming, GA",
            price: 399875,
            beds: 5,
            baths: 3.5,
            sqft: 3865,
            availability: "Ready Now"
        }, {
            image: '/images/site/microsite/thumb-home.jpg',
            name: "ANDOVER",
            address: "1840 Kingsbury Drive, Cumming, GA",
            price: 399875,
            beds: 5,
            baths: 3.5,
            sqft: 3865,
            availability: "Ready Now"
        }, {
            image: '/images/site/microsite/thumb-home.jpg',
            name: "ANDOVER",
            address: "1840 Kingsbury Drive, Cumming, GA",
            price: 399875,
            beds: 5,
            baths: 3.5,
            sqft: 3865,
            availability: "Ready Now"
        }, {
            image: '/images/site/microsite/thumb-home.jpg',
            name: "ANDOVER",
            address: "1840 Kingsbury Drive, Cumming, GA",
            price: 399875,
            beds: 5,
            baths: 3.5,
            sqft: 3865,
            availability: "Ready Now"
        }
      ];

      var filteredPlans = plans;

      this.getFilters = function() {
        return filters;
      }

      this.getModalTabs = function() {
        return modalTabs;
      }

      this.getFloorplans = function(id) {
        angular.forEach(plans, function(value) {
          if (value.id == id) {
            filteredPlans = value;
          }
        });

        return filteredPlans;
      }


    }]);
}());
