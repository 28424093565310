/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeCtrl', ['$scope', 'DeviceService', '$window', '$timeout', 'DataService', '$filter', 'slickConfigService', '$rootScope', 'regionStorageFactory',
      function($scope, DeviceService, $window, $timeout, DataService, $filter, slickConfigService, $rootScope, regionStorageFactory) {

        var loadedOnce = false;

        $scope.communityLoaded = false;
        $scope.community = [];
        slickConfigService.modalSlideshow($scope);
        slickConfigService.baseConfig($scope);
        $scope.triggerSlick = false;

        if (DeviceService.check() == 'phone') {
          $scope.isCollapsed = true;
        } else {
          $scope.isCollapsed = false;
        }

        $scope.filteredResidences = [];

        $scope.sortColumn = 'price';
        $scope.sortName = '';
        $scope.sortReverse = true;

        $scope.toggleType = function (kind, order) {
          $scope.sortColumn = kind;
          $scope.sortReverse = order;
          $scope.updateSortName();

        };

        $scope.sortsParams = [{
          name: 'By Highest Price',
          value: {
            type: 'price',
            descending: true
          }
        }, {
          name: 'By Lowest Price',
          value: {
            type: 'price',
            descending: false
          }
        }, {
          name: 'By Most Bedrooms',
          value: {
            type: 'plan.bed_high',
            descending: true
          }
        },{
          name: 'By Least Bedrooms',
          value: {
            type: 'plan.bed_low',
            descending: false
          }
        }, {
          name: 'By Highest Sq. Ft.',
          value: {
            type: 'plan.sqft_high',
            descending: true
          }
        }, {
          name: 'By Lowest Sq. Ft.',
          value: {
            type: 'plan.sqft_low',
            descending: false
          }
        }];

        $scope.updateSortName = function() {
          let matchingSortParam = $scope.sortsParams.find(function(param) {
            return param.value.type === $scope.sortColumn && param.value.descending === $scope.sortReverse;
          });

          $scope.sortName = matchingSortParam ? matchingSortParam.name : '';
        }

        $scope.updateSortName();
        $scope.filtersParams = {
          bed: [],
          bath: [],
          price: [],
          type: [],
          collection: [],
          stories: []
        };

        $scope.filters = {
          price: 'all',
          bed: 'all',
          bath: 'all',
          type: 'all',
          collection: 'all',
          stories: 'all'
        }

        $scope.specAggregates = {
          bed: [],
          bath: [],
          price: [],
          type: [],
          collection: []
        };

        var defaults = {
          filterParam: {
            name: 'All',
            value: 'all'
          }
        };

        $scope.filtersParams.price.push(defaults.filterParam);
        $scope.filtersParams.bed.push(defaults.filterParam);
        $scope.filtersParams.bath.push(defaults.filterParam);
        $scope.filtersParams.type.push(defaults.filterParam);
        $scope.filtersParams.collection.push(defaults.filterParam);

        $scope.$watch('filters', function(filters, oldFilters) {
          if (filters === oldFilters) {
            return;
          }

          $scope.filteredResidences = $filter('residenceFilter')($scope.community.residences, $scope.filters);
        }, true);

        $scope.communityPromise = DataService($window.location.pathname, 'community')
          .promise
          .then(function(community) {

            $scope.$broadcast('user-selected-community', community);
            $rootScope.community = community;

            // deactivated so that images are not being rendered in /resources/views/components/modal-gallery-details.blade.php
            // $scope.images = community.assets;

            // merge videos and assets
            $scope.firstAsset = null;
            $scope.newAssets = community.videos.concat(community.assets);

            if (community.videos.length > 0) {
              $scope.firstAsset = $scope.newAssets[0];
              $scope.newAssets = $scope.newAssets.slice(1);
            }

            $scope.regionPhone = community.region.sales_phone;

            angular.forEach(community.model_homes, function(model_home) {
              if (typeof model_home.plan !== 'undefined' && !!model_home.plan) {
                model_home.plan.url = model_home.url;
                delete model_home.plan.elevations;
              } else {
                model_home.plan = {};
              }

              model_home.plan.beds = model_home.plan.bed_low == model_home.plan.bed_high ? model_home.plan.bed_high : model_home.plan.bed_low+'-'+model_home.plan.bed_high;
              model_home.plan.baths = model_home.plan.bath_low == model_home.plan.bath_high ? model_home.plan.bath_high : model_home.plan.bath_low+'-'+model_home.plan.bath_high;
              model_home.plan.sqft = model_home.plan.sqft_low == model_home.plan.sqft_high ? model_home.plan.sqft_high: model_home.plan.sqft_low+'-'+model_home.plan.sqft_high;
            });

            var shortPrice = community.price < 1000000 ? Math.floor((community.price / 1000) / 10) * 10 : Math.floor(community.price / 1000000);

            community.short_price = shortPrice;

            $scope.short_price = community.short_price;

            if(community.homes.length > 0 && $window.location.href.includes('move-in-ready')){
              $scope.isFloorplanActive = false;
            } else {
              $scope.isFloorplanActive = community.residences.length > 0;
            }

            $scope.toggleView = function(value){
              $scope.isFloorplanActive = value;
            }

            // unique order/sort floorplans/residences by price asc (Renaissance - Franklin City Schools, community ID 274)
            // unique order/sort floorplans/residences by price asc (Twelve Oaks, community ID 795)
            // unique order/sort floorplans/residences by price asc (Silverstone, community ID 847)
            // unique order/sort floorplans/residences by price asc (Crossvine Estates, community ID 872)
            // unique order/sort floorplans/residences by price asc (The Cove, community ID 883)
            // unique order/sort floorplans/residences by price asc (Laurel Farms, community ID 885)
            // unique order/sort floorplans/residences by price asc (Laurel Farms, community ID 905)
            // unique order/sort floorplans/residences by price asc (Skybrook, community ID 884)
            if (community.id === 274 || community.id === 795 || community.id === 847 || community.id === 872 || community.id === 883 || community.id === 885 || community.id === 905 || community.id === 884) {
              $scope.sortReverse = false;
              $scope.sortName = 'By Lowest Price';
            }

            angular.forEach(community.residences, function(residence) {
              residence.plan.price = residence.price;
              residence.plan.url = community.url + residence.plan.url.substring(1);

              $scope.specAggregates.price.push(residence.price);
              $scope.specAggregates.bed.push(residence.plan.bed_low);
              $scope.specAggregates.bed.push(residence.plan.bed_high);
              $scope.specAggregates.bath.push(residence.plan.bath_low);
              $scope.specAggregates.bath.push(residence.plan.bath_high);

              if(typeof residence.plan.productType !== 'undefined' && residence.plan.productType) {
                $scope.specAggregates.type.push(residence.plan.productType.name);
              }

              if(typeof residence.plan.collection !== 'undefined' && residence.plan.collection) {
                $scope.specAggregates.collection.push(residence.plan.collection.name);
              }

              if (!$scope.specAggregates.stories) {
                $scope.specAggregates.stories = [];
            }

            if (typeof residence.plan.floors !== 'undefined') {
                $scope.specAggregates.stories.push(residence.plan.floors);
            }

              residence.plan.beds = residence.plan.bed_low+'-'+residence.plan.bed_high;
              residence.plan.baths = residence.plan.bath_low+'-'+residence.plan.bath_high;
              residence.plan.sqft = residence.plan.sqft_low+'-'+residence.plan.sqft_high;
            });

            Object.keys($scope.specAggregates).forEach(function(key) {
              switch (key) {
                case 'price':
                  var minPrice = Math.min.apply(null, $scope.specAggregates[key]);
                  var maxPrice = Math.max.apply(null, $scope.specAggregates[key]);
                  minPrice = Math.ceil(minPrice / 100000) * 100000;
                  maxPrice = (Math.ceil(maxPrice / 100000) * 100000);

                  for (var i = minPrice; i <= maxPrice; i += 100000) {
                    $scope.filtersParams.price.push({
                      name: 'Under $' + i.toLocaleString(),
                      value: i
                    });
                  }
                  break;

                case 'bed':
                  var minBeds = Math.min.apply(null, $scope.specAggregates[key]);
                  var maxBeds = Math.max.apply(null, $scope.specAggregates[key]);

                  for (var i = minBeds; i <= maxBeds; i++) {
                    $scope.filtersParams.bed.push({
                      name: i + '+',
                      value: i
                    });
                  }
                  break;
                case 'bath':
                  var minBaths = Math.min.apply(null, $scope.specAggregates[key]);
                  var maxBaths = Math.max.apply(null, $scope.specAggregates[key]);

                  for (var i = minBaths; i <= maxBaths; i++) {
                    $scope.filtersParams.bath.push({
                      name: i + '+',
                      value: i
                    });
                  }
                  break;
                case 'type':
                  $scope.specAggregates[key] = _.uniq($scope.specAggregates[key]);
                  $scope.specAggregates[key].sort();
                  angular.forEach($scope.specAggregates[key], function(item) {
                    $scope.filtersParams.type.push({
                      name: item,
                      value: item
                    });
                  });
                  break;
                case 'collection':
                  $scope.specAggregates[key] = _.uniq($scope.specAggregates[key]);
                  $scope.specAggregates[key].sort();
                  angular.forEach($scope.specAggregates[key], function(item) {
                    let minSqft = Infinity;
                    let maxSqft = -Infinity;
                    let minBeds = Infinity;
                    let maxBeds = -Infinity;
                    let minBaths = Infinity;
                    let maxBaths = -Infinity;
                    let minPrice = Infinity;

                    community.residences.forEach(residence => {
                      try {
                          if (residence.plan.collection.name === item) {
                              minSqft = Math.min(minSqft, residence.plan.sqft_low);
                              maxSqft = Math.max(maxSqft, residence.plan.sqft_high);
                              minBeds = Math.min(minBeds, residence.plan.bed_low);
                              maxBeds = Math.max(maxBeds, residence.plan.bed_high);
                              minBaths = Math.min(minBaths, residence.plan.bath_low);
                              maxBaths = Math.max(maxBaths, residence.plan.bath_high);
                              if (residence.plan.price < minPrice) {
                                  minPrice = Math.min(minPrice, residence.plan.price);
                              }
                          }
                      } catch (error) {
                          console.error("An error occurred with the collection filter: ", error);
                      }
                  });

                    let homeCopy;
                    if(item == 'Paired Patio Homes Collection') {
                      homeCopy = 'Paired Patio Homes';
                    } else if(item === 'Patio Homes Collection'){
                      homeCopy = 'Patio Homes';
                    } else if(item === 'Midtown Collection'){
                      homeCopy = 'Townhomes';
                    } else {
                      homeCopy = 'Single Family Homes';
                    }
                    $scope.filtersParams.collection.push({
                        name: item,
                        value: item,
                        bedRange: `${minBeds}-${maxBeds}`,
                        bathRange: `${minBaths}-${maxBaths}`,
                        sqftRange: `${minSqft}-${maxSqft}`,
                        price: minPrice,
                        copy: `${homeCopy} Priced From ${Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0}).format(minPrice)}`
                    });

                  });
                  $scope.filtersParams.collection.sort((a, b) => {
                    return b.price - a.price;
                  });
                  break;
                case 'stories':
                  $scope.specAggregates[key] = _.uniq($scope.specAggregates[key]);
                  $scope.specAggregates[key].sort();
                  angular.forEach($scope.specAggregates[key], function(item) {
                    $scope.filtersParams.stories.push({
                      name: item + " Story",
                      value: item
                    });
                  });
                  break;

              }
            });

            $scope.filteredResidences = $filter('residenceFilter')(community.residences, $scope.filters);

            $scope.$broadcast('communityDataLoaded', community);
            $scope.community = community;

            $scope.getHomeTypes = function() {
              const collection = $scope.community.collection.name;
              const homeTypeMap = [
                ['Patio Homes Collection', 'Patio Homes'],
                ['Midtown Collection', 'Townhomes'],
                ['Paired Patio Homes Collection', 'Paired Patio Homes'],
                ['Villa II Collection', 'Condominiums'],
                ['Gallery II Collection', 'Condominiums']
              ];
              const matchingType = homeTypeMap.find(subArray => subArray[0] === collection);
              return matchingType ? matchingType[1] : 'Homes';
            }
            $scope.isMobile = function(){
              return window.innerWidth <= 768;
            }
            $scope.displayLimit = $scope.isMobile() ? 3 : 6;
            $scope.loadMoreFloorplans = function(count){
              $scope.displayLimit += count;
            }
            $scope.communityLoaded = true;
            return $scope.communities;
          });


        $scope.active = 0;

        $scope.featuredFloorplan = [];

        $scope.selectFilter = function(type, value) {
          $scope.filters[type] = value;
        }

        $scope.filterByStory = function(storyCount) {
          if (storyCount === 1) {
              if ($scope.filters.stories && $scope.filters.stories.value === 1) {
                  $scope.filters.stories = {};
              } else {
                  $scope.filters.stories = { value: 1 };
              }
          } else if (storyCount === 'multi') {
              if ($scope.filters.stories && $scope.filters.stories.value === 'multi') {
                  $scope.filters.stories = {};
              } else {
                  $scope.filters.stories = { value: 'multi' };
              }
          } else {
              $scope.filters.stories = {};
          }
      };


        $scope.triggerSlick = false;
  			$rootScope.$on('modalClose', function(){
  				$scope.triggerSlick = false;
  			});

        $scope.setSlide = function(num) {
          if (!$scope.triggerSlick) {
            $scope.slickLarge.initialSlide = num;
            $scope.slickLarge.event = {
              beforeChange: function(event, slick, currentSlide, nextSlide) {
                var player, command;

                player = $("iframe", slick.$slides[currentSlide])[0];

                // for youtube, don't forget to add the parameter enablejsapi=1 to the url of the video
                if ($(slick.$slides[currentSlide]).hasClass('youtube')) {
                  command = {
                    "event": "command",
                    "func": "pauseVideo"
                  };
                } else if ($(slick.$slides[currentSlide]).hasClass('vimeo')) {
                  command = {
                    "method": "pause",
                    "value": "true"
                  };
                }

                //check if the player exists.
                if (player != undefined) {
                  // post our command to the iframe.
                  player.contentWindow.postMessage(JSON.stringify(command), "*");
                }
              }
            };
            $scope.triggerSlick = true;
          }
        }

        $scope.lastScrollTop = 0;
        $scope.scrollDirection = "up";
        angular.element($window).bind("scroll", function() {
            var currentScrollTop = window.pageYOffset;
            // Preventing navbar from collapsing on mobile if user can see the header.
            if (currentScrollTop <= 50) {
                $scope.scrollDirection = "up";
                $scope.isCollapsed = false;
            } else {
                if (currentScrollTop > $scope.lastScrollTop && DeviceService.check() == 'phone') {
                    $scope.scrollDirection = "down";
                    var $toggle = $('#subnav .navbar-toggle');
                    if(!$toggle.hasClass('collapsed')){
                        $toggle.click();
                    }
                } else {
                    $scope.scrollDirection = "up";
                }
            }

            $scope.lastScrollTop = currentScrollTop;
            $scope.$apply();
        });
         $scope.someFunction = function(){
          $scope.broadcast('triggerModal');
        };
      }

    ])
    .filter('residenceFilter', function() {
      return function(residences, filters) {
        return residences.filter(function(residence) {
          var valid = true;

          Object.keys(filters).forEach(function(key) {
            if (filters[key]['value'] && filters[key]['value'] !== 'all') {
              switch (key) {
                case 'bed':
                  if (filters[key]['value'] < residence.plan.bed_low || filters[key]['value'] > residence.plan.bed_high) {
                    valid = false;
                  }
                  break;
                case 'bath':
                  if (filters[key]['value'] < residence.plan.bath_low || filters[key]['value'] > residence.plan.bath_high) {
                    valid = false;
                  }
                  break;
                case 'price':
                  if (residence.price > filters[key]['value']) {
                    valid = false;
                  }
                  break;
                case 'type':
                  if (residence.plan.productType.name != filters[key]['value']) {
                    valid = false;
                  }
                  break;
                case 'collection':
                  if (sget(['plan', 'collection', 'name'], residence) != sget([key, 'value'], filters)) {
                    valid = false;
                  }
                  break;
                case 'stories':
                    if (filters[key] && filters[key]['value'] === 'multi') {
                        if (residence.plan.floors <= 1) {
                            valid = false;
                        }
                    } else if (filters[key] && residence.plan.floors !== filters[key]['value']) {
                        valid = false;
                    }
                    break;
              }
            }
          });

          return valid;
        });


      };
    });
}());
