/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeContactCtrl', ['$scope', '$timeout', '$window', 'DataService', 'uiGmapPromises',
      function($scope, $timeout, $window, DataService, uiGmapPromises) {


        // Function to update placeholders based on label text
        function updateFormPlaceholders() {
          // Select the form by its ID
          const form = document.getElementById('hsForm_099207ec-eaf5-46c3-bac7-db753a3811f9');

          if (form) {
            // Find all label elements within the form
            const labels = form.getElementsByTagName('label');

            // Loop through each label
            Array.from(labels).forEach(function(label) {
              // Get the 'for' attribute which corresponds to the input ID
              var inputId = label.getAttribute('for');

              // Find the input element that matches the label
              var input = form.querySelector('#' + inputId);

              if (input) {
                // Set the input's placeholder attribute to the label's text
                input.placeholder = label.textContent.trim();
              }
            });
          }
        }
/*
        $scope.map = {
          center: communityCenter,
          control: {},
          markers: [],
          zoom: 15,
          windows: [],
          markersEvents: {
            click: function(marker, eventName, model, args) {
              $scope.map.windows[model.id].show = true;
            }
          }
        };
*/
         $scope.communityPromise = DataService($window.location.pathname, 'community')
          .promise
          .then(function(community) {
            $scope.community = community;
            $timeout(function() {
              updateFormPlaceholders();
            }, 0);
/*
            uiGmapPromises
              .GoogleMapsApiReady()
              .then(function(GoogleMaps) {
                angular.extend($scope.map, {
                  options: {
                    streetViewControl: false,
                    draggable: true,
                    scrollwheel: true,
                    mapTypeControl: false,
                    panControl: true,
                    panControlOptions: {
                      position: GoogleMaps.ControlPosition.LEFT_CENTER
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                      position: GoogleMaps.ControlPosition.RIGHT_BOTTOM
                    }
                  }
                });

                var lat = null,
                    lng = null,
                    name = null;

                if ($scope.community.model_homes.length > 0 && $scope.community.model_homes[0].latitude && $scope.community.model_homes[0].longitude)
                {
                  lat = $scope.community.model_homes[0].latitude;
                  lng = $scope.community.model_homes[0].longitude;
                  name = 'Model Home Location';
                }
                else if ($scope.community.sales_office_latitude && $scope.community.sales_office_longitude)
                {
                  lat = $scope.community.sales_office_latitude;
                  lng = $scope.community.sales_office_longitude;
                  name = 'Sales Office Location';
                }

                angular.extend($scope.map, {
                  center: {
                    latitude: lat,
                    longitude: lng
                  }
                });

                $scope.map.markers.push({
                  id: 0,
                  latitude: lat,
                  longitude: lng,
                  icon: '/images/site/microsite/map-pin-contact.png',
                  options: {
                    visible: true
                  }
                });

                $scope.map.windows.push({
                  id: 0,
                  latitude: lat,
                  longitude: lng,
                  show: true,
                  templateUrl: 'contactPopupWindow.html',
                  templateParameter: {
                    name: name,
                    latitude: lat,
                    longitude: lng
                  },
                  options: {
                    maxWidth: 800,
                    pixelOffset: new GoogleMaps.Size(0, -7.5)
                  }
                });

              });
*/
          });

        $scope.micrositeContactAnalyticsConfig = {
          googleAnalytics: {
            pageViewUrl: '/ajax-goals/microsite-contact'
          }
        };

      }
    ]);
}());
