/*jslint white: true, nomen: true */
/*global angular, googleApiKey, FastClick */

(function() {
  'use strict';

  angular.module('app', [
      'ngResource',
      'app.common',
      'app.forms',
      'app.homepage',
      'app.wherewebuild',
      'app.marketing',
      'app.hometour',
      'app.directives',
      'app.ourcollections',
      'app.customertestimonials',
      'app.buyingprocess',
      'app.buildingprocess',
      'app.virtualtours',
      'app.designoverview',
      // 'app.blog',
      'app.modeldetail',
      'app.moveindetail',
      'app.floorplandetail',
      'app.imagegallery',
      'app.lifestyle',
      'app.joinourteam',
      'app.vendorbid',
      'app.buildingabettercommunity',
      'app.discoverthedifference',
      'app.contactus',
      'app.stjudes',
      'app.longinterestlist',
      'app.energywise',
      'app.whychoosefischer',
      'app.podcast',
      'app.stlouis',
      'app.whodey',
      'app.regionRevamped',
      'app.subregionRevamped',

      'uiGmapgoogle-maps',
      'ngAnimate',
      'ngRoute',
      'ngTouch',
      'ngSanitize',
      'ui.bootstrap',
      '720kb.datepicker',
      'slickCarousel',
      'angular.filter',
      'youtube-embed',
      'angular-inview',
      'modules.dataServices',
      'modules.priceStartingAt',
      'ngStorage',
      'LocalStorageModule'
    ])
    .config(['$interpolateProvider', function($interpolateProvider) {
      $interpolateProvider.startSymbol('<%');
      $interpolateProvider.endSymbol('%>');
    }])
    .config(['localStorageServiceProvider', function (localStorageServiceProvider) {
      localStorageServiceProvider
        .setPrefix('siteApp')
        .setStorageType('localStorage')
        .setNotify(true, true)
    }])
    .config(['uiGmapGoogleMapApiProvider', function(uiGmapGoogleMapApiProvider) {
      uiGmapGoogleMapApiProvider.configure({
        key: googleApiKey,
        v: '3.25',
        libraries: 'places'
      });
    }])
    .config(['$locationProvider', function($locationProvider) {
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: true,
        rewriteLinks: false
      });
    }])
    .config(['$compileProvider', function($compileProvider) {
      $compileProvider.debugInfoEnabled(false);
      $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|sms):/);
    }])
    .run(['$http', '$window', function($http, $window) {
      /*init fastclick*/
      if ('addEventListener' in document) {
        document.addEventListener('DOMContentLoaded', function() {
          FastClick.attach(document.body);
        }, false);
      }

      /* init facebook CAPI IDs */
      $http({
        method: 'GET',
        url: '/api/fb/ids',
        headers: { 'Content-Type': 'application/json' },
      }).success(function (data) {
        $window.localStorage.setItem('fbEventId', data.event_id);
        $window.localStorage.setItem('fbExternalId', data.external_id);
      });
    }]);

  angular.module('app.common', ['ngStorage']);
  angular.module('app.forms', ['ui.bootstrap', 'ngMessages']);
  angular.module('app.homepage', []);
  angular.module('app.wherewebuild', []);
  angular.module('app.marketing', []);
  angular.module('app.hometour', []);
  angular.module('app.directives', []);
  angular.module('app.ourcollections', []);
  angular.module('app.customertestimonials', []);
  angular.module('app.buyingprocess',[]);
  angular.module('app.buildingprocess',[]);
  angular.module('app.virtualtours',[]);
  angular.module('app.designoverview',[]);
  angular.module('app.blog',[]);
  angular.module('app.modeldetail',[]);
  angular.module('app.moveindetail',[]);
  angular.module('app.imagegallery',[]);
  angular.module('app.lifestyle',[]);
  angular.module('app.joinourteam',[]);
  angular.module('app.vendorbid',[]);
  angular.module('app.discoverthedifference',[]);
  angular.module('app.contactus',[]);
  angular.module('app.buildingabettercommunity',[]);
  angular.module('app.stjudes',[]);
  angular.module('app.longinterestlist',[]);
  angular.module('app.energywise',[]);
  angular.module('app.whychoosefischer',[]);
  angular.module('app.podcast',[]);
  angular.module('app.stlouis',[]);
  angular.module('app.whodey',[]);

}());

var strTitle = function(string) {
  string = string
    .replace(/_/g, ' ')
    .replace(/-/g, ' ')
    .replace(/^(.)|\s+(.)/g, function ($1) {
      return $1.toUpperCase();
    });
  return string;
};

var findByKebab = function(string, list) {
  if (!string) return false;
  return list[matchKebab(string, list)];
}

var matchKebab = function(string, list) {
  for (let index = 0; index < list.length; index++) {
    if (strKebab(list[index]) == strKebab(string)) {
      return index;
    }
  }

  return -1;
}

var strKebab = function(string) {
  return string.replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/'*/g, '')
    .replace(/[\s-\/\.]+/g, '-')
    .toLowerCase();
};

var strUrl = function(string) {
  return encodeURIComponent(strKebab(string));
}

// super get - safe get - sget
var sget = function (p, o) {
  return p.reduce(function(xs, x) {
    return (xs && xs[x]) ? xs[x] : null;
  }, o);
};
