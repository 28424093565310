/*jslint white: true, nomen: true */
/*global angular */

(function() {
  'use strict';

  angular.module('app.common')
    .directive('customCheckbox', function() {
      return {
        restrict: 'E',
        transclude: true,
        scope: {
          item: '@',
          radioModel: '='
        },
        templateUrl: '/directive/custom-checkbox'
      };
    });
}());
