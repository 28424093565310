/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .directive('scrollTo', ['DeviceService', function(DeviceService) {
      return {
        restrict: 'A',
        link: function(scope, elem, attrs) {

          var clickMethod;

          $("#main-nav button.navbar-toggle").on('click',function(){
            $('#subnav button.navbar-toggle:not(.collapsed)').click();
          });

          $(window).resize(scroll);
          scrollMethod();

          function scrollMethod(){
            clickMethod = 'click';
            if(DeviceService.check() == 'phone' || DeviceService.check() == 'ipad'){
              clickMethod = 'touchend';
            }

            elem.on(clickMethod, function(e) {
              
              var target = attrs.href;
              var offset = attrs.desktopOffset;

              e.preventDefault();
              //elem.siblings().removeClass('active');
              
              if (attrs.target) {
                target = attrs.target;
              }

              if (DeviceService.check() == 'iPad' && 
                  DeviceService.orientation() == 'portrait' && 'tabletOffset' in attrs){
                offset = attrs.tabletOffset;
              }
              if (DeviceService.check() == 'phone') {
                offset = attrs.mobileOffset;
                $('#subnav button.navbar-toggle').click();
              }

              $('html, body').animate({
                scrollTop: $(target).offset().top - parseInt(offset, 10)
              }, 1000);

            });
          }
        }
      };
    }]);
}());
