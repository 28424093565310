/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .filter('reverse', [function() {
      return function(items) {
        return items.slice().reverse();
      };
    }]);
}());
