(function() {
  "use strict";

  angular
    .module("modules.staticMap")

    .directive("staticMap", [function() {

      return {

        scope: {
          'pins': '=staticMapPins'
        },
        templateUrl: "/modules/staticMap/pins.html",
        //transclude: true,
        controller: ['$scope', function($scope) {

          $scope.scope = $scope;

          // pins should have a community and a position
          // pin: { community: {  }, position: { left: "", top: "" }};
          $scope.staticMapPinStyle = function(pin) {
            return {
              "position": "absolute",
              "top": pin.position.top + "%", 
              "left": pin.position.left + "%"
            };
          };

        }],
        link: function(scope, element, attrs) {

          // Make this container the size of its parent container.
          // This will ensure a relative box to work with.
          element.css({
            "position": "absolute",
            "top": 0,
            "left": 0,
            "right": 0,
            "bottom": 0,
            "margin": 0,
            "padding": 0
          });

        }

      };

    }]);

})();