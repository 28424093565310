/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .service('scrollMagic', ['$rootScope', 'device', function($rootScope, device) {
      var controller = new ScrollMagic.Controller();

      this.parallax = function(elem, target) {
        target = $(elem).find(target);
        var tween = TweenMax.to(target, 100, {
          top: -100,
          scale: 1
        });

        var scene = new ScrollMagic.Scene({
            triggerElement: elem,
            duration: "200%"
          })
          .setTween(tween)
          //.addIndicators()
          .addTo(controller);
      }

      this.triggerClass = function(elem, trigger, classname, triggerEvent, offsetnum) {
        var scene = new ScrollMagic.Scene({
            triggerElement: trigger,
            triggerHook: triggerEvent,
            offset: offsetnum
          })
          .setClassToggle(elem, classname)
          //.addIndicators({name: trigger})
          .addTo(controller);
      }

      this.scrollSpy = function(trigger, classname, triggerEvent, offsetnum) {
        var scene = new ScrollMagic.Scene({
            triggerElement: trigger,
            triggerHook: triggerEvent,
            offset: offsetnum
          })
          .on("enter leave", function(e) {

            var $target = $('#subnav li a[href="' + trigger + '"]');

            if (e.type == 'enter') {
              console.log(trigger + " " +e.type);
            }

            if (e.type == 'leave') {
              console.log(trigger + " " +e.type);
            }

            $target.addClass(classname).siblings().removeClass(classname);
            $rootScope.$broadcast('scrollspyTriggered', {
              url: $target.attr('target')
            });
          })
          .addIndicators({name: trigger})
          .addTo(controller);
      }

    }]);
}());
