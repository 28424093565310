(function(){
	"use strict";

	angular.module("app.common")
	.directive("inlineForm", function(){
		return {
			restrict: 'E',
			transclude: true,
			scope: {
				image:'@',
				title:'@',
				sub: '@',
				button: '@',
				regionDropdown: '@',
				classname: '@',
        analyticsConfig: '=',
        formType: '@'
			},
			templateUrl: '/directive/inline-form'
		};
	});
}());