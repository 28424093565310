/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function() {
  'use strict';

  angular.module('app')
  .controller("HomeShowCtrl", ['$scope', '$window', 'DataService', 'slickConfigService',
    function($scope, $window, DataService, slickConfigService) {

      $scope.slickActive = false;
      $scope.slickFloorplan = slickConfigService.fadeConfig(1);
      $scope.slickFloorplan.event = {
          beforeChange: function(event, slick, currentSlide, nextSlide) {
            $scope.activeTab = nextSlide;
          }
      }

      DataService($window.location.href).promise
        .then(function(result) {
          //gather data then make slick slideshow active in Explore section
          $scope.home_show = result.home_show;
          $scope.slickActive = true;
        })
        .then(function(){
          //adjust popup position for Explore section
          var floorplan_levels = $scope.home_show.home_show_floorplans;
          angular.forEach( floorplan_levels, function(item){
              angular.forEach(item.home_show_floorplan_photos, function(pin){
                  var vert = "";
                  var hor = "";
                  if(parseInt(pin.lot_map_top)  < 30){vert = "bottom";}
                  if(parseInt(pin.lot_map_top)  > 70){vert = "top";}
                  if(vert !== ''){vert + "-" + hor}
                  if(parseInt(pin.lot_map_left) < 30){hor  = "right";}
                  if(parseInt(pin.lot_map_left) > 70){hor  = "left";}
                  pin.placement = vert + hor;
              })
          })
        })

      //toggle active tabs for Explore section
      $scope.activeTab = 0;
      $scope.setActiveTab = function(tab){
          $scope.activeTab = tab;
      };

      $scope.homeShowAnalyticsConfig = {
        googleAnalytics: {
          pageViewUrl: '/ajax-goals/home-show'
        }
      };

    }
  ]);

}());
