(function(){
	'Use Strict';
	angular.module('app.buildingabettercommunity')
		.directive('buildingAnimation', [function(){
			return{
				restrict: 'E',
				templateUrl: '/components/building-animation.tpl.html',
				link: function(scope, elem, attrs){

					var speed = 0.3;

					var tl = new TimelineMax();

					tl.from(elem.find('.b'),speed,{rotationX:90,opacity:0,delay:1})
					  .from(elem.find('.u'),speed,{rotationY:90,opacity:0})
					  .from(elem.find('.i').eq(0),speed,{rotationX:90,opacity:0})
					  .from(elem.find('.l'),speed,{bottom:50,opacity:0})
					  .from(elem.find('.d'),speed,{rotationX:90,opacity:0})
					  .from(elem.find('.i').eq(1),speed,{top:50,opacity:0})
					  .from(elem.find('.n'),speed,{rotationX:90,opacity:0})
					  .from(elem.find('.g'),speed,{bottom:50,opacity:0});
				}
			}
	}]);
}());