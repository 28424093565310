(function() {
    'use strict';
  
    angular
      .module('app.microsite')
      .controller('MicrositeReviewsCtrl', MicrositeReviewsCtrl);
  
      MicrositeReviewsCtrl.$inject = ['$scope']; 
  
    function MicrositeReviewsCtrl($scope) {
      $scope.reviews = []; 
  
    }
  })();
  