(function(){
	'use strict';
	angular.module('app.buildingprocess',[])
	.controller('buildingProcessCtrl', ['$scope', '$timeout',
	function($scope, $timeout){

		$scope.active = 0;

		$scope.steps = [
			{
				image: '/images/site/building-process/slide-001.jpg',
				title: 'Selections',
				caption: 'As the building process begins, you are able to start personalizing your home! You will have the opportunity to preview our Lifestyle Design Center and set your selections appointment. Approximately 2 weeks after your Selections Appointment, you will have a Sign-Off Appointment where you will review final blue prints and finalize all color options.'
			},
			{
				image: '/images/site/building-process/slide-002.jpg',
				title: 'Meet the Team',
				caption: 'You will have an opportunity to meet with your Field Manager and Sales Counselor where they will discuss the building process with you and address any questions or concerns you may have to make sure you feel at ease with the entire process.'
			},
			{
				image: '/images/site/building-process/slide-003.jpg',
				title: 'Pre-Drywall Review',
				caption: 'You will meet with the Field Manager for a tour of your new home where you will review the installation of your selections and confirm that everything is being installed properly. We will answer any questions you have and review the construction timeline during this tour. '
			},
			{
				image: '/images/site/building-process/slide-004.jpg',
				title: 'Homeowner Orientation',
				caption: 'You will meet with your Field Manager and review the features of your new home and the installation of your selections. The team will also go over proper maintenance techniques for your new home. This meeting typically occurs one week prior to your closing date. '
			},
			{
				image: '/images/site/building-process/slide-005.jpg',
				title: 'Move-In and Enjoy Your New Home!',
				caption: 'Close and move into your new home! Rest easy knowing you have a Fischer Homes Warranty, extensive homeowner�s manual, and 3 and 12 month home reviews. Now you are ready to start building memories and enjoy life in your brand new dream home. '
			}
		];

		$scope.count = $scope.steps.length - 1;

		$scope.prevSlide = function(){
			if($scope.active == 0){
				$scope.active = $scope.count;
			}else{
				$scope.active--;
			}
		}

		$scope.nextSlide = function(){
			if($scope.active == $scope.count){
				$scope.active = 0;
			}else{
				$scope.active++;
			}
		}

		$scope.setActive = function(index){
			$scope.active = index;
		}


		$scope.$watch('active', function (newValue, oldValue) {
			if(newValue !== oldValue){
				var caption = document.getElementById("caption-watch");

				caption.className = "caption";
				$timeout(function(){
					caption.className = "caption active";
				}, 500);
			}
		}, true);

	}])
}());