(function(){
	'use strict';

	angular.module('app.common')
	.directive('showMore', function(){
		return {		
			restrict: 'E',
			transclude: true,
			templateUrl: '/directive/show-more'
		}
	})
}());