/*jslint white: true, nomen: true */
/*global angular, $, window, navigator */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller("MicrositeFloorplansCtrl", ["$scope", "$timeout", "$http", 'CommunitiesListDataService', function($scope, $timeout, $http, CommunitiesListDataService) {
      CommunitiesListDataService.getCommunity(communityId).then(function(result) {
        $scope.community = result.community;
        $scope.residences = result.community.residences;

      });

      $scope.filters = [

      ];

      $scope.openFloorplan = function(community, residence) {
        window.location = community.url+residence.url;
      }

      $scope.sortsParams = [{
        name: 'Price Low-High',
        value: {
          type: 'price',
          descending: false
        }
      }, {
        name: 'Sqft Low-High',
        value: {
          type: 'sqft',
          descending: false
        }
      }, {
        name: 'Beds Low-High',
        value: {
          type: 'bed',
          descending: false
        }
      }, {
        name: 'Baths Low-High',
        value: {
          type: 'bath',
          descending: false
        }
      }

      ];
      $scope.sort = $scope.sortsParams[0].value;

      $scope.addToFavorites = function(event, residence_id) {
        event.stopPropagation();

        var action;

        // remove
        if ($(event.currentTarget).hasClass('active')) {
          action = 'remove';
          $(event.currentTarget).attr('data-content', 'Save this floorplan');
        }
        // add
        else {
          action = 'add';
          $(event.currentTarget).attr('data-content', 'Remove this floorplan');
        }

        $http.get('/my-saved-homes/favorite-residence', {
          params: {
            residence_id: residence_id,
            action: action
          }
        })
        .then(function(response) {
          if (response.data.result === 'success') {
          }
        });

        $(event.currentTarget).toggleClass('active');
      };

      $scope.slidePrev = function() {
        slideID($('#microsite-floorplan-carousel .item.active').prev(), $scope.total);
      };

      $scope.slideNext = function() {
        slideID($('#microsite-floorplan-carousel .item.active').next(), 1);
      };

      function slideID(elem, limit) {
        slideNum = elem.index() + 1;
        if (slideNum == 0) {
          slideNum = limit;
        }
        $('.slide-num').text(slideNum);
      }

      function createChunk(arr, chunkSize) {
        var groups = [],
          i;
        for (i = 0; i < arr.length; i += chunkSize) {
          groups.push(arr.slice(i, i + chunkSize));
        }
        return groups;
      }

      $scope.floorplanSort = function(floorplan) {
        switch ($scope.sort.type) {
          case 'price':
            return floorplan.price;
            break;
          case 'sqft':
            return floorplan.sqft_low;
            break;
          case 'bed':
            return floorplan.bed_low;
            break;
          case 'bath':
            return floorplan.bath_low;
            break;
        }
        return true;
      }
    }])
    .filter('floorplanFilter', function() {
      return function(residences, filters, sort) {
        if (residences) {
          return residences.filter(function(residence) {
            var valid = true;

            return valid;
          });
        }

        return [];
      };
    });
}());
