(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formPrequalify', [function() {
      return {
        restrict: "E",
        scope: {},
        bindToController: true,
        controllerAs: 'vm',
        templateUrl: '/prequalify/partials/prequalify-form',
        link: function() {},
        controller: [
          'FormService',
          'DeviceService',
          '$timeout',
          '$window',
          'AnalyticsService',
          function(
            FormService,
            DeviceService,
            $timeout,
            $window,
            AnalyticsService
          ) {

            var self = this,
              prefillMode = false,
              prefillData = FormService.prefillForm(prefillMode, 'prequalify');

            self.formData    = prefillData.formData;
            self.formResult  = prefillData.formResult;

            self.regions = FormService.getRegionsForSelect();

            self.analyticsConfig = {
              googleAnalytics: {
                pageViewUrl: '/ajax-goals/prequalify'
              }
            };

            self.checkFormValidation = function(form) {
              self.triedSubmit = true;

              if (! form.$valid) {
                return;
              }

              $window.open('https://www.victorymortgage.com/prequalification');

              processGetInTouchForm();
            };

            function processGetInTouchForm() {
              self.formResult.showForm = false;
              self.formResult.showLoader = false;

              prepareFormData();


              FormService.submit('/forms/prequalify', self.formData)
                .then(function(result) {
                  self.formResult = result;

                  AnalyticsService.logTrackingClick(self.analyticsConfig);

                  return result;
                });
            }

            function prepareFormData() {
              self.formData.selected_regions = [self.formData.selected_regions];
            }

          }]
      };
    }]);
})();